import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { ReactComponent as Waring } from "./waring.svg";

function BirthdayWaringModal({ setModal }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const hideForAnHour = () => {
    setModal(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "34px 20px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center text-center primiumMainText"
                  style={{ marginBottom: "20px" }}
                >
                  <span className="capSvgWrap">
                    <Waring />
                  </span>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{ marginBottom: "24px" }}
                >
                  생년월일 필수 입력입니다.
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => hideForAnHour()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default BirthdayWaringModal;
