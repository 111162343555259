import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";
import { ReactComponent as Signup } from "../../assets/img/others/signup.svg";
import { ReactComponent as Marryfeet } from "../../assets/img/others/marryfeet.svg";
import "./products.scss";

function Products(props) {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const history = useHistory();

  const callApi = async () => {
    const response = await requestGet(`/api/product/joinfee/list`);
    setData(response.data);
  };

  useEffect(() => {
    document.title = "서비스 안내 | 우리의 인연";
    callApi();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content customWrap">
        <Container>
          <Row>
            <span className="titleName">서비스 안내</span>
          </Row>
          <Row>
            <h1 className="productscardName">회원가입비</h1>
            <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">회원가입비</div>
                  <div>
                    <div className="productsCustomCardInSubSubPrice">5회권</div>
                    <div>
                      <span className="productsCustomCardInPrice">100</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col>
            {/* <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">회원가입비</div>
                  <div>
                    <div className="productsCustomCardInSubSubPrice">
                      10회권
                    </div>
                    <div>
                      <span className="productsCustomCardInPrice">180</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <h1 className="productscardName">성혼사례금</h1>
            <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">성혼사례금</div>
                  <div>
                    <div>
                      <span className="productsCustomCardInPrice">300</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col>
            <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">성혼사례금</div>
                  <div>
                    <div>
                      <span className="productsCustomCardInPrice">500</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col>
            <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">성혼사례금</div>
                  <div>
                    <div>
                      <span className="productsCustomCardInPrice">700</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col>
            <Col lg="6" className="d-flex mb-3">
              <Card className="productsCustomCard">
                <Col xs="12">
                  <div className="productsCustomCardInName">성혼사례금</div>
                  <div>
                    <div>
                      <span className="productsCustomCardInPrice">1,400</span>
                      <span className="productsCustomCardInSubPrice">만원</span>
                    </div>
                    <div className="productsCustomCardInVAT">(VAT 별도)</div>
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Products;
