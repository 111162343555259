import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";

function MarketingDetail({ setMarketingModal }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              {`<개인정보의 제공에 관한 안내>*

[소비자 권익 보호에 관한 사항]

최소한의 정보처리 및 동의거부에 대한 안내
정보 동의시 가입상담 및 고객관리 등을 위해 최소한의 정보만 수집·이용하게 되며, 본 동의를 거부하시는 경우 해당 서비스의 이용 및 가입상담이 제한될 수 있습니다.

개인정보 수집·이용 동의 철회
개인정보 수집·이용에 동의한 이후에도 전화[대표번호], 서면, 메일 등을 통해 고객정보 관리 등에 대한 개인정보 처리 중지를 요청할 수 있습니다

상품권유 중지청구(Do-Not Call)
개인정보 제공 및 이용에 동의한 이후에도 전화[대표번호], 서면 등을 통해 마케팅활동에 대한 중지를 요청할 수 있습니다.

제공 사실 통보 요구, 열람 및 정정·삭제 청구
고객은 당사가 개인정보를 타인에게 제공한 사실이 있는 경우 그 사실을 조회해 줄 것을 요구할 수 있습니다. 또한 정보 열람이나 정정ㆍ삭제를 요구할 수 있습니다. 고객의 권리와 그 행사방법 등 자세한 사항은 당사 홈페이지를 참조하여 주시기 바랍니다.

문의사항 및 동의 철회, 조회 요구 방법

 메일[coihelp@naver.com]


[개인정보 제공에 관한 사항]

1. 개인정보를 제공받는 자 : 우연 커플매니저 및 이벤트/마케팅 담담

2. 개인정보를 제공받는 자의 이용 목적
- 회원관리 및 결혼서비스에 관한 상담 및 자료요청 의사 확인
- 결혼관련 서비스 상담 및 이용 권유, 각종 서비스 및 이벤트 안내
- 테스트 결과 도출 및 안내를 위한 정보수집
- 이벤트 참가신청, 참가가능여부, 당첨자발표, 진행사항에 대한 정보 전달

3. 제공할 개인정보

- 성명, 출생년도(또는 출생년월일), 성별, 연락처 및 휴대전화번호, 결혼경력, 이메일, 학력(또는 최종출신학교), 주거주지(또는 주소), 직업종류(또는 직장명)

4. 제공받는 자의 개인정보 보유 및 이용기간

- 결혼중개업법 기준 5년 또는 개인정보 삭제 및 탈회를 요청할 때까지 보유.이용합니다.
- 결혼회원 가입의 경우 개인정보에 관한 회사 내부 방침에 따라 개인정보를 보유합니다.
- 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 회사 내부 방침에 의한 정보보유 사유

-개인정보 삭제 및 회원탈퇴 신청기록
　보존 이유 : 부정 이용 방지
　보존 기간 : 우연 회원 탈퇴일부터 2년
　보존 항목 : 아이디, 이름, 출생년도, 이메일

-채용에 관한 입사지원 기록
　보존 이유 : 상시 채용 및 내부 인재풀 관리
　보존기간 : 최종 업데이트일부터 180일

나. 관련법령에 의한 정보보유 사유

상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

- 계약 또는 청약철회 등에 관한 기록
　보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
　보존 기간 : 5년

- 대금결제 및 재화 등의 공급에 관한 기록
　보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
　보존 기간 : 5년

- 전자금융 거래에 관한 기록
　보존 이유 : 전자금융거래법
　보존 기간 : 5년

- 소비자의 불만 또는 분쟁처리에 관한 기록
　보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
　보존 기간 : 3년

- 본인 확인에 관한 기록
　보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
　보존 기간 : 6개월

- 웹사이트 방문기록
　보존 이유 : 통신비밀보호법
　보존 기간 : 3개월

5. 서비스 안내방법 : SMS, Email, 전화 등`}
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    onClick={() => setMarketingModal(false)}
                  >
                    닫기
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MarketingDetail;
