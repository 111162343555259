import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
// import Login from "../pages/Authentication/Login";
// import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Introduce from "../pages/Introduce/index";
import CEOIntroduce from "../pages/CEOIntroduce/index";
import Problem from "../pages/Problem/index";
import Whypage from "../pages/Whypage/index";
import MobileAll from "../pages/mobileAll/index";

//Consult
import ConsultRequest from "../pages/Consult/ConsultRequest";
import ConsultRequestFinish from "../pages/Consult/finish";
import PrivacyDetail from "../pages/Consult/PrivacyDetail";
import MarketingDetail from "../pages/Consult/MarketingDetail";

//Talk
import MyProfile from "../pages/Talk/MyProfile";
import MyProfileDetail from "../pages/Talk/MyProfileDetail";
import IntroTalk from "../pages/Talk/Intro";
import IntroTalkDetail from "../pages/Talk/IntroDetail";
import LikeTalk from "../pages/Talk/Like";
import LikeTalkDetail from "../pages/Talk/LikeDetail";
import ImgDetail from "../pages/Talk/ImgDetail";
import UsersProfile from "../pages/Talk/UsersProfile";
import Users from "../pages/Talk/Users";

//Purcahse
import Products from "../pages/Purcahse/Products";
import ProductsList from "../pages/Purcahse/Productscopy";
import ProductsListCopy from "../pages/Purcahse/Productscopycopy";
import Purchase from "../pages/Purcahse/Purchase";
import Purchasecopy from "../pages/Purcahse/Purchasecopy";
import Purchasewait from "../pages/Purcahse/Purchasewait";

//Review
import ReviewPage from "../pages/Review/ReviewPage";
import PartyReviewPage from "../pages/Review/PartyReviewPage";
import PhotoReviewPage from "../pages/Review/PhotoReviewPage";
import ReviewDetail from "../pages/Review/ReviewDetail";
import PartyDetail from "../pages/Review/PartyDetail";

//Products
import ProductsIntroduce from "../pages/Products/Products";

//Register
import RegUser from "../pages/RegUser/User";

//footer
import Total from "../components/Total";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/review/detail/:id?", component: ReviewDetail },
  { path: "/party/review", component: PartyReviewPage },
  { path: "/party/detail/:id?", component: PartyDetail },
  { path: "/review", component: ReviewPage },
  { path: "/photoreview", component: PhotoReviewPage },
  { path: "/introduce", component: Introduce },
  { path: "/ceo/introduce", component: CEOIntroduce },
  { path: "/introduce/products", component: ProductsIntroduce },
  { path: "/problem", component: Problem },
  { path: "/whypage", component: Whypage },
  { path: "/mobileAll", component: MobileAll },
  { path: "/products", component: Products },
  { path: "/products/list", component: ProductsListCopy },
  // { path: "/products/innolist", component: ProductsListCopy },
  // { path: "/innopurchase/:id?/:uuid?", component: Purchasecopy },
  { path: "/purchase/:id?/:uuid?", component: Purchasecopy },
  { path: "/purchasewait", component: Purchasewait },
  //Consult
  { path: "/consult/request", component: ConsultRequest },
  { path: "/form_submit_finish", component: ConsultRequestFinish },
  { path: "/total/:page?", component: Total },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/privacy/detail", component: PrivacyDetail },
  { path: "/marketing/detail", component: PrivacyDetail },
  { path: "/my/profile/:id", component: MyProfile },
  { path: "/my/profile/detail/:id/:birth", component: MyProfileDetail },
  { path: "/introduce/user/:id", component: IntroTalk },
  { path: "/introduce/detail/:id/:birth", component: IntroTalkDetail },
  { path: "/like/user/:id", component: LikeTalk },
  { path: "/like/detail/:id/:birth", component: LikeTalkDetail },
  { path: "/like/user/profile/:id/:birth", component: UsersProfile },
  { path: "/like/user/profile/:id", component: Users },
  { path: "/image", component: ImgDetail },
  { path: "/register", component: RegUser },
  // { path: "/logout", component: Logout },
  // { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
