import React from "react";
import { Row, Col } from "reactstrap";

function Privacy(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12">
              <tbody>
                <tr>
                  <td class="privacy__box-1">
                    <h3 className="my-3">제 1 조 (목적)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      이 약관은 우연(이하 "회사"라 합니다.)와 이용자간에 회사가
                      운영하는 인터넷 사이트 (www.coincidence.co.kr 및 동
                      사이트의 기본 DB를 공유하는 모든 사이트 및 제휴사이트
                      포함. 이하 "사이트"라 합니다)에서 제공하는 제반
                      서비스(이하 "서비스"라 합니다)의 가입 및 이용에 관한 제반
                      사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-2">
                    <h3 className="my-3">제 2 조 (용어의 정의)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
                      <br />
                      1. 회원 : 서비스를 제공받기 위하여 이용자 아이디(ID)를
                      부여 받은 자를 말합니다.
                      <br />
                      2. 회원 아이디 (이하 "ID" ) : 회원의 식별과 회원의 서비스
                      이용을 위하여 회원이 선정하고 회사가 부여하는 문자와
                      숫자의 조합을 말합니다.
                      <br />
                      3. 비밀번호 : 회원이 사용하는 ID와 일치된 회원임을
                      확인하고 회원의 개인정보를 위하여 회원이 선정한 문자와
                      숫자의 조합을 말합니다.
                      <br />
                      4. 핸드폰 인증 : SMS(문자메시지)로 6자리 숫자를 전송하여
                      핸드폰에 수신된 인증번호를 사이트에 등록하도록 함으로써
                      회원 인증을 하는 것
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-3">
                    <h3 className="my-3">제 3 조 (약관의 효력 및 변경)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 이 약관은 이용자가 회원에 가입함과 동시에 효력을
                      발생하며, 관계법령에 위배되지 않는 범위 안에서 개정될 수
                      있습니다. 개정된 약관은 온라인에서 공지함으로써 효력을
                      발생하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은
                      사전에 공지합니다.
                      <br />
                      2. 이 약관에 동의하는 것은 정기적으로 웹을 방문하여 약관의
                      변경사항을 확인하는 것에 동의함을 의미합니다. 변경된
                      약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는
                      회사에서 책임지지 않습니다.
                      <br />
                      3. 회원은 개정된 약관에 동의하지 않을 경우 회원 탈퇴를
                      요청할 수 있으며, 서비스를 계속 사용하는 것은 약관의
                      내용을 수락하고 동의하는 것으로 간주합니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-4">
                    <h3 className="my-3">제 4 조 (약관 외 준칙)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 이 약관이 해당사이트에서 공지하는 개별서비스 혹은
                      회사의 정책에 대한 세부적인 안내내용은
                      서비스안내(헬프데스크 등 도움말 관련 메뉴, 각 메뉴 및
                      서비스 화면에 고지된 내용, 사이트에 게시하는 공지 등을
                      포함하며 회사가 온라인에서 제공하는 서비스에 대한 설명 및
                      사용권한, 회원의 의무, 책임 등을 명시한 내용 이하
                      "서비스안내"라 합니다)에서 정할 것을 위임하거나, 기타 이
                      약관에서 명시하지 아니한 사항에 대하여는 서비스안내가 정한
                      바에 따르기로 합니다.
                      <br />
                      2. 서비스안내의 변경 및 변경에 관한 동의에 있어서도 제3조
                      제2항 및 제3항의 규정을 적용합니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-5">
                    <h3 className="my-3">제 5 조 (회원가입)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 사이트 회원이 되고자 하는 자는 회사가 정한 가입양식에
                      따라 회원정보를 기입하고 "약관에 동의합니다" 및 "회원가입"
                      단추를 누르는 방법으로 회원가입을 신청합니다.
                      <br />
                      2. 회원은 반드시 본인인증서비스를 통해 본인확인을 해야
                      하며, 회사가 정한 가입양식에 기재하는 모든 정보는 진실한
                      것으로 간주됩니다. 내용이 허위(차명, 비실명 등
                      포함)이거나, 그러하다고 의심할만한 합리적인 사유가 발생할
                      경우 회사는 가입신청을 한 회원의 모든 ID를 삭제하고 본
                      서비스 제공의 일부 또는 전부를 중지할 수 있으며, 이로 인해
                      발생하는 모든 불이익에 대하여 책임지지 않습니다. 회사는
                      이를 위하여 필요한 경우 제증명을 요구할 수 있습니다.
                      <br />
                      3. 회원이 회사의 사이트 회원으로 가입하는 것은 서비스와
                      관련된 전화, 이메일(E-MAIL)을 수신하는 데에 동의하는
                      것으로 간주합니다. 단, 이메일 수신 거부자의 경우 이메일
                      발송대상에서 제외되며, 이후에도 회원의 의사에 따라
                      수신여부를 변경할 수 있습니다.
                      <br />
                      4. 회사는 회원의 등급을 서비스 유료결제 등으로 나눌 수
                      있으며, 회원은 그 등급에 따라 서비스 이용에 제한을 받을 수
                      있습니다. 회원 등급에 관한 자세한 사항은 해당
                      서비스안내에서 정한 바에 따릅니다. 이는 회사의 정책에 의해
                      변경될 수 있으며, 변경된 사항은 공지 즉시 효력을
                      발생합니다.
                      <br />
                      5. 회사는 제1항과 같은 방법으로 회원가입을 신청한 자가
                      다음 각 호에 해당되지 않는 한 신청한 자를 회원으로
                      등록합니다. 그러나 다음 각 호에 해당하는 자에게는 회원가입
                      승낙을 유보하거나 사이트 이용을 제한할 수 있으며,
                      가입신청을 거절할 수도 있습니다.
                      <br />
                      (1) 등록내용이 허위(차명, 비실명 등 포함)인 것으로
                      판명되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할
                      경우
                      <br />
                      (2) 가입 신청자가 본 약관 및 서비스안내, 기타 관계법령을
                      위반하여 해당 인터넷 사이트의 회원자격을 상실한 적이 있는
                      경우
                      <br />
                      (3) 기술상 지장이 있는 경우
                      <br />
                      (4) 사회질서 및 미풍양속에 반할 우려가 있다고 인정되는
                      경우
                      <br />
                      (5) 영리를 추구한 목적으로 본 서비스를 이용하고자 하는
                      경우
                      <br />
                      (6) 기타 회사의 사정상 이용승낙이 곤란하거나 회사가 정한
                      이용신청요건이 미비 되었을 경우
                      <br />
                      6. 회원가입계약의 성립시기는 회사의 승낙이 가입신청자에게
                      도달한 시점으로 합니다.
                      <br />
                      7. 회사가 운영하는 모든 사이트의 회원 DB는 통합되어
                      운영되므로 본 약관에 동의함은 회사가 운영하는 모든
                      사이트(www.coincidence.co.kr)의 회원이 됨에 동의함을
                      의미합니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-6">
                    <h3 className="my-3">제 6 조 (회사의 의무)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회사는 지속적이고, 안정적으로 서비스를 제공하기 위해서
                      노력합니다.
                      <br />
                      2. 회사는 서비스 제공과 관련해서 알고 있는 회원의
                      신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지
                      않습니다. 단, 본인이 승낙한 경우, 전기통신기본법 등 법률의
                      규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한
                      수사상의 목적이 있거나 정보통신 윤리 위원회의 요청이 있는
                      경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는
                      경우, 제휴사 또는 외부 마케팅을 통한 보다 나은 서비스를
                      제공하기 위해 회사가 요청을 하고 이에 회원 스스로 동의를
                      한 경우, 회원이 당사에 제공한 개인정보를 스스로 공개한
                      경우에는 그러하지 않습니다.
                      <br />
                      3. 2항의 범위 내에서 회사는 업무와 관련하여 회원 전체 또는
                      일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수
                      있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수
                      있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의
                      수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의
                      설정을 변경할 수 있습니다.
                      <br />
                      4. 회사는 회원이 서비스 이용 중 필요가 있다고 인정되는
                      다양한 정보 및 광고를 전자우편이나 서신 혹은 유/무선 전화
                      등으로 제공합니다.
                      <br />
                      5. 회사는 회원으로부터 제기되는 불만이 정당하다고 인정할
                      경우에는 적극적으로 처리함을 원칙으로 합니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="privacy__box-7">
                    <h3 className="my-3">제 7 조 (회원의 의무)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회원 가입 시에 요구되는 회원 자신의 정보는 진실하고
                      정확하여야 합니다. 또한 이미 제공된 회원에 대한 정보가
                      진실하고, 정확한 정보가 되도록 유지, 갱신하여야 하며
                      그러하지 아니하여 발생하는 문제의 책임은 회원에게 있으며
                      회사는 책임을 지지 않습니다. 이름, 아이디, 출생년,
                      휴대전화 번호, 성별 등은 수정할 수 없습니다.
                      <br />
                      2. 회원이 제공한 정직하지 않은 정보와 기타 이 약관 또는
                      서비스안내에 위배된 행위로 인한 책임은 회원 본인에게
                      있으며, 이로 인하여 회사에 손해가 발생한 경우 회사는
                      회원에게 민,형사상의 책임을 물을 수 있습니다.
                      <br />
                      3. 회사는 회원의 신원에 대한 보증을 하지 않습니다. 또한
                      회원 스스로 사이트를 통한 온/오프라인의 만남으로 회원간에
                      발생한 모든 문제에 대해서는 전적으로 회원본인에게 책임이
                      있으며, 회사는 책임을 지지 않습니다. 그러므로 어떤 형태의
                      만남이든 회원본인의 신중한 판단이 필요합니다.
                      <br />
                      4. 회원은 회사가 공지 또는 통지하는 내용을 반드시 확인,
                      준수하여야 하며 그렇지 아니하여 발생한 문제나 회원의
                      손해에 대해서는 회사가 책임을 지지 않습니다.
                      <br />
                      5. 회원이 본 서비스를 통하여 게시, 전송, 입수하였거나
                      전자메일 기타 다른 수단에 의하여 게시, 전송 또는 입수한
                      모든 형태의 정보에 대하여는 해당 회원이 모든 책임을
                      부담하며 당사는 어떠한 책임도 부담하지 아니합니다.
                      <br />
                      6. 회원은 다음 각 호에 해당하는 행위를 하지 않아야 한다.
                      <br />
                      (1) 타인의 ID와 비밀번호, 성명을 도용하는 행위 및 자신의
                      ID와 비밀번호를 누설하는 행위
                      <br />
                      (2) 가입신청 또는 개인정보 변경시 허위 내용을 등록하는
                      행위
                      <br />
                      (3) 공공질서 및 미풍양속을 저해할 만한 저속, 음란, 모욕적,
                      위협적이거나 타인의 프라이버시를 침해할 수 있는 내용을
                      전송, 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는
                      행위
                      <br />
                      (4) 서비스를 통하여 전송 혹은 게시된 내용의 출처를
                      위장하는 행위
                      <br />
                      (5) 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을
                      침해하는 내용을 게시, 게재, 전자메일 또는 기타의 방법으로
                      전송하는 행위
                      <br />
                      (6) 회사의 승인을 받지 아니한 불법선거운동, 광고, 판촉물,
                      정크메일, 스팸, 행운의 편지, 피라미드 조직 기타 다른
                      형태의 권유를 게시, 게재, 전자메일 또는 기타의 방법으로
                      전송하거나 사용하는 행위
                      <br />
                      (7) 다른 사용자의 개인정보를 수집 또는 저장하는 행위
                      <br />
                      (8) 서비스의 정상적 운영, 유지 등을 방해하거나 지연시키는
                      행위
                      <br />
                      (9) 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는
                      컴퓨터 바이러스 프로그램 등을 유포하는 행위
                      <br />
                      (10) 회사의 승인 없이 회사 인터넷 사이트의 서비스 정보
                      또는 개인정보를 복제 또는 유통 시키거나 상업적으로 이용
                      또는 타인에게 제공하는 행위
                      <br />
                      (11) 서비스를 이용하여 불법 상품을 판매하는 등 영리 활동을
                      하는 행위
                      <br />
                      (12) 해킹, 음란사이트를 통한 상업행위, 상용 S/W 불법 배포
                      등을 하는 행위
                      <br />
                      (13) 범죄와 관련 있다고 객관적으로 판단할 수 있는 행위
                      <br />
                      (14) 회사의 운영진, 관리자 또는 관계자를 사칭하는 행위
                      <br />
                      (15) 회사 프로그램상의 버그를 악용하거나 정상적이지 아니한
                      방법으로 사이버 자산을 취득하는 행위
                      <br />
                      (16) 회사로부터 특별한 권리를 부여 받지 않고 회사의
                      클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹
                      하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를
                      임의로 변경하는 행위
                      <br />
                      (17) 본 약관을 포함하여 기타 회사가 정한 정책 및 기타
                      관계법령을 위반한 행위
                      <br />
                      (18) 다른 이용자 또는 제3자를 비방하거나 명예를 훼손하는
                      행위
                      <br />
                      7. 회원은 회사가 명시적으로 동의하지 않는 한 서비스
                      이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수
                      없으며, 이를 담보로 제공할 수 없습니다.
                      <br />
                      8. 본 약관을 위반하거나 또는 기타의 사유로 타회원으로부터
                      강제탈퇴 또는 불량회원으로 일반에 공개할 것을 요구 받는
                      경우, 신고를 당한 회원의 활동은 정지되며, 회사는 사실확인
                      작업에 들어갑니다. 신고의 내용이 적절했다고 판단되면
                      사이트 관리자는 유·무선을 통해 원활한 회원활동을 권고
                      하거나 강제탈퇴 처리 합니다.
                      <br />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-8">
                    <h3 className="my-3">
                      제 8 조 (게시물 및 회원간 통신내용의 관리)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회원이 게시한 게시물(회원 본인의 개인정보 포함)의
                      내용에 대한 권리와 책임은 회원에게 있으며, 단, 회사는
                      서비스 내에서의 게시권을 가지며 서비스 홍보 등의 목적으로
                      사용할 수 있습니다. 회원이 게재한 모든 정보에 대하여 다음
                      각 호와 같은 경우나 합리적인 사유 발생시 임의로 이를 편집,
                      이동, 삭제할 수 있습니다.
                      <br />
                      (1) 게시된 내용이 본 약관에 위배되는 경우
                      <br />
                      (2) 공공질서 및 미풍양속을 위반하는 내용인 경우
                      <br />
                      (3) 다른 회원 또는 제3자를 비방하거나 명예를 훼손하는
                      내용인 경우
                      <br />
                      (4) 범죄 행위와 관련 있다고 인정되는 내용인 경우
                      <br />
                      (5) 서비스 성격에 부합하지 않은 내용인 경우
                      <br />
                      (6) 회사의 저작권, 제3자 저작권등 기타 권리를 침해하는
                      내용인 경우
                      <br />
                      (7) 상업용 또는 불법, 음란, 저속하다고 판단되는 내용인
                      경우
                      <br />
                      (8) 기타 관계법령에 위반된다고 판단되는 경우
                      <br />
                      2. 회사는 회원의 게시물을 소중하게 생각하며
                      변조,훼손,삭제되지 않도록 최선을 다하고, 회원간 통신
                      내용에 대한 보안유지에도 최선을 다합니다.
                      <br />
                      3. 사이트(개인 신상자료 등 포함)에 등록된 게시물에 대한
                      보존의 의무는 회원 개인이 가지며 그 보존의 책임은 회사가
                      지지 않습니다.
                      <br />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-9">
                    <h3 className="my-3">
                      제 9 조 (서비스 제공의 제한 및 중지)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는
                      일부를 제한하거나 중지할 수 있습니다.
                      <br />
                      (1) 회원이 제9조에 정해진 회원의 의무를 위반하거나 기타 이
                      약관 및 서비스안내, 관계법령에 위반한 경우
                      <br />
                      (2) 미풍양속을 저해하는 비속한 ID 및 닉네임사용
                      <br />
                      (3) 타인 또는 제3자나 이용자에게 심한 모욕 또는 불이익을
                      주거나, 서비스 이용을 방해한 경우
                      <br />
                      (4) 회사의 서비스 운영을 고의로 방해하거나 회사의 운영진,
                      직원, 관계자를 사칭하는 경우
                      <br />
                      (5) 회원 가입신청 또는 개인정보 변경 시 허위 내용을 등록한
                      경우
                      <br />
                      (6) 회사 서비스 정보를 이용하여 얻은 정보를 회사 사전
                      승낙없이 복제 또는 유통하거나 상업적으로 이용하는 경우
                      <br />
                      (7) 안정적 서비스 운영을 방해하거나 영리를 목적으로 다량
                      정보를 전송하거나 광고성 정보를 전송하는 경우
                      <br />
                      (8) 회사, 다른 이용자 또는 제 3자의 지적재산권을 침해하는
                      경우
                      <br />
                      (9) 서비스 프로그램상의 버그를 악용하여 정상적이지 아니한
                      방법으로 사이버자산을 취득하거나 사이버자산을 타인과
                      거래하는 행위를 한 경우
                      <br />
                      (10) 정상적인 서비스 운영에 방해가 될 경우
                      <br />
                      (11) 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가
                      있는 경우
                      <br />
                      (12) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
                      (정기점검, 긴급조치 등)
                      <br />
                      (13) 국가비상사태, 천재지변, 정전, 제반 설비의 장애 또는
                      이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때
                      <br />
                      (14) 새로운 서비스로 교체 및 서비스를 제공할 수 없는 사유
                      발생시
                      <br />
                      (15) 회사의 서비스 개선을 위한 회사 정책 상 불가피한 경우
                      <br />
                      (16) 기타 불가항력적 사유가 있는 경우
                      <br />
                      2. 위 제11조 제1항에 의하여 회사는 서비스를 이용하는
                      회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의
                      일시정지, 초기화, 이용계약 해지 등을 할 수 있다
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-10">
                    <h3 className="my-3">
                      제 10 조 (이용자의 ID, 비밀번호 및 접속종료에 대한 의무)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회원의 ID와 비밀번호에 관한 모든 관리책임은
                      회원본인에게 있습니다.
                      <br />
                      2. 회원은 본인의 ID 및 비밀번호를 제3자에게 이용하게
                      해서는 안됩니다.
                      <br />
                      3. 회원은 본인의 ID 및 비밀번호를 도난 당하거나 제3자가
                      사용하고 있음을 인지한 경우에는 바로 회사에 통보하고
                      회사의 안내가 있는 경우에는 그에 따라야 합니다.
                      <br />
                      4. 회원은 본 서비스의 매 사용 종료 시마다 정확히 접속을
                      종료하도록 하여야 하며, 정확히 종료 하지 아니함으로써
                      제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과에
                      의하여 귀하에게 발생하는 손해 및 손실에 대하여는 회사는
                      책임을 부담하지 아니합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-11">
                    <h3 className="my-3">제 11 조 (이용계약의 해지)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회원이 이용계약을 해지하고자 할 경우 회사가 안내하는
                      방법으로 회원 본인이 회사에게 해지 신청을 하고, 회사는
                      확인절차를 거쳐 이용계약을 해지합니다. 해지 처리를 하는
                      과정에서 시스템의 문제나 불량회원 등록 확인 등으로 수일의
                      시간이 필요하다고 판단될 때는 회원에게 사유를 공지하고
                      추후에 해지 처리하게 됩니다.
                      <br />
                      2. 회사는 회원이 본 약관 또는 서비스안내 기타 관계법령에
                      위배되는 행위를 하였을 경우, 제공 서비스의 일부 또는
                      전체에 대한 이용권한을 사전 통지 없이 해지 할 수 있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-12">
                    <h3 className="my-3">제 12 조 (면책조항)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여
                      서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
                      면제됩니다.
                      <br />
                      2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나
                      정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이
                      면제됩니다.
                      <br />
                      3. 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대하여
                      책임을 지지 않습니다.
                      <br />
                      4. 회사는 회원이 회사의 서비스 제공으로부터 기대되는
                      이익을 얻지 못하였거나, 서비스 자료에 대한 취사 선택 또는
                      이용으로 인해 발생하는 손해 등에 대해서는 책임이
                      면제됩니다.
                      <br />
                      5. 회사는 회원이 사이트에 제공한 개인정보 및 게재한 정보,
                      자료, 사실의 신뢰도, 정확성 등 그 내용에 관해서 책임이
                      면제됩니다.
                      <br />
                      6. 회사는 회원의 게시물 등의 자료유실, 파손 등에 대한
                      피해에 대해 책임지지 않으며, 따라서 회원은 자료 등에
                      대하여 항시 별도 보관 조치를 취해야 합니다.
                      <br />
                      7. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등
                      부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
                      <br />
                      8. 회사는 이용자의 컴퓨터 오류에 의한 손해가 발생한 경우,
                      또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여
                      손해가 발생한 경우 책임을 지지 않습니다.
                      <br />
                      9. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한
                      손해에 대하여 책임을 지지 않습니다.
                      <br />
                      10. 회사는 회원의 신원에 대한 보증을 하지 않습니다. 또한
                      회사는 회원이 서비스를 이용하며 타회원으로 인해 입게 되는
                      정신적, 물질적 피해에 대하여 보상할 책임을 지지 않습니다.
                      <br />
                      11. 회사는 이용자 상호간 및 이용자와 제3자 상호간에
                      서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며,
                      이로 인한 손해를 배상할 책임도 없습니다.
                      <br />
                      12. 회사는 회원에게 무료로 제공하는 서비스의 이용과
                      관련해서는 어떠한 손해도 책임을 지지 않습니다.
                      <br />
                      13. 본 사이트와 연결되어 제3자에 의하여 유지되는 다른
                      인터넷 사이트들의 내용들에 대하여 회사는 아무런 통제권한이
                      없습니다. 따라서 다른 인터넷 사이트에 포함된 정보의
                      정확도와 신뢰성에 대하여 회사는 아무런 법적 책임을 지지
                      않습니다.
                      <br />
                      14. 회사는 회원이 본 약관 규정을 위배하여 발생한 손실에
                      대하여는 책임을 지지 않습니다.
                      <br />
                      15. 회원이 공개를 허락한 자료에 대하여 발생된 문제에
                      대하여서는 책임이 면제됩니다.
                      <br />
                      16. 회사는 회원이 게재하거나 타인과 통신하는 정보, 자료,
                      사실에 대한 신뢰도, 정확성 및 그 내용에 대하여 알지
                      못하며, 그로 인해 사회적, 윤리적, 법적으로 문제가 발생 시
                      그 책임을 지지 않습니다.
                      <br />
                      17. 회원이 회사가 제공하는 제휴서비스 이용시 서비스이용의
                      필요에 의해 제휴사에 제공하는 개인정보에 대해서는 본인의
                      동의하에 제공된 것으로 간주하고 제공한 개인정보로 인해
                      발생한 문제에 대해서 회사는 책임을 지지 않습니다.
                      <br />
                      18. 회사는 당사가 제공한 서비스가 아닌 가입자 또는 기타
                      제휴사가 제공하는 서비스의 내용상의 정확성, 완전성 및 질에
                      대하여 보장하지 않습니다. 따라서 회사는 회원이 위 내용을
                      이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에
                      대하여 책임을 부담하지 아니합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-13">
                    <h3 className="my-3">
                      제 13 조 (회원에 대한 통지 및 정보제공)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회원에 대한 통지 및 예고는 사이트에 게시하거나 서면,
                      전화, 전자우편(e-mail)의 방법으로 할 수 있습니다.
                      <br />
                      2. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한
                      문자, 음성, 사진, 영상자료에 대해서 사이트게시, 전자우편,
                      전화, 모바일서비스, 공지사항 등으로 회원에게 제공할 수
                      있습니다.
                      <br />
                      3. 회사는 회원들에게 보다 좋은 서비스를 제공하거나 회사의
                      새로운 상품을 제공하기 위해서 전자우편, 전화, SMS, 서면
                      등의 방법으로 회원과 영업을 목적으로 한 상담 및 정보제공을
                      할 수 있습니다.
                      <br />
                      4. 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의
                      목적으로 회원의 동의 하에 추가적인 개인정보를 요구할 수
                      있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-14">
                    <h3 className="my-3">
                      제 14 조 (광고게재 및 광고주와의 거래)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회사가 회원에게 서비스를 제공할 수 있는 서비스
                      투자기반의 일부는 광고게재를 통한 수익으로부터 나옵니다.
                      회원은 서비스 이용시 노출되는 광고게재 및 전송에 대해
                      동의합니다.
                      <br />
                      2. 회사는 서비스상에 게재되어 있거나 본 서비스를 통한
                      광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를
                      함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-15">
                    <h3 className="my-3">제 15 조 (약관의 해석)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      이 약관 및 서비스안내에서 정하지 아니한 사항과 이 약관 및
                      서비스안내의 해석은 관계법령 및 상관습에 따릅니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-16">
                    <h3 className="my-3">제 16 조 (분쟁해결)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 회사는 회원으로부터 제출되는 불만사항 및 의견은
                      우선적으로 그 사항을 처리하도록 노력합니다.
                      <br />
                      2. 회원의 서비스 이용과 관련하여 당사자 간에 다툼이 발생한
                      경우 당사자의 협의로 처리하되 신의성실의 원칙에 따라
                      원만히 해결함을 원칙으로 합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-17">
                    <h3 className="my-3">제 17 조 (관할법원 및 준거법)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      1. 서비스 이용과 관련하여 회사와 회원간에 발생한 분쟁에
                      관한 소송은 당사 본점 소재지를 관할하는 법원을
                      관할법원으로 합니다.
                      <br />
                      2. 본 서비스 이용과 관련하여 회사와 회원간에 제기된
                      소송에는 한국 법을 적용합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-18">
                    <div style={{ lineHeight: "37px" }}>
                      부칙
                      <br />
                      1. 이 약관은 2024년 04월 04일부터 시행합니다.
                    </div>
                  </td>
                </tr>
              </tbody>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Privacy;
