import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";

import { ReactComponent as Star } from "./Star.svg";
import BackImg1 from "./review.png";
import BackImg2 from "./review3.png";
import BackImg3 from "./review4.png";
import BackImg4 from "./review5.jpg";
import BackImg5 from "./review2.png";
import BackImg6 from "./banner107.jpeg";
import BackImg7 from "./banner109.jpeg";
// import BackImg8 from "./review2.png";
import BackImg9 from "./couple_banner110.jpeg";
import BackImg10 from "./couple_banner111.jpeg";
import banner8 from "./couple8.jpeg";
import BackImg0818 from "./couple0818.jpeg";
import BackImg0909 from "./couple_0909.jpg";
import "./review.scss";

function PhotoReviewPage(props) {
  

  const [dummy1] = useState([
    {
      header: "9월 1주차 커플",
      stars: 5,
      name: "9월 1주차\n커플 후기",
      desc:  "상담과 매칭을 동일한 매니저분이 진행해주셔서, 상담에서 말했던 희망하시는 분을 만날 수 있던 점입니다. 자기소개를 자세하게 쓴다는 점이 좋았습니다. 아무래도 만나기 전에 상대분이 어떤 성향인지 알기가 어려운데 자기소개를 통해 초반에 적은 만남으로도 상대 분을 이해할 수 있었던 것 같아요.",
      id:12,
      image:BackImg0909,
    },
    {
      header: "8월 2주차 커플",
      stars: 5,
      name: "8월 2주차\n커플 후기",
      desc:  "담당매니저님이 매칭+상담까지 같이 해주시기 때문에 믿음이 갔습니다. 궁금한 사항 같은 게 있으면 담당 매니저님이 다 알고 있는 사항이기에 빠르게 고민 해결할 수 있었습니다. 그리고 파티에 가서 알게된 점으론 1.대표님이 프로필 사진에 진심인 점(프사기 절대 용납 불가)이 사진에 대한 신뢰가 갔습니다. 대부분의 사람들이 사진보다 실물이 훨씬 나으시더라구요! 2.파티에 신경을 정말 많이 써주셔서 infp인 저도 재밌게 잘 즐길 수 있어서 좋았습니다. 3.파티에서 만난 사람들이 자기 담당 매니저 칭찬하기 바쁠 정도로 매니저님들이 다 좋으십니다. 결정사를 통해 알게 된 매니저님이지만 어느순간 친한 지인이 소개팅을 해주는 착각이 들 정도로 세심히 신경써 주십니다.(이정은 매니저님 최고!)",
      id:11,
      image:BackImg0818,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      desc:  "다른 결정사는 자소서가 짧다고 들었는데 우연은 의무적으로(?!) 자소서를 정성들여 작성해야해서 그런지 구체적인 자소서 내용을 통해서 상대에 대해 어느 정도 알고 만날 수 있어서 만나서 대화할 때 덜 어색한 분위기가 형성될 수 있는 것 같아서 좋았습니다.아무래도 만나서 하기 어려운 주제를 처음부터 오픈한다는 게 좋았어요. 시간낭비를 안할 수 있고요. 그리고 애써주시는 매니저님과 평소에 의사소통이 잘되어서 그점이 가장 마음에 들었습니다!",
      id:10,
      image:BackImg10,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      desc:  "여러가지 조건과 상대방의 검증된 프로필을 볼 수 있어서 좋았고 다른 결혼정보회사보다 합리적인 가격으로 성혼에 목표를 두어 만남성사에 진심인 부분이 좋았습니다.담당 매니저님이 상담할 때 원하는 이상형을 파악하기 위해 정량적인 조건들에 대해 구체적으로 질문해 주세요.\n\n또한 확실히 또래 매니저님 한 분이 상담&매칭을 전담하다보니 머리부터 메이크업, 복장 등 여러 가지 부분에 대해 코칭을 해주셨는데 많은 도움이 되었습니다.",
      id:9,
      image:BackImg9,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      desc:  "합리적이고 납득 가능한 상식적인 서비스 거짓이 섞이지 않은 날것으로 깐깐한 프로필 작성 특히 무신경한 남자들에게 이성에게 어필에 필요한 것들까지 챙겨주는 세심함이 좋습니다.일단 캐쥬얼한 결정사라는 느낌이 강했어서, 접근성이 좋았던 것 같아요.\n\n저도 결정사는 처음이라 무서웠지만, 적당한 값을 지불하고 소개팅의 퀄리티를 올려보자!하고 도전했던 거거든요.\n\n진짜 결혼을 해야만 성혼비를 지급하는 시스템도 매니저님께서 내 이상형을 찾아주실 수 밖에 없는 구조겠고, 제 취향에 맞는 이성을 만날 확률을 높히는데 도움이 될거라고 생각했습니다.",
      id:8,
      image:banner8,
    },
    {
      header: "7월 4주차 커플",
      stars: 5,
      name: "7월 4주차\n커플 후기",
      desc:  "처음 상담 때 성향 파악을 잘 해주시고 그에 맞는 매칭을 잘 해 주셨습니다.합리적인 가격과 상담 매니저와 매칭 매니저가 같다는 점이 만족스러웠어요.",
      id:7,
      image:BackImg7,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc:  "매니저님이 소통을 잘해주신것이 가장 좋았고 이런 이유로 좋은 인연을 만날수 있었던 것 같습니다. 직접 상담해주시는 매니저님이 또래라는 점이 편하고, 말도 잘통했어요!",
      id:6,
      image:BackImg6,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc: "매니저의 세심한 상담과 매칭, 프사기 방지 시스템, 그리고 정확한 정보 확인 덕분에 첫 매칭에서 바로 교제를 시작할 수 있었습니다. 1시간 이상 상담을 통해 단순히 글로 적힌 저의 니즈를 파악하는 것이 아닌 글로 표현할 수 없는 부분을 확인하셨기 때문에 가능했다고 생각합니다.",
      id:5,
      image:BackImg5,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc: "까다로운 요구에도 불구하고 매니저와의 원활한 소통 덕분에 좋은 동반자를 만날 수 있었습니다. 매니저가 첫 상담부터 객관적으로 자신을 파악하고 원하는 조건을 잘 반영해주어 만족스러웠습니다.",
      id:4,
      image:BackImg4,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc: "우연의 상담매니저와 매칭매니저가 동일해 개인의 성향을 잘 파악하고 어울리는 짝을 추천해주는 시스템이 마음에 들었습니다. 매니저의 친절한 소통과 조언 덕분에 원하는 상대를 잘 만날 수 있었고, 사진과 실제 모습의 일치로 신뢰감을 느꼈습니다.",
      id:3,
      image:BackImg3,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc: "결정사에서 이렇게 좋은 사람을 만날 수 있을거라고 생각 못했어요. 남자친구같이 멋있고 결혼에 긍정적인 사람과 만난 게 가장 만족스러워요. 주말, 쉬는날에도 궁금한 점에 대해서 빠르게 답변 해주셔서 좋았습니다. ",
      id:2,
      image:BackImg2,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      id:1,
      image:BackImg1,
    },
    
  ]);

  useEffect(() => {
    document.title = "커플 후기 | 우리의 인연";
  }, []);

  const callStars = (num) => {
    return (
      <div className="stars">
        {Array.from({ length: num }, (_, i) => (
          <Star key={i} />
        ))}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content customWrap photoReviewWrap">
        <Container>
          <h1 className="ReviewTitle">커플 후기</h1>
          <Row>
            {dummy1?.map((item, i) => {
              return (
                <Col lg={6} key={i} className="d-flex justify-content-center">
                  <Card
                    className="reviewCard"
                    onClick={() => (window.location.href = "/review/detail/"+item.id)}
                  >
                    <Row className="reviewCardHeader w-100 m-0">
                      <Col className="reviewCardHeaderName">{item.header}</Col>
                      <Col className="reviewCardHeaderStar">
                        {callStars(item?.stars)}
                      </Col>
                    </Row>
                    <Row
                      className="reviewCardBody w-100 m-0"
                      style={{
                        backgroundImage: `url(${item.image})`,
                      }}
                    >
                      <Col className="d-flex flex-column p-0">
                        <div
                          className="reviewCardName"
                          
                          
                        >
                          {item.name}
                          </div>
                        {/* <div className="reviewCardDesc">{item.desc}</div> */}
                        {/* <div className="reviewCardMore">더 보기</div> */}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PhotoReviewPage;
