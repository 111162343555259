import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import { ReactComponent as Assignment } from "./Assignment.svg";

function WaringModal({ setWaring }) {
  const [timesent, setTimesent] = useState(false);
  const history = useHistory();
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const hideForAnHour = () => {
    if (timesent) {
      const now = new Date().getTime();
      const oneHourLater = now + 3600000;
      localStorage.setItem("hideUntil", oneHourLater.toString());
    }
    setWaring(true);
  };

  // <Row className="w-100 m-0 mt-3">
  //   <Col className="px-5 d-flex align-items-center">
  //     <input
  //       type="checkbox"
  //       value={timesent}
  //       id="oneHour"
  //       style={{
  //         width: "20px",
  //         height: "20px",
  //         marginRight: "5px",
  //       }}
  //       onClick={() => setTimesent(!timesent)}
  //     />
  //     <label htmlFor={`oneHour`} className="m-0">
  //       한 시간 동안 보이지 않기
  //     </label>
  //   </Col>
  // </Row>
  // <Button
  //   color="primary"
  //   id="masterAccesBtn"
  //   className="w-50"
  //   style={{ borderRadius: "0px" }}
  //   onClick={() => hideForAnHour()}
  // >
  //   승인
  // </Button>
  // <Button
  //   id="masterAccesBtn"
  //   className="w-50"
  //   style={{ borderRadius: "0px" }}
  //   onClick={() => history.goBack()}
  // >
  //   거절
  // </Button>
  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "34px 16px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center text-center primiumMainText"
                  style={{ marginBottom: "20px" }}
                >
                  <span className="capSvgWrap">
                    <Assignment />
                  </span>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{ marginBottom: "24px" }}
                >
                  캡처금지 및 안내사항
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 primiumSubText"
                  style={{ marginBottom: "15px" }}
                >
                  <div>
                    <textarea
                      className="form-control"
                      value={`이성 회원의 사진 및 개인정보를 무단으로 캡쳐 및 외부 공유 시 개인정보 보호법에 의거하여 법적 처벌을 받을 수 있습니다.

이성과의 만남 시 아래의 내용에 해당하는 행위를 금지합니다.

1. 다른 이성과 교제 중임에도 소개를 받는 경우

2. 일방적인 약속 시간/장소 변경, 만남 불참, 지각, 복장 불량, 기타 성의 없는 만남 

3. 사회 통념상 부적절한 언행으로 이성에게 심한 불쾌감을 주는 경우

4. 상대방이 거부함에도 연락을 하는 등의 행위로 정신적, 육체적인 피해를 주는 경우

5. 회원으로 인해 회사의 신용이나 명예가 훼손되는 일이 발생한 경우(상대회원의 탈퇴 등) 

위 내용 발생 시, 회원 가입비 환불은 불가하며 또한 회사는 해당 행위를 한 회원에게 위약금을 청구할 수 있습니다. 우연은 법무법인(유) 산우와 함께 합니다. `}
                      rows={6}
                      readOnly
                      style={{
                        padding: "10px",
                        resize: "none",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col
                  className="px-5 d-flex align-items-center justify-content-center"
                  style={{ marginBottom: "34px" }}
                >
                  <input
                    type="checkbox"
                    value={timesent}
                    id="oneHour"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    onClick={() => setTimesent(!timesent)}
                  />
                  <label htmlFor={`oneHour`} className="m-0">
                    한 시간 동안 보이지 않기
                  </label>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => hideForAnHour()}>
                    네, 확인했습니다
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default WaringModal;
