import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Col, Row } from "reactstrap";

import "../Consult/consult.scss";
import BackImg from "../../assets/img/others/birthBack.png";
import { requestGet } from "../../apis/request";
import ErrorModal from "./ErrorModal";

function MyProfile(props) {
  const [values, setValues] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [callErrorModal, setCallErrorModal] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  const submitApi = async (e) => {
    e.preventDefault();
    if (values) {
      try {
        const params = new URLSearchParams();
        params.append("birthday", values);
        await requestGet(`/api/introduce/info/${id}?${params}`);
        window.location.href = `/introduce/detail/${id}/${values}`;
      } catch (error) {
        if (error.data.error) {
          setMessage(error.data.error);
        } else if (error.data.message) {
          setMessage(error.data.message);
        }
        setError(true);
        setCallErrorModal(true);
      }
    } else {
      setMessage("생년월일 필수 입력 입니다.");
      setError(false);
      setCallErrorModal(true);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length === 9) {
      setValues(e.target.value.substr(1));
    } else {
      setValues(e.target.value);
    }
  };

  const callInfp = useCallback(async () => {
    try {
      const response = await requestGet(`/api/introduce/check/${id}`);
      setName(response.name);
    } catch (error) {
      if (error.data.error) {
        setMessage(error.data.error);
      } else if (error.data.message) {
        setMessage(error.data.message);
      }
      setError(true);
      setCallErrorModal(true);
    }
  }, [id]);

  useEffect(() => {
    callInfp();
  }, [callInfp, id]);

  return (
    <React.Fragment>
      <div
        className="container d-flex align-items-center"
        style={{ height: "100vh" }}
      >
        <img
          alt="back"
          src={BackImg}
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100vh",
          }}
        />
        <div className="d-flex flex-column">
          <div className="customMainText" style={{ zIndex: 1 }}>
            나의 프로필
          </div>
          <form
            className="js-step-form"
            onSubmit={submitApi}
            style={{ zIndex: 1 }}
          >
            <div className="row">
              <div id="formContainer" className="col-lg-12">
                <div id="uploadResumeStepFormContent">
                  <div className="customCard">
                    <Row>
                      <Col lg={12}>
                        <div className="customCardText">
                          프로필을 확인하시려면 <br />
                          주민번호 앞 8자리를 입력해 주세요.
                        </div>
                      </Col>
                      <Col
                        lg={12}
                        className="d-flex flex-column"
                        style={{ marginBottom: "34px" }}
                      >
                        <div>
                          <input
                            type="number"
                            className="js-input-mask form-control customCardInput"
                            name="birthday"
                            id="nameLabel"
                            maxLength={8}
                            placeholder="생년월일 8자리 (예: 19881210)"
                            aria-label="생년월일 8자리 (예: 19881210)"
                            value={values}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="introCustomButtonWrap">
                          <button
                            type="submit"
                            className="introCustomPrimiumButton"
                          >
                            확인
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {callErrorModal ? (
        <ErrorModal
          message={message}
          setMessage={setMessage}
          setCallModal={setCallErrorModal}
          error={error}
          setError={setError}
        />
      ) : null}
    </React.Fragment>
  );
}

export default MyProfile;
