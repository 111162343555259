import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import MobileImg1 from "./coupleMo1.jpg";
import PcImg1 from "./coupleWeb1.jpg";
import MobileImg2 from "./coupleMo2.jpg";
import PcImg2 from "./coupleWeb2.jpg";
import MobileImg3 from "./coupleMo3.jpg";
import PcImg3 from "./coupleWeb3.jpg";
import MobileImg4 from "./coupleMo4.jpg";
import PcImg4 from "./coupleWeb4.jpg";
import MobileImg5 from "./coupleMo5.jpg";
import PcImg5 from "./coupleWeb5.jpg";
import { useParams } from "react-router-dom"; 
import man from "./woman.png";
import woman from "./man.png";
import banner from "./couple_banner1.jpeg";

import banner111 from "./couple_banner111.jpeg";
import banner110 from "./couple_banner110.jpeg";
import banner108 from "./banner109.jpeg";
import banner107 from "./banner107.jpeg";
import banner8 from "./couple8.jpeg";

import banner1 from "./banner1.jpeg";
import banner2 from "./banner2.jpeg";
import banner3 from "./banner3.jpeg";
import banner4 from "./banner4.jpeg";
import banner5 from "./banner5.jpeg";
import BackImg0818 from "./couple0818.jpeg";
import BackImg0909 from "./couple_0909.jpg";
import "./couple-detail.css";

const ReviewDetail = (props) => {
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [data, setData] = useState([]);

  const [dummy1] = useState([
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
          "message": "자기소개 부탁드립니다."
      },
      {
          "type": "male",
          "message": "안녕하세요. 올해 34살이고 경영기획자로 일하고 있어요. 업무 특성상 항상 T로 오해받는데 감성적인 F남자입니다! 이번에 우연을 통해서 커플이 되면서 후기를 남기게 됐어요 :)"
      },
      {
          "type": "female",
          "message": "강아지를 좋아하는 수줍음이 많은 93년생 집순이 입니다~ :-)"
      },
      {
          "type": "question",
          "message": "우연을 어떻게 알게 되셨나요?"
      },
      {
          "type": "male",
          "message": "김달님 유튜브를 통해 접하게 되었습니다."
      },
      {
          "type": "female",
          "message": "김달님 유튜브를 통해 우연을 알게 되었습니다 :)"
      },
      {
          "type": "question",
          "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
      },
      {
          "type": "male",
          "message": "어려서부터 가정을 꾸리고 싶어했어요. 집과 인테리어에도 관심이 많고 요리하는 것도 좋아해서 가정적인 사람을 만나고 싶었어요. 그런데 성향이나 성격은 만나보기 전에는 알 수 없는 부분이라서 매니저님에게 외적으로 선한 인상 + 회사가 가까운분을 선호한다고 말씀드렸습니다. 여자친구 처음 만났을 때 웃는 모습이 너무 선해서 대화만 잘 통했으면 좋겠다 싶었어요. ㅋㅋ 결과적으로는 잘 만난 것 같아요!"
      },
      {
          "type": "female",
          "message": "저는 구체적인 이상형은 없고, 대신 가치관이 잘 맞고 함께 있을 때 편안함을 느낄 수 있는 사람을 만나고 싶었어요."
      },
      {
          "type": "question",
          "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
      },
      {
          "type": "male",
          "message": "우연에서 첫 소개로 지금의 여자친구를 만났어요. 회사 근처 카페에서 만났는데, 연애에 대한 질문은 거의 없이 그냥 서로 직장이야기만 잔뜩 하고 왔어요. 오히려 부담스러운 대화가 아니라서 어색함보다는 편안한 느낌이 컸어요!\n\n분위기가 편안했다보니 자연스럽게 다음 만남으로 이어졌어요. 제 직장이랑 여자친구 집이랑 가깝다보니 퇴근 후에 가볍게 공원 산책하면서 이야기할 기회가 많았어요. 첫 만남 다음날에도 보고, 평일에 퇴근하고도 만나고, 주말마다 데이트하면서 열흘동안 네번이나 만났더라고요.\n\n이전에는 주 1회 만나면 충분하다 생각했는데, 마음이 생기니 어느샌가 제가 자꾸 약속을 만들고 있더라고요 ㅋㅋㅋ\n\n초반이 가장 궁금한 것도 많고 조심스러울 때인데 자주 만나서 대화를 하다보니 서로에 대해서 더 깊이 알게되고 빠르게 가까워진 것 같아요."
      },
      {
          "type": "female",
          "message": "첫 만남 전에 전달 받은 프로필의 자기소개를 꼼꼼하게 읽어보려 했어요. 자기소개 글에서 남자친구의 가치관이 저와 비슷한 부분이 많았고, 실제로 만나서 이야기 할때에도 대화가 잘 통한다는 느낌이 많이 들었어요. 첫 만남 이후에는 가능한 자주 만나고 얘기하면서 충분히 서로를 알아가는 시간을 가졌고, 마음을 어느정도 확인한 후로는 빠르게 가까워 진 것 같아요."
      },
      {
          "type": "question",
          "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
      },
      {
          "type": "male",
          "message": "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹"
      },
      {
          "type": "female",
          "message": "매칭 기간 동안 매니저님이 친근하고 적극적으로 서포트 해주셨어요. 든든한 지원군(?) 같이 저에게 자존감 올려주는 말도 많이 해주셨네요 ^_^"
      },
      {
          "type": "question",
          "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
      },
      {
          "type": "male",
          "message": "아무래도 결정사이고, 한살 한살 나이가 들수록 현실적인 조건들이 눈에 걸리는게 사실인데요. 누군가를 비교하면서 만나면 좋은 분을 놓칠 수 있겠다 싶어서 한명씩만 소개받으려고 했어요\n\n우연에서의 첫 소개가 여자친구이고, 덕분에 커플이 되서 꽁냥거릴 날만 남겨두고 있습니다.  :D\n\n우연에 계신분들은 다른 곳보다는 연애와 결혼에 대해서 생각도 깊으시고 진지하신분들이실테니 꼭 좋은 인연 만나시길 바랄게요!"
      },
      {
          "type": "female",
          "message": "마음이 잘 맞는 지는 충분한 대화를 거쳐야 알 수 있는 부분이어, 제한된 시간 안에 상대방을 파악해야 하는 소개를 통해 누군가를 만나는 것이 어려웠어요. 그래서 사실 큰 기대는 하지 않고 우연에 가입했는데, 포장되지 않은 시스템과 빠른 피드백 덕분에 저도 진솔한 마음으로 빠르게 짝을 찾을 수 있던것 같아요. 감사합니다~"
      }
      ],
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      id:1,
      image:banner1,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
          "message": "자기소개 부탁드립니다."
      },
      {
          "type": "male",
          "message": "안녕하세요 저는 94년생이고, 디스플레이 제조업에서 사무직으로 일하고 있습니다"
      },
      {
          "type": "female",
          "message": "안녕하세요 저는 초등학생 방과후센터 돌봄교사로 근무하고 있는 95년생입니다"
      },
      {
          "type": "question",
          "message": "우연에는 어떻게 가입을 하게 되셨어요?"
      },
      {
          "type": "male",
          "message": "유튜브 김달님 구독자여서 영상 보고 가입하게 되었습니다."
      },
      {
          "type": "female",
          "message": "김달님 구독자라 영상 보고 가입했어요."
      },
      {
          "type": "question",
          "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
      },
      {
          "type": "male",
          "message": "단순 연애가 아니라 평생 함께하고 싶은 사람을 만나고 싶었습니다. 여성스러우면서 자존감이 높고, 결혼에 대한 가치관이 뚜렷한 사람이 이상형 이었습니다."
      },
      {
          "type": "female",
          "message": "저 역시 결혼을 염두에 둔 만남을 원했기에 평생 동반자로서 잘 맞고, 서로 존중할 수 있고, 아이의 아빠로서도 좋은 점을 두루 가진 사람을 만나고 싶었어요."
      },
      {
          "type": "question",
          "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
      },
      {
          "type": "male",
          "message": "매칭되어 두 번쨰 만났을 때 서로 호감을 표시하였고, 계속 연락하면서 확신이 생겼습니다. 그리고 세 번째 만남떄 연인으로 발전하였습니다."
      },
      {
          "type": "female",
          "message": "저는 오빠가 첫눈에 너무 마음에 들었는데 진솔한 대화를 통해 서로 같은 마음인 걸 알았고 고백을 받아 사귀게 되었습니다."
      },
      {
          "type": "question",
          "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
      },
      {
          "type": "male",
          "message": "우선 좋은 사람 소개시켜 주셔서 너무 감사하고, 매칭주신 다른 분들도 저의 이상형에 맞추어서 잘 해주신것 같습니다. 그리고 주말, 쉬는날에도 궁금한 점에 대해서 빠르게 답변 해주셔서 좋았습니다."
      },
      {
          "type": "female",
          "message": "결정사에서 이렇게 좋은 사람을 만날 수 있을거라고 생각 못했어요. 남자친구같이 멋있고 결혼에 긍정적인 사람과 만난 게 가장 만족스러워요."
      },
      {
          "type": "question",
          "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
      },
      {
          "type": "male",
          "message": "결정사가 처음이지만 일반 소개팅과 크게 다르다고 느껴지진 않았습니다. 상담과 매칭 매니저가 동일하다는 점이 확실히 좋은것 같습니다."
      },
      {
          "type": "female",
          "message": "가입할 때는 여러 단계들과 숫자로 판단하고 판단되는 기분이 낯설었지만 막상 오빠를 만나보니 무엇보다 그 사람이 먼저 보여요. 우연 화이팅!"
      }
      ],
      desc: "결정사에서 이렇게 좋은 사람을 만날 수 있을거라고 생각 못했어요. 남자친구같이 멋있고 결혼에 긍정적인 사람과 만난 게 가장 만족스러워요. 주말, 쉬는날에도 궁금한 점에 대해서 빠르게 답변 해주셔서 좋았습니다. ",
      id:2,
      image:banner2,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
          "message": "자기소개 부탁드립니다."
      },
      {
          "type": "male",
          "message": "안녕하세요! 인천 사는 90년생 남자입니다. 평생 함께할 짝꿍을 만나기 위해 우연에 가입하게 되었습니다."
      },
      {
          "type": "female",
          "message": "안녕하세요! 의정부 사는 98년생 여자입니다. 결혼정보회사에 가입하기엔 이른 나이지만, 주변에 이성을 만날 기회가 없기도 하고 또 일찍 안정적인 가정을 꾸리고 싶어서 가입하게 되었어요!"
      },
      {
          "type": "question",
          "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
      },
      {
          "type": "male",
          "message": "밝고 긍정적이면서 생각이 깊고 남을 배려할 줄 아는 성격의 여성분을 만나고 싶었습니다. 애교 있는 성격에 많이 녹는 편이라 귀엽고 애교 있는 분이면 좋을 거 같았습니다 ㅎㅎ\n그리고 자기 일에 대한 꿈을 가지고 열심히 노력하는 분께 매력을 크게 느껴서 그런 분을 만나고 싶었습니다.\n외적으로는 하얗고 밝은 분위기에 귀여운 외모를 좋아합니다."
      },
      {
          "type": "female",
          "message": "일단 솔직하면서 다른 사람들의 생각을 존중해 줄 수 있는 분이요! 서로 살아온 길이 다른데 100프로 맞는 사람은 없다고 생각해요. 다만 서로의 다름을 인정하고 존중해 줄 수 있는 분을 만나고 싶었습니다. 그리고 제가 막내딸이라 제 애교와 텐션을 잘 받아주실 분을 원했어요😁\n외적으로는 키는 175이상에 호감형 얼굴이 이상형입니다!"
      },
      {
          "type": "question",
          "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
      },
      {
          "type": "male",
          "message": "소개 카드를 받았을 때 외모도 이상형이었지만 성격과 가치관이 너무 저랑 잘 맞는다고 느꼈습니다. 그리고 제가 여자분의 이상형에 가깝다는 생각도 들었습니다. 그래서 고민 없이 호감을 보냈습니다!\n첫 미팅 장소에서는 세 시간 정도 이야기를 나누었는데 소개 카드에서 본 대로 제가 원하는 이상형에 정말 가까우셔서 마음에 들었고, 그냥 헤어지기에는 너무 아쉬웠습니다. 용기를 내어 영화를 보러 가자고 했는데 흔쾌히 보러 가주셔서 정말 날아갈 것 같았습니다!\n이후에도 호감을 가지고 여러 번 만났습니다. 서로 신중한 편인데다가 평생을 함께할 사람을 만나는 자리이다 보니 20일 정도 서로를 알아가는 시간을 가졌고, 서로에 대한 확신이 생길 때 진지하게 만나보기로 했습니다."
      },
      {
          "type": "female",
          "message": "사실 처음에 호감을 받았을 때는 키가 제 이상형보다 많이 작으셔서 고민이 되었습니다🤔 하지만 자기소개에서 진심이 느껴졌고 제가 원하는 성향과 가치관을 가지신 것 같아서 한번 만나보자는 생각으로 호감을 수락했습니다.\n첫 만남에는 남자분의 인상이 제 기준 호감형이라 좋았고, 가치관과 대화 흐름이 저랑 잘 맞는다고 느꼈습니다. 첫 만남부터 영화는 부담스러울 것 같았는데 그전에 대화가 잘 통해서 그런지 즐거운 시간을 보냈어요!\n이후에도 여러 번 만나면서 서로 잘 맞는다고 느꼈고 제가 평소 했던 말들을 기억해서 챙겨주는 모습에서 진심이 느껴졌습니다. 알아갈수록 좋은 분이라는 걸 느끼고 마음을 열게 되었어용🥰\n제가 많이 신중한 편이고, 연인은 천천히 알아가면서 가까워져야 마음이 열리기 때문에 진지한 만남을 결심하기까지 시간이 좀 걸렸습니다ㅠㅠ 이런 저를 이해하고 기다려 준 오빠가 고마웠어요😍"
      },
      {
          "type": "question",
          "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
      },
      {
          "type": "male",
          "message": "서로에 대한 이상형을 현실적으로 맞추어 주는 시스템이 마음에 들었습니다. 저는 사실 다른 결정사를 하다가 만족을 못하다가 우연에 가입하게 되었습니다. 다른 결정사에서는 상담매니저와 매칭매니저가 달라서 저를 잘 모르고 기계적으로 소개를 해 주는 느낌이 강했습니다. 우연에 가장 끌렸던 점은 상담매니저와 매칭매니저가 같아서 저를 잘 파악하고 저랑 어울릴 만한 분을 추천해 준다는 점이었습니다. 그 덕분인지 정말 마음에 드는 짝을 만난 것 같습니다!"
      },
      {
          "type": "female",
          "message": "매니저님이 정말 친절하셨고 소통을 정말 잘 해주셔서 제가 원하는 분에 대한 반영을 잘 해주셨던 것 같아요! 조언도 잘 해주시고 고민 상담도 잘 받아주셔서 동네 언니 같은 느낌이었어요☺\n그리고 사진과 실제 모습이 너무 다르면 어떨까 걱정했는데 듣던대로 프사기가 없어서 부담 없이 매칭을 받을 수 있었습니다!"
      },
      {
          "type": "question",
          "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
      },
      {
          "type": "male",
          "message": "가입을 고민하고 계신 분들에게는 꼭 용기 내서 가입하시길 추천드립니다! 저도 정말 많은 고민이 있었는데 상담만 받아보자 하고 갔다가 가입하게 되었고, 이렇게 제가 원하는 이상형을 만나게 되어서 너무 만족하고 잘했다고 생각합니다 ㅎㅎ 한 걸음만 용기 내서 나아가면 우연에서 훌륭히 이끌어주실 테니 한번 믿어보세요!"
      },
      {
          "type": "female",
          "message": "우선 상담해 주신 김달님과 매니저님에게 정말 감사드려요! 귀찮을 수 있는데도 진심으로 고민 들어주시고 응원해 주셔서 좋은 만남까지 이뤄낼 수 있었습니다!\n저는 직업 특성상 이성을 만날 기회가 없어서 가입하게 되었는데 저와 같은 분들은 시간 절약은 물론이고 제 이상형에 가까운 분들을 만나볼 수 있으니 정말 후회 없는 선택이 될 거라고 생각해요! 우리 모두 좋은 인연 찾기를 바라요~🥰"
      }
      ],
      desc: "우연의 상담매니저와 매칭매니저가 동일해 개인의 성향을 잘 파악하고 어울리는 짝을 추천해주는 시스템이 마음에 들었습니다. 매니저의 친절한 소통과 조언 덕분에 원하는 상대를 잘 만날 수 있었고, 사진과 실제 모습의 일치로 신뢰감을 느꼈습니다.",
      id:3,
      image:banner3,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
          "message": "자기소개 부탁드립니다."
      },
      {
          "type": "male",
          "message": "안녕하세요. IT정보통신 관련회사를 꾸리고 있는 남자입니다."
      },
      {
          "type": "female",
          "message": "안녕하세요, 저는 28살 여자입니다:)"
      },
      {
          "type": "question",
          "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
      },
      {
          "type": "male",
          "message": "미래를 함께할 분을 만나려는 생각을 했습니다. 목적과 방법과 유사하게, 성격이나 방식은 달라도 바라보는 성향은 비슷한 분을 만나고 싶었습니다. 아니면 서로에게 너무 큰 이해나 배려를 바랄 것 같아서요."
      },
      {
          "type": "female",
          "message": "대화가 잘 통하고 저와 결이 비슷한 분을 만나고 싶었습니다. 가치관, 자라온 환경, 생각 등에서 비슷하고, 함께 공감할 수 있는 것들이 많은 분을 만나고 싶었어요 + 이성적 끌림이 있는가도 중요했습니다."
      },
      {
          "type": "question",
          "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
      },
      {
          "type": "male",
          "message": "처음 만났을 때부터 비슷한 성향의 가치관, 생각... 심지어 말이나 행동에서도 비슷한 부분이 많았습니다. 나름 특이한 저인데 말이죠. 그래서 자꾸 생각이나고, 알아갈 수록 놓치고 싶지 않았습니다. 그래서 더 알아가면서 함께 하고싶어져서 사귀게 되었습니다."
      },
      {
          "type": "female",
          "message": "첫 만남에 정말 많은 공통점이 있어 대화가 술술 이어졌고, 기분 좋은 설렘과 긴장감이 들었던 것 같습니다:)\n그리고 저는 저와 결이 비슷하면서 배울 수 있는 분을 선호하는데 누구보다 삶을 열심히 살아가는 태도, 발전적인 모습에 큰 호감이 갔어요"
      },
      {
          "type": "question",
          "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
      },
      {
          "type": "male",
          "message": "평생의 동반자를 찾기 위한 까다로운 요구에도 불구하고, 잘 소통해주셨습니다. 그렇게 매니저 분과 잘 소통해서 좋은분 만나게 되었습니다. 매니저의 관점에서의 소개 보다 저의 관심을 잘 반영하려고 노력한게 느껴졌습니다."
      },
      {
          "type": "female",
          "message": "무엇보다 매니저님을 가장 잘 만난 것이 가장 감사한 부분입니다! 첫 상담때부터 객관적으로 저를 봐주신 점, 제가 원하는 상대의 포인트를 잘 잡아주셔서 좋았습니다.\n그리고 제가 원하는 조건을 최대한 반영해서 추천해주시는게 느껴졌고, 가운데서 소통을 잘 해주셔서 지금 남자친구를 만나게 된 것 같아요!"
      },
      {
          "type": "question",
          "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
      },
      {
          "type": "male",
          "message": "단순히 만남만을 위한 매칭이 아니라, 저를 결혼을 시켜주려고 하시는 구나라는 느낌을 많이 받았습니다. 프사기나 키사기를 방지하려는 노력의 결과인지, 기대보다 더 예쁘셔서 좋았습니다. 마지막으로 좋은 분과 인연을 맺어준 우연에게 감사의 인사를 드립니다."
      },
      {
          "type": "female",
          "message": "우연을 통해, 제가 찾던 이상향에 가까운 분을 실제로 만나게 되어 감사하고 신기하기도 합니다. 아직 초반이라 더 알아가야 할 부분들이 많지만 좋은 인연으로 잘 맺어가보도록 하겠습니다:)\n우연과 이정은 매니저님 감사합니다☺️"
      }
      ],
      desc: "까다로운 요구에도 불구하고 매니저와의 원활한 소통 덕분에 좋은 동반자를 만날 수 있었습니다. 매니저가 첫 상담부터 객관적으로 자신을 파악하고 원하는 조건을 잘 반영해주어 만족스러웠습니다.",
      id:4,
      image:banner4,
    },
    {
      header: "7월 4주차 커플",
      stars: 5,
      name: "7월 4주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
          "message": "자기소개 부탁드립니다."
      },
      {
          "type": "male",
          "message": "안녕하세요. 저는 연 나이 33살 평범한 직장인입니다."
      },
      {
          "type": "female",
          "message": "안녕하세요! 1996년생 29살 직장인 입니다."
      },
      {
          "type": "question",
          "message": "우연은 어떻게 알게 되셨나요?"
      },
      {
          "type": "male",
          "message": "김달님 유튜브 보고 알게 되었습니다.(기존 구독자X)"
      },
      {
          "type": "female",
          "message": "김달님 유튜브 보고 알게 되었습니다.(기존 구독자)"
      },
      {
          "type": "question",
          "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
      },
      {
          "type": "male",
          "message": "성격이 밝고, 착하고, 유머 감각이 있는 이성분을 만나고 싶었습니다."
      },
      {
          "type": "female",
          "message": "저와 가치관이 비슷한 사람을 만나고 싶었습니다."
      },
      {
          "type": "question",
          "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
      },
      {
          "type": "male",
          "message": "우연으로 처음 프로필을 교환하여 첫 만남을 시작하게 되었습니다. 첫 만남 때 눈앞에 있는 이성을 놓치고 싶지 않다는 생각에 헤어지고 나서 연락처를 물어봤고, 다행히 연락처를 주셔서 연인으로 발전해 나갈 수 있도록 매 주말마다 만나서 제가 느끼고 있는 감정을 표현한 결과 연인으로 발전할 수 있었습니다."
      },
      {
          "type": "female",
          "message": "우연히 저희 둘은 같은 매니저님 회원이었고 매니저님의 카드 소개로 제가 먼저 호감 표현을 했습니다.\n이후 네 번의 만남을 통해 서로를 알아가고 교제를 시작하게 되었습니다."
      },
      {
          "type": "question",
          "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
      },
      {
          "type": "male",
          "message": "제가 선호하는 이성을 만날 수 있도록 매니저님께서 여자친구에게 제 자랑을 많이 해주셔서 첫 만남이 이루어질 수 있었습니다. 감사합니다."
      },
      {
          "type": "female",
          "message": "1. 매니저 상담, 매칭 2. 프사기 방지 및 우연 촬영 3. 정확한 정보 확인\n\n1. 매니저 상담, 매칭: 제가 첫 매칭을 통해 바로 교제를 시작할 수 있었던 건 한 분의 매니저님께서 상담, 매칭을 해주셨기 때문이라고 생각합니다. 1시간 이상 상담을 통해 단순히 글로 적힌 저의 니즈를 파악하는 것이 아닌 글로 표현할 수 없는 부분을 확인하셨기 때문에 가능했다고 생각합니다.\n\n2. 프사기 방지 및 우연 촬영: 우연은 프사기 방지 시스템이 강해서 그런지 사진보다 실물이 훨씬 좋았어요. 그리고 우연 포토그래퍼님이 제 매력을 최대한 돋보이게 사진에 담아주시고 저 또한 상대방의 모습을 있는 그대로 받아볼 수 있어서 좋았습니다.\n\n3. 정확한 정보 확인: 각종 증명서를 제출하는 과정이 쉽지 않았지만 그만큼 신뢰를 할 수 있었습니다."
      },
      {
          "type": "question",
          "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
      },
      {
          "type": "male",
          "message": "감사합니다. 저를 아껴주고 사랑해 줄 사람은 지금 여자친구밖에 없을 것 같습니다. 좋은 인연 만들어 나갈 수 있도록 도와주셔서 다시 한번 감사드립니다."
      },
      {
          "type": "female",
          "message": "사실 우연을 처음 가입하고 상담까지 정말 많은 고민을 했습니다. 내가 이렇게까지 해서 결혼을 해야 하나…?라는 생각과 함께 흔히 말하는 현타가 오더라고요.\n\n그렇지만 현재는 우연이 아니었다면 이렇게 좋은 사람을 만날 수 있었을까 하는 생각이 듭니다.\n\n마지막으로 제 매니저님이신 김사랑 매니저님께 정말 감사드립니다. 휴무, 밤낮없이 신경 써주시고 마음 써주신 덕분에 소중한 인연을 만날 수 있었습니다.\n\n좋은 인연 만나게 해주신 우연 감사드리고 대박 나세요!"
      }
      ],
      desc: "매니저의 세심한 상담과 매칭, 프사기 방지 시스템, 그리고 정확한 정보 확인 덕분에 첫 매칭에서 바로 교제를 시작할 수 있었습니다. 1시간 이상 상담을 통해 단순히 글로 적힌 저의 니즈를 파악하는 것이 아닌 글로 표현할 수 없는 부분을 확인하셨기 때문에 가능했다고 생각합니다.",
      id:5,
      image:banner5,
    },
    {
      header: "7월 1주차 커플",
      stars: 5,
      name: "7월 1주차\n커플 후기",
      conversation: [  
        {
          "type": "question",
            "message": "자기소개 부탁드립니다."
        },
        {
            "type": "male",
            "message": "서울사는 39살 사업을 하고 있습니다."
        },
        {
            "type": "female",
            "message": "서울사는 32살 직장인입니다."
        },
        {
            "type": "question",
            "message": "우연은 어떻게 알게 되셨나요?"
        },
        {
            "type": "male",
            "message": "김달님 유튜브를 통해 알게 됐습니다"
        },
        {
            "type": "female",
            "message": "저도 김달님 유튜브를 통해 알게 됐습니다"
        },
        {
            "type": "question",
            "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
        },
        {
            "type": "male",
            "message": "성실하고 배려심 있는 분을 만나 설레임과 안정감을 동시에 느낄수 있는 연애를 하고 싶었습니다."
        },
        {
            "type": "female",
            "message": "자상하고 배려심있는 분을 만나고 싶었어요."
        },
        {
            "type": "question",
            "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
        },
        {
            "type": "male",
            "message": "첫만남에 결이 비슷한단 느낌을 받았고 만나볼수록 더 좋은 사람이란걸 느껴서 만나게 되었습니다"
        },
        {
            "type": "female",
            "message": "매니저님의 적극 추천으로 만나뵈었는데, 말씀해주셨던 것보다 더 첫만남에 호감이었어서 사귀게 되었습니다."
        },
        {
            "type": "question",
            "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
        },
        {
            "type": "male",
            "message": "매니저님이 소통을 잘해주신것이 가장 좋았고 이런 이유로 좋은 인연을 만날수 있었던 것 같습니다."
        },
        {
            "type": "female",
            "message": "직접 상담해주시는 매니저님이 또래라는 점이 편하고, 말도 잘통했어요!"
        },
        {
            "type": "question",
            "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
        },
        {
            "type": "male_female",
            "message": "매니저님 감사합니다! 예쁘게 잘 만나보겠습니다 :)"
        }
      ],
      desc:  "매니저님이 소통을 잘해주신것이 가장 좋았고 이런 이유로 좋은 인연을 만날수 있었던 것 같습니다. 직접 상담해주시는 매니저님이 또래라는 점이 편하고, 말도 잘통했어요!",
      id:6,
      image:banner107,
    },
    {
      header: "7월 4주차 커플",
      stars: 5,
      name: "7월 4주차\n커플 후기",
      conversation: [  {
        "type": "question",
        "message": "자기소개 부탁드립니다."
    },
    {
        "type": "male",
        "message": "40대초반 직장인 유xx 입니다."
    },
    {
        "type": "female",
        "message": "30대후반 직장인 이xx 입니다."
    },
    {
        "type": "question",
        "message": "우연은 어떻게 알게 되셨나요?"
    },
    {
        "type": "male",
        "message": "김달님 유튜브에 대표님 나오는 방송을 우연히 보게 되었습니다."
    },
    {
        "type": "female",
        "message": "평소 즐겨보던 유튜버 김달님 영상보고 알게 됐습니다."
    },
    {
        "type": "question",
        "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
    },
    {
        "type": "male",
        "message": "대화가 잘 통하고 서로 공감을 잘 해주는 사람.\n\n차분하고 모나지 않은 착한 사람.\n\n하고싶은 일, 목표가 있는 사람."
    },
    {
        "type": "female",
        "message": "인성이 좋고 대화가 잘 통하고 가치관이 비슷한 분.\n\n자기 일에 책임감이 강하고 성실한 분."
    },
    {
        "type": "question",
        "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
    },
    {
        "type": "male",
        "message": "우연에서 첫번째로 소개 받은 분과 만나게 되었고 첫 대화부터 서로 성향이 잘 맞는다고 생각 해서 애프터를 신청했습니다.\n\n이후에는 자연스럽게 몇번의 만남을 가지며 더 좋아하는 감정이 생기게 되었습니다:)"
    },
    {
        "type": "female",
        "message": "우연 가입 후 처음으로 호감 메시지를 받아서 만남을 가지게 되었는데 첫 만남에서 좋은 분이라고 생각이 들었어요.\n\n마침 감사하게도 애프터 신청을 해주셨고 저 또한 호감이어서 지금까지도 좋은 만남 이어가는 중이에요~!"
    },
    {
        "type": "question",
        "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
    },
    {
        "type": "male",
        "message": "처음 상담 때 성향 파악을 잘 해주시고 그에 맞는 매칭을 잘 해 주셨습니다."
    },
    {
        "type": "female",
        "message": "합리적인 가격과 상담 매니저와 매칭 매니저가 같다는 점이 만족스러웠어요."
    },
    {
        "type": "question",
        "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
    },
    {
        "type": "male",
        "message": "상담부터 매칭까지 정말 신경 많이 써 주신다고 느껴졌습니다.\n\n가격도 합리적으로 느껴졌습니다.\n\n저 처럼 연애에 어려움을 겪으시는 분들은 가입하시면 후회 없을 거라 생각합니다:)"
    },
    {
        "type": "female",
        "message": "결정사에 대한 이미지가 우연을 통해 편하고 좋게 바뀌었어요.\n\n무엇보다 매칭이 잘 돼서 좋은 분을 만나게 된 것에 감사드립니다 :)"
    }],
    desc:  "처음 상담 때 성향 파악을 잘 해주시고 그에 맞는 매칭을 잘 해 주셨습니다.합리적인 가격과 상담 매니저와 매칭 매니저가 같다는 점이 만족스러웠어요.",
      id:7,
      image:banner108,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      conversation: [  {
        "type": "question",
        "message": "자기소개 부탁드립니다."
    },
    {
        "type": "male",
        "message": "안녕하세요 94년생 남성입니다"
    },
    {
        "type": "female",
        "message": "안녕하세요. 저는 94년생이고 게임업계에서 일하고 있습니다."
    },
    {
        "type": "question",
        "message": "우연은 어떻게 알게 되셨나요?"
    },
    {
        "type": "male",
        "message": "평소 남들보다 결혼에 관심이 많고 결혼하고 싶다는 생각으로 이전까지는 유튜브로 결혼정보회사를 둘러보다\n\n최근에 김달 유튜브에 우연 관련 홍보 영상에 지금까지\n\n와는 다른 서비스 마인드에 바로 납득이 가고 합리적이다 생각이 들어 바로 가입해 버렸습니다"
    },
    {
        "type": "female",
        "message": "달님을 구독한건 19년도 부터였고, 이전 연애에서 어려움을 느낄 때마다 달님의 조언과 시청자분들의 댓글보면서 많은 도움 받았습니다.\n\n고맙게도 지인들이 소개팅을 해주었으나 ‘내 취향에 맞는 사람을 만나기엔 한계가 있구나’를 느끼고 있을 때, 마침 달님의 결정사 오픈 영상을 보게 되었습니다.\n\n당시 이전 연애들에서 수동적이었던 제 자신을 탈피해 봐야겠다는 생각도 하고 있었고, 소개팅 풀을 넓힐 필요성을 느껴 바로 상담을 신청했습니다."
    },
    {
        "type": "question",
        "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
    },
    {
        "type": "male",
        "message": "진지하고 한평생 같이 살 사람을 구한다라 생각하여 집순이에 몸이 마르고 자기 관리를 하며 겸손하고 검소하며\n\n자기 일에 자부심이 있는 자신의 일이 성장이 가능한 부류입니다\n\n대화가 중요하며 문제에 대해 즉각적으로 피드백이 오가는 그런 사람입니다.\n\n연인도 하나의 인격체로서 존중해주며 개인 시간도 존중하고 서로가 성장하는 게 보이는 그런 사람이 좋습니다"
    },
    {
        "type": "female",
        "message": "기술이 있는 사람. 주변 사람에 휘둘리지 않는 독립적인 사람.\n\n가정을 꾸리는 것에 대해서 생각해 본 사람.\n\n술담배가 아닌 자기에게 맞는 방법으로 스트레스를 관리하는 사람.\n\n저도 이렇게 살고자 하기 때문에, 이런 가치관을 공유할 수 있는 사람을 원했습니다.\n\n그 외엔 대화나 개그코드가 잘 통하고 다정한 사람.\n\n마르지 않은 곰상이 좋다고 말씀드리긴 했지만 희망사항이었고, 외모보단 인성에 중점을 두고 나이가 몇살이든 철든 분 만나고 싶었어요."
    },
    {
        "type": "question",
        "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
    },
    {
        "type": "male",
        "message": "첫 만남에 낯을 많이 가리셔서 조금 당황했으나 이후 밥 먹을 때나 카톡으로 대화하니 호감이 가고 나랑 비슷한 사람이라 생각이 들어 귀엽게 보여서 이후 사귀었습니다"
    },
    {
        "type": "female",
        "message": "매칭 시작 후, 첫 호감을 받았는데 피지컬과 외모가 훈훈해서 일단 외형적으로 끌렸습니다.\n\n근데 그 부작용으로 소개팅 날 제가 너무 긴장해서 자꾸 허공을 봤고, 남친도 당시에 이 소개팅은 망했구나 생각했데요.\n\n지금 생각하면 참 아찔한데, 수동적인 모습을 극복해보고자 한 마당에 “호감이 있어서 눈을 잘 못보는거다”라고 그냥 솔직하게 얘기했어요.\n\n그렇게 오해도 풀고, 이후 대화 할수록 공통점도 많고, 자기일에 대해 전문성도 있고, 눈에 띄는 외형과 달리 내실도 튼튼한게 느껴졌고,\n\n이 사람 착실하게 살고 있구나라는 생각이 들어서 마음이 커지게 되었습니다."
    },
    {
        "type": "question",
        "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
    },
    {
        "type": "male",
        "message": "합리적이고 납득 가능한 상식적인 서비스 거짓이 섞이지 않은 날것으로 깐깐한 프로필 작성 특히 무신경한 남자들에게 이성에게 어필에 필요한 것들까지 챙겨주는 세심함이 좋습니다"
    },
    {
        "type": "female",
        "message": "일단 캐쥬얼한 결정사라는 느낌이 강했어서, 접근성이 좋았던 것 같아요.\n\n저도 결정사는 처음이라 무서웠지만, 적당한 값을 지불하고 소개팅의 퀄리티를 올려보자!하고 도전했던 거거든요.\n\n진짜 결혼을 해야만 성혼비를 지급하는 시스템도 매니저님께서 내 이상형을 찾아주실 수 밖에 없는 구조겠고, 제 취향에 맞는 이성을 만날 확률을 높히는데 도움이 될거라고 생각했습니다."
    },
    {
        "type": "question",
        "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
    },
    {
        "type": "male",
        "message": "제가 듣던 것과는 다르게 진정성 있는 소개로 결정사에 대한 편견을 없애 주셔서 감사드립니다.\n\n앞으로 더 많은 분들이 우연에서 좋은 결과 있기를 응원합니다"
    },
    {
        "type": "female",
        "message": "능력도 좋은데 티키타카도 잘 되고 외모까지 이상형인 곰돌이 남친을 만나게 되어 설레는 날을 보내고 있습니다.\n\n20대 초반 이후 이런 감정 오랜만이네요.\n\n장기연애 실패 이후 미소를 잃고 살았는데, 그래도 버티며 열심히 살았다고 하늘에서 선물받은 느낌이에요.\n\n미래가 막막하기만 했는데, 이젠 삶을 어떻게 꾸려나가면 좋을지 같이 대화할 사람이 생겼다는게 참 신기하고 감사할 따름입니다.\n\n귀중한 인연 소중하게 발전시켜 나가겠습니다."
    }],
    desc:  "합리적이고 납득 가능한 상식적인 서비스 거짓이 섞이지 않은 날것으로 깐깐한 프로필 작성 특히 무신경한 남자들에게 이성에게 어필에 필요한 것들까지 챙겨주는 세심함이 좋습니다.일단 캐쥬얼한 결정사라는 느낌이 강했어서, 접근성이 좋았던 것 같아요.\n\n저도 결정사는 처음이라 무서웠지만, 적당한 값을 지불하고 소개팅의 퀄리티를 올려보자!하고 도전했던 거거든요.\n\n진짜 결혼을 해야만 성혼비를 지급하는 시스템도 매니저님께서 내 이상형을 찾아주실 수 밖에 없는 구조겠고, 제 취향에 맞는 이성을 만날 확률을 높히는데 도움이 될거라고 생각했습니다.",
      id:8,
      image:banner8,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      conversation: [  {
        "type": "question",
        "message": "자기소개 부탁드립니다."
    },
    {
        "type": "male",
        "message": "안녕하세요 1990년생, 매장 관리자로 일하고 있습니다"
    },
    {
        "type": "female",
        "message": "안녕하세요. 31살이고 현재 법률사무원으로 근무하고 있습니다."
    },
    {
        "type": "question",
        "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
    },
    {
        "type": "male",
        "message": "욕심일 수 있지만 평생을 같이할 반려자를 찾고 있었습니다.\n\n살아온 날 보다 살아갈 날이 많기에 비슷한 생각을 갖고 있고 저를 이해해 줄 수 있는 여성을 만나고 싶었습니다"
    },
    {
        "type": "female",
        "message": "나이, 거리, 흡연 여부 등 몇 가지 조건들을 보긴 했었지만,\n\n결혼을 전제로 하기에 가장 중요하게 보았던 부분은 상대방의 가치관과 인성이었습니다.\n\n앞으로 어떻게 살아갈 것인지에 대한 자기 주관이 있고, 일상에서 오는 행복을 소중히 하는 분을 만나고 싶었습니다."
    },
    {
        "type": "question",
        "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
    },
    {
        "type": "male",
        "message": "다른 조건들은 둘째치고 선한 첫인상에 우선 만나보고 싶다는 생각이 가득했습니다.\n\n첫만남때 잘 보이고 싶은 마음에 평소보다 오버하기도 하고 긴장해서 어색하기도 했지만 시간이 빨리 지나가서 너무 아쉬웠습니다.\n\n헤어질때 용기내서 연락처를 물어보았고 이후 한번 더 용기내 애프터를 신청했습니다.\n\n평범한 일상관련 카톡으로도 서로 즐겁게 이야기하고 의외의 꼰대 같은모습에 오히려 마음이 쉽게 열렸습니다.\n\n밀고 당기기를 못해서 이거저거 재고 싶지 않았던 마음에 세번째 만남을 급히 추진했고 고백, 성공하여 연인으로 발전하였습니다."
    },
    {
        "type": "female",
        "message": "남자친구의 프로필을 받아 보았을 때 자기소개서를 읽어 내려가는데 '나와 결이 비슷한 것 같네,\n\n만나고 싶다'는 생각이 들었어요.\n\n첫 만남 당일, 긴장을 많이 했었는데 걱정했던 게 무색할 정도로 남자친구와 2시간 정도 재미있게 이야기했던 기억이 나네요.\n\n그날 남자친구가 고맙게도 애프터 신청을 해주었고, 저도 더 만나보고 싶어서 애프터 수락을 했습니다.\n\n두 번째 만남에서는 같이 볼링을 치면서 소원내기를 했는데 남자친구가 이기고 나서 말하기를, '말 편하게 놓자'고 하더라고요.\n\n저희는 그렇게 더 가까워졌습니다.\n\n일상대화를 하면서 서로에 대해 알아가고 '제 옆에 이 사람이 있었으면 좋겠다'는 생각이 들었을 때 즈음,\n\n진지하게 만나보는 게 어떻겠냐는 남자친구의 말에 정식으로 교제하기 시작했습니다."
    },
    {
        "type": "question",
        "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
    },
    {
        "type": "male",
        "message": "여러가지 조건과 상대방의 검증된 프로필을 볼 수 있어서 좋았고 다른 결혼정보회사보다 합리적인 가격으로 성혼에 목표를 두어 만남성사에 진심인 부분이 좋았습니다."
    },
    {
        "type": "female",
        "message": "담당 매니저님이 상담할 때 원하는 이상형을 파악하기 위해 정량적인 조건들에 대해 구체적으로 질문해 주세요.\n\n또한 확실히 또래 매니저님 한 분이 상담&매칭을 전담하다보니 머리부터 메이크업, 복장 등 여러 가지 부분에 대해 코칭을 해주셨는데 많은 도움이 되었습니다."
    },
    {
        "type": "question",
        "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
    },
    {
        "type": "male",
        "message": "일과 집, 단조롭고 무료한 일상에 이성을 만나는게 쉽지 않은 현실을 느끼는 분들에게 우연을 추천하고 싶습니다.\n\n그리고 오랜 시간동안 돌고돌아 결국 만나게 된 지금의 여자친구에게 고맙고 사랑한다는 말 전하고 싶습니다."
    },
    {
        "type": "female",
        "message": "사람을 만난다는 게 어렵다는 걸 한 해가 갈수록 느꼈고,\n\n더는 안 될 것 같아 결혼정보 회사를 찾아왔습니다.\n\n사실 가입하고도 진짜 인연을 만날 수 있을까 싶었지만,\n\n결과적으로 빠른 시간 안에 제가 원하는 이상형을 만나게 되었고 만족스럽습니다."
    }],
    desc:  "여러가지 조건과 상대방의 검증된 프로필을 볼 수 있어서 좋았고 다른 결혼정보회사보다 합리적인 가격으로 성혼에 목표를 두어 만남성사에 진심인 부분이 좋았습니다.담당 매니저님이 상담할 때 원하는 이상형을 파악하기 위해 정량적인 조건들에 대해 구체적으로 질문해 주세요.\n\n또한 확실히 또래 매니저님 한 분이 상담&매칭을 전담하다보니 머리부터 메이크업, 복장 등 여러 가지 부분에 대해 코칭을 해주셨는데 많은 도움이 되었습니다.",
      id:9,
      image:banner110,
    },
    {
      header: "8월 1주차 커플",
      stars: 5,
      name: "8월 1주차\n커플 후기",
      desc:  "다른 결정사는 자소서가 짧다고 들었는데 우연은 의무적으로(?!) 자소서를 정성들여 작성해야해서 그런지 구체적인 자소서 내용을 통해서 상대에 대해 어느 정도 알고 만날 수 있어서 만나서 대화할 때 덜 어색한 분위기가 형성될 수 있는 것 같아서 좋았습니다.아무래도 만나서 하기 어려운 주제를 처음부터 오픈한다는 게 좋았어요. 시간낭비를 안할 수 있고요. 그리고 애써주시는 매니저님과 평소에 의사소통이 잘되어서 그점이 가장 마음에 들었습니다!",
      conversation: [ {
        "type": "question",
        "message": "자기소개 부탁드립니다."
        },
        {
            "type": "male",
            "message": "저는 서울 구로구에 살고 있는 34세 AI 개발자입니다."
        },
        {
            "type": "female",
            "message": "안녕하세요~ 저는 관악구에 살고 있는 32세 간호사입니다."
        },
        {
            "type": "question",
            "message": "우연은 어떻게 알게 되셨나요?"
        },
        {
            "type": "male",
            "message": "김달님 유튜브를 통해서 알게 되었습니다."
        },
        {
            "type": "female",
            "message": "김달님 유튜브를 통해 알게되었어요~"
        },
        {
            "type": "question",
            "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
        },
        {
            "type": "male",
            "message": "지금 여자친구처럼 예쁘고 귀엽고 마음이 사려 깊고 상대를 아껴 주는 이성을 만나고 싶었습니다."
        },
        {
            "type": "female",
            "message": "말이 잘통하고 진중한 성격을 가진 분을 원했어요~ 자신의 일 열심히하고, 든든한 분이 이상형이었습니다!"
        },
        {
            "type": "question",
            "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
        },
        {
            "type": "male",
            "message": "첫 만남 이후에 두 번째 만남 전까지 연락을 자주 하고 두 번째 만남에서 저녁 식사를 하고 공원 산책하면서 서로의 마음을 확인했습니다."
        },
        {
            "type": "female",
            "message": "프로필이 여러개 왔었는데, 자소서를 많이 봤습니다. 글을 통해서 그사람의 내면을 살짝 볼 수 있다고 생각했고, 자소서에서 저와 비슷한결인것같다는 생각이 들었어요~ 만나보니 제가 생각했던 이상형과 너무닮아서 마음이 든다고 생각했습니다. 저에게는 상대방의 자소서가 큰 영향을 미친 케이스같습니다~"
        },
        {
            "type": "question",
            "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
        },
        {
            "type": "male",
            "message": "다른 결정사는 자소서가 짧다고 들었는데 우연은 의무적으로(?!) 자소서를 정성들여 작성해야해서 그런지 구체적인 자소서 내용을 통해서 상대에 대해 어느 정도 알고 만날 수 있어서 만나서 대화할 때 덜 어색한 분위기가 형성될 수 있는 것 같아서 좋았습니다."
        },
        {
            "type": "female",
            "message": "아무래도 만나서 하기 어려운 주제를 처음부터 오픈한다는 게 좋았어요. 시간낭비를 안할 수 있고요. 그리고 애써주시는 매니저님과 평소에 의사소통이 잘되어서 그점이 가장 마음에 들었습니다!"
        },
        {
            "type": "question",
            "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
        },
        {
            "type": "male",
            "message": "매니저님께서 이상적인 매칭이 이루어 질 수 있도록 적극적으로 임해주시는 모습이 정말 보기 좋았습니다. 첫 상담 때 말씀드린 이상형에 근접한 여성분을 최대한 맞춰주시려고 노력하시는 모습이 만족스러웠습니다. 두 번째 매칭만에 만나 좋은 인연을 이어가고 있는 만큼 많은 신뢰가 있고 다른 분들에게도 추천해 줘서 좋은 인연 만날 수 있게 해주고 싶습니다."
        },
        {
            "type": "female",
            "message": "매니저님께서 많이 애써주시고 도와주셔서 좋은 만남을 이어간것같습니다~ 결정사에 크게 관심이없었는데 친절하신 매니저님 만나서 좋은인연만나 잘 사귀고있어요~ 다른 결정사는 못가봤지만, 우연이 다른곳보다 훨씬 경제적인것 같고 프사기가 없어서 좋더라고요 ㅋㅋ"
            + "\n\n근데 이제 상대방도 그렇지만 서로 없다보니까 첫만남도 엄청 기대하고 만나진않고요(진짜 포토샵 너무없는거 아니에요?ㅜ) 실물이 더 나은거같아서 오히려 좋은거같기도하고요. 장점인데 단점같은.. 그런데 장점같은..그런너낌~~(결론은 좋습니다)"
            + "\n\n앞으로도 이쁜만남해서 결혼까지 갈게요~! 늘 마음써주신 매니저님 감사합니당ㅎㅎㅎ❤️"
        }],
      id:10,
      image:banner111,
    },
    {
        header: "8월 2주차 커플",
        stars: 5,
        name: "8월 2주차\n커플 후기",
        desc:  "담당매니저님이 매칭+상담까지 같이 해주시기 때문에 믿음이 갔습니다. 궁금한 사항 같은 게 있으면 담당 매니저님이 다 알고 있는 사항이기에 빠르게 고민 해결할 수 있었습니다. 그리고 파티에 가서 알게된 점으론 1.대표님이 프로필 사진에 진심인 점(프사기 절대 용납 불가)이 사진에 대한 신뢰가 갔습니다. 대부분의 사람들이 사진보다 실물이 훨씬 나으시더라구요! 2.파티에 신경을 정말 많이 써주셔서 infp인 저도 재밌게 잘 즐길 수 있어서 좋았습니다. 3.파티에서 만난 사람들이 자기 담당 매니저 칭찬하기 바쁠 정도로 매니저님들이 다 좋으십니다. 결정사를 통해 알게 된 매니저님이지만 어느순간 친한 지인이 소개팅을 해주는 착각이 들 정도로 세심히 신경써 주십니다.(이정은 매니저님 최고!)",
        id:11,
        image:BackImg0818,
        conversation: [ 
            {
                "type": "question",
                "message": "자기소개 부탁드립니다."
            },
            {
                "type": "male",
                "message": "저는 남양주에서 가업승계로 식품공장을 운영하고 있는 33살 남자입니다."
            },
            {
                "type": "female",
                "message": "안녕하세요 강서구에 살고있는 28살 회사원입니다"
            },
            {
                "type": "question",
                "message": "우연은 어떻게 알게 되셨나요?"
            },
            {
                "type": "male",
                "message": "결정사에 편견을 가지고 있던 와중에 유튜브 알고리즘에 김달님 영상을 보게 되었는데 다른 결정사와는 다른 운영방식인 거 같아서 관심을 가지게 되었고 상담만 해보자는 생각이었는데 상담했을 때 진정성이 느껴져서 가입까지 하게 되었습니다."
            },
            {
                "type": "female",
                "message": "김달님 유튜브로 알게됐습니다"
            },
            {
                "type": "question",
                "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
            },
            {
                "type": "male",
                "message": "자기 인생을 열심히 살고 귀엽고 이쁜 사람, 대화가 잘 통하는 사람 만나고 싶었습니다"
            },
            {
                "type": "female",
                "message": "처음에는 저랑 비슷한 직업군이나 배경을 가진분, 대화가 잘 통하고 남자다운 모습이 있는 분을 만나기를 생각했었습니다. 매니저님과 여러 피드백을 거치는 과정에서 어떤 부분을 중요하게 보는지 점차 명확해지면서, 막연하게 고민했던 부분에 대한 편견이 사라졌고, 제가 찾던 분을 만날 수 있게 된 것 같습니다."
            },
            {
                "type": "question",
                "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
            },
            {
                "type": "male",
                "message": "우선 제가 일이 바쁜 편이라 거리를 1순위로 두고 있었습니다. 바쁜 제 상황을 상대는 이해 못 할 거라 생각해서 거리가 멀면 연애를 할 수 없을 거라 판단했었습니다. 그래서 매칭 카드가 와도 거리만 보고 안된다고 지레짐작하던 와중에 매니저님이 와인파티를 초대해 주셨고 거기서 옆에 앉았던 분과 잠시 대화를 나눴고 더 알고 싶어져서 번호를 물어봤습니다. 다시 만나보니 외모도 이상형이고 사람이 너무 괜찮아서 1순위인 거리를 접어두고 만나게 되었습니다. 매니저님이 상담 내용을 통해 거리에 대한 편견을 낮춰주셔서 좋은 인연 만나는데 큰 도움이 되었습니다."
            },
            {
                "type": "female",
                "message": "김달님께서 오셨던 와인파티에 매니저님이 참석하길 추천해주셔서 나가게 되었고 옆자리에 같이 앉아 대화를 하며 친해졌습니다"
            },
            {
                "type": "question",
                "message": "우연에 가장 만족하신 건 어떤 점이셨나요?"
            },
            {
                "type": "male",
                "message": "담당매니저님이 매칭+상담까지 같이 해주시기 때문에 믿음이 갔습니다. 궁금한 사항 같은 게 있으면 담당 매니저님이 다 알고 있는 사항이기에 빠르게 고민 해결할 수 있었습니다. 그리고 파티에 가서 알게된 점으론 1.대표님이 프로필 사진에 진심인 점(프사기 절대 용납 불가)이 사진에 대한 신뢰가 갔습니다. 대부분의 사람들이 사진보다 실물이 훨씬 나으시더라구요! 2.파티에 신경을 정말 많이 써주셔서 infp인 저도 재밌게 잘 즐길 수 있어서 좋았습니다. 3.파티에서 만난 사람들이 자기 담당 매니저 칭찬하기 바쁠 정도로 매니저님들이 다 좋으십니다. 결정사를 통해 알게 된 매니저님이지만 어느순간 친한 지인이 소개팅을 해주는 착각이 들 정도로 세심히 신경써 주십니다.(이정은 매니저님 최고!)"
            },
            {
                "type": "female",
                "message": "가격이 부담되지 않았던 점이 좋았고, 매니저님께서 꼼꼼하게 물어봐주시고 피드백해주셔서 도움을 많이 받았어요 감사합니다ㅎㅎ"
            },
            {
                "type": "question",
                "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다"
            },
            {
                "type": "male",
                "message": "'결혼'이라는 게 인생에서 가장 중요한 일 중에 하나고 그렇기 때문에 결정사 가입을 결정하는 게 부담이 됐었습니다. 주변 사람들도 안 좋은 인식이 많았기에 고민이 컸던 것도 사실입니다. 그래도 해보고 후회하는 게 낫다는 생각으로 가입을 결정했고 그 결과 가입한 게 신의 한 수였다고 생각합니다. 주변에 연애, 결혼을 고민하는 사람이 있다면 적극 추천하고 싶습니다. 좋은 사람을 만날 수 있는 소중한 기회를 주신 우연 측에게 머리 숙여 감사드립니다ㅎㅎ"
            },
            {
                "type": "female",
                "message": "흔히 결혼정보회사는 조건에 맞춰서 의미없는 매칭을 시켜준다는 편견이 많더라구요, 우연은 제 담당 매니저님께서 저의 의견을 꼼꼼하게 신경써주시는게 느껴질 정도로 매니저님과의 피드백을 통해서 내가 원하는 사람과 미래에 대해, 더 나아가 스스로를 돌이켜보는 기회가 되었고, 배우자를 찾는 과정을 함께 해주시는 기분이었습니다. 우연이 아니었으면 만날 수 있었을까 하는 좋은 인연을 만나게되어 감사합니다."
            }
        ]
      },
      {
        header: "9월 1주차 커플",
        stars: 5,
        name: "9월 1주차\n커플 후기",
        desc:  "상담과 매칭을 동일한 매니저분이 진행해주셔서, 상담에서 말했던 희망하시는 분을 만날 수 있던 점입니다. 자기소개를 자세하게 쓴다는 점이 좋았습니다. 아무래도 만나기 전에 상대분이 어떤 성향인지 알기가 어려운데 자기소개를 통해 초반에 적은 만남으로도 상대 분을 이해할 수 있었던 것 같아요.",
        id:12,
        image:BackImg0909,
        conversation: [ 
            {
                "type": "question",
                "message": "자기소개 부탁드립니다."
            },
            {
                "type": "male",
                "message": "안녕하세요. 경기도에서 회사에 다니는 93년생 직장인입니다."
            },
            {
                "type": "female",
                "message": "안녕하세요. 경기도에서 일하고 있는 97년생 직장인입니다."
            },
            {
                "type": "question",
                "message": "우연은 어떻게 알게 되셨나요?"
            },
            {
                "type": "male",
                "message": "알 수 없는 알고리즘으로 김달 유튜브에 유입되어 알게 되었습니다."
            },
            {
                "type": "female",
                "message": "김달님 유튜브를 구독하다가 결정사를 만드셨다는 영상에 관심이 생겨서 알아보게 되었습니다."
            },
            {
                "type": "question",
                "message": "우연에서 만나고 싶은 이성은 어떤 분이셨나요?"
            },
            {
                "type": "male",
                "message": "저와 코드와 대화가 잘 맞는 분이면서, 자신이 하는 일을 열심히 하는 분을 만나고 싶었습니다."
            },
            {
                "type": "female",
                "message": "함께 있을 때 즐겁고 행복해지는 분을 만나고 싶었습니다. 서로의 소소한 일상을 나누고, 상대방을 진심으로 이해해주고 서로 배려하는 다정한 분이 이상형이었어요."
            },
            {
                "type": "question",
                "message": "어떻게 만나셔서 연인으로 발전하셨는지 궁금합니다."
            },
            {
                "type": "male",
                "message": "인연이 되는 사람은 결국 이어지는 것 같습니다.\n여자친구를 처음 만났을 때, 저와 너무 잘 맞는 분이라서 계속 만나고 싶었는데, 다음 주에 해외여행을 간다고 했습니다. 저희 모두 아쉬워했는데, 여자친구가 당일 바로 본가에 내려가는 기차 시간도 늦추면서 두 번째 만남의 시간을 가지게 되었습니다.\n저는 이 모습을 보고 한 눈에 반해버려 귀국한 이후 만나 연인으로 발전하게 되었습니다^^"
            },
            {
                "type": "female",
                "message": "처음 프로필을 받았을 때 자기소개글을 읽고 저와 결이 비슷하고 취향이 겹치는 부분이 있어 만나게 되었습니다. 이후 두 번째 만남까지도 대화가 편하게 잘 오가는 것을 보며 점점 더 호감이 생겼는데 제가 장거리 해외여행을 가게 되어 다음 만남까지의 텀이 길어졌습니다. 그동안 꾸준히 연락을 하고 일상을 공유하다 귀국 후에 만났을 때도 공백이 느껴지지 않을 만큼 자연스러워서 연애를 시작하게 되었습니다."
            },
            {
                "type": "question",
                "message": "우연에 가장 만족하는 건 어떤 점이셨나요?"
            },
            {
                "type": "male",
                "message": "상담과 매칭을 동일한 매니저분이 진행해주셔서, 상담에서 말했던 희망하시는 분을 만날 수 있던 점입니다."
            },
            {
                "type": "female",
                "message": "자기소개를 자세하게 쓴다는 점이 좋았습니다. 아무래도 만나기 전에 상대분이 어떤 성향인지 알기가 어려운데 자기소개를 통해 초반에 적은 만남으로도 상대 분을 이해할 수 있었던 것 같아요."
            },
            {
                "type": "question",
                "message": "마지막으로 하고 싶은 말씀 자유롭게 부탁드립니다."
            },
            {
                "type": "male",
                "message": "다른 분들도 우연에서 좋은 인연 만들어 가시길 바랍니다. 아, 그리고 이렇게 좋은 분을 만나게 해주신 매니저님께 감사 인사를 드려요~"
            },
            {
                "type": "female",
                "message": "매니저님이 제가 원하는 성향의 분을 잘 찾아주셔서 덕분에 좋은 인연을 만날 수 있었던 것 같아요. 결정사라고 부담 가지실 필요 없이 소개팅 같은 만남을 원하시는 분들께 우연을 추천드립니다!"
            }
        ],
    }
  ]);

  useEffect(() => {
    

    const updateImage = () => {
      if (id) {
        let idNumber = parseInt(id);
        
        console.log("data",dummy1);
        console.log("data",dummy1[idNumber]);
        for(let i = 0; i < dummy1.length; i++){
          if(dummy1[i].id === idNumber){
            setImage(dummy1[i].image);
            setData(dummy1[i].conversation);    
          }
        }
        
        console.log("data",data);

      }
    };

    updateImage();

    const handleResize = () => {
      updateImage();
    };

    window.addEventListener("resize", handleResize);

    document.title = "커플 후기 | 우연 소개";

    return () => window.removeEventListener("resize", handleResize);
  }, [id]);

  return (
    <React.Fragment>
      <img class="couple-detail-banner" src={image} style={id == 8 || id == 2 || id == 4? {objectPosition :"center 30%"}:
        id == 7 || id == 3 ? {objectPosition :"center 40%"}:
      null
      } />		
      <div className="couple-detail-background">
        <div class="couple-detail-title-wrapper">
          <div class="couple-detail-title">커플 후기 인터뷰</div>
          <div class="couple-detail-sub_title">우연에서 만난 커플들의 솔직한 후기입니다.</div>
        </div>
        <div class="couple-detail-conversation">
        
        {data?.map((item, i) => {
          if(item.type === "question") {
            return (
              <div class="couple-detail-question">Q. {item.message}</div>
            )
          } else if(item.type === "male"){
            return (
              <div class="couple-detail-answer male">
                <img src={man} alt="Male Emoji" />
                <div class="couple-detail-answer-text">{item.message}</div>
              </div>
            )
          } else if(item.type === "female"){
            return (
              <div class="couple-detail-answer female">
                <img src={woman} alt="Male Emoji" />
                <div class="couple-detail-answer-text">{item.message}</div>
              </div>
            )
          }
        })}
        </div>
      </div>
    </React.Fragment>
  );
};

ReviewDetail.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ReviewDetail);
