import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import Send from "./send.png";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";

function NomalPost({ setCallModal, postLike, type }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callapi = () => {
    setCallModal(false);
    postLike("", "normal");
  };

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "20px 20px 45px 20px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-end primiumMainText"
                  style={{ marginBottom: "-10px" }}
                >
                  <button
                    onClick={() => setCallModal(false)}
                    className="noneBtn"
                  >
                    <Xmark
                      width={20}
                      height={20}
                      onClick={() => setCallModal(false)}
                    />
                  </button>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center primiumMainText"
                >
                  <img
                    src={Send}
                    alt="sendicon"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  호감으로 전송할까요?
                  {/* 일반 호감으로 전송할까요? */}
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                >
                  확인을 누르시면 SMS로 매칭 알림이 전송됩니다.
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callapi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default NomalPost;
