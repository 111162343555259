import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
//i18n
import { withTranslation } from "react-i18next";
import "../../assets/vendor/hs-mega-menu/src/css/hs-mega-menu.css";
import HSMegaMenu from "../../assets/vendor/hs-mega-menu/src/js/hs-mega-menu";
import { ReactComponent as Down } from "../../assets/icon/svgs/solid/chevron-down.svg";
import "./header.scss";

const Navbar = (props) => {
  const history = useHistory();
  const megaMenuRef = useRef(null);

  useEffect(() => {
    // DOM 요소가 준비된 후 HSMegaMenu 초기화
    if (megaMenuRef.current) {
      new HSMegaMenu(megaMenuRef.current, {
        desktop: {
          position: "left",
        },
        // HSMegaMenu에 필요한 추가 옵션
      });
    }
  }, []); // 빈 배열을 넘겨 컴포넌트 마운트 시 1회만 실행되도록 함
  return (
    <React.Fragment>
      <div className="container">
        <nav ref={megaMenuRef} className="js-mega-menu navbar-nav-wrap">
          <div
            className="collapse navbar-collapse"
            id="navbarNavDropdown"
            style={{ order: "0" }}
          >
            <ul className="navbar-nav d-flex justify-content-center">
              <div
                className="d-flex justify-content-center"
                style={{ width: "200px" }}
              >
                <li className="hs-has-sub-menu nav-item">
                  <a
                    id="companyMegaMenu"
                    className={
                      window.location.pathname === "/introduce"
                        ? "hs-mega-menu-invoker nav-link headerTextActive  3"
                        : "hs-mega-menu-invoker nav-link headerText  3"
                    }
                    href="/introduce"
                    role="button"
                    aria-expanded="false"
                  >
                    우연은?
                  </a>
                  <div
                    class="hs-sub-menu"
                    aria-labelledby="companyMegaMenu"
                    style={{ minWidth: "14rem" }}
                  ></div>
                </li>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "200px" }}
              >
                <li class="hs-has-sub-menu nav-item">
                  <a
                    id="companyMegaMenu"
                    className={
                      window.location.pathname === "/problem"
                        ? "hs-mega-menu-invoker nav-link headerTextActive"
                        : "hs-mega-menu-invoker nav-link headerText"
                    }
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    서비스소개{" "}
                    <Down
                      width={11}
                      className={
                        window.location.pathname === "/problem"
                          ? "downSvgActive"
                          : "downSvg"
                      }
                    />
                  </a>
                  <div
                    class="hs-sub-menu dropdown-menu"
                    aria-labelledby="companyMegaMenu"
                    style={{ minWidth: "14rem" }}
                  >
                    <a class="dropdown-item" href="/problem">
                      기존의 문제점
                    </a>
                    <a class="dropdown-item" href="/problem">
                      왜 우연인가?
                    </a>
                    <a class="dropdown-item" href="/problem">
                      왜 우연인가?
                    </a>
                  </div>
                </li>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "200px" }}
              >
                <li class="hs-has-sub-menu nav-item">
                  <a
                    id="reviewMegaMenu"
                    className={
                      window.location.pathname === "/photoreview"
                        ? "hs-mega-menu-invoker nav-link headerTextActive"
                        : "hs-mega-menu-invoker nav-link headerText"
                    }
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    후기{" "}
                    <Down
                      width={11}
                      className={
                        window.location.pathname === "/photoreview"
                          ? "downSvgActive"
                          : "downSvg"
                      }
                    />
                  </a>
                  <div
                    class="hs-sub-menu dropdown-menu"
                    aria-labelledby="companyMegaMenu"
                    style={{ minWidth: "14rem" }}
                  >
                    <a class="dropdown-item" href="/photoreview">
                      커플후기
                    </a>
                    <a class="dropdown-item" href="/party/review">
                      파티후기
                    </a>
                  </div>
                </li>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "200px" }}
              >
                <li className="hs-has-sub-menu nav-item">
                  <a
                    id="accountMegaMenu"
                    className={
                      window.location.pathname === "/consult/request"
                        ? "hs-mega-menu-invoker nav-link headerTextActive"
                        : "hs-mega-menu-invoker nav-link headerText"
                    }
                    href="/consult/request"
                    role="button"
                    aria-expanded="false"
                  >
                    상담 신청
                  </a>
                  <div
                    class="hs-sub-menu"
                    aria-labelledby="companyMegaMenu"
                    style={{ minWidth: "14rem" }}
                  ></div>
                </li>
              </div>
              {/* <li className="hs-has-sub-menu nav-item">
                  <a
                    id="pagesMegaMenu"
                    className="hs-mega-menu-invoker nav-link"
                    href="/consult/request"
                    role="button"
                    aria-expanded="false"
                  >
                    상담 신청
                  </a>
                </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Navbar));
