import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

function PremiumPost({ setCallModal, postLike, message }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callapi = () => {
    setCallModal(false);
    postLike(message, "premium");
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "36px 24px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  호감을 전송할까요?
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                >
                  확인을 누르시면 SMS로 매칭 알림이 전송됩니다.
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 4px 0px 0px" }}>
                  <button
                    id="primiumRejectBtn"
                    onClick={() => setCallModal(false)}
                  >
                    취소
                  </button>
                </Col>
                <Col className="m-0" style={{ padding: "0px 0px 0px 4px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callapi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PremiumPost;
