import React, { useEffect, useState, useRef, useCallback } from "react";

import { useHistory, Link } from "react-router-dom";

import { adminRequestPost, requestGet } from "../../apis/request";
import { Col, Label, Row } from "reactstrap";

import "./consult.scss";
import { ReactComponent as Down } from "../../assets/icon/svgs/solid/chevron-down.svg";

import ReactSelect from "react-select";
import PrivacyDetail from "./PrivacyDetail";
import MarketingDetail from "./MarketingDetail";
import moment from "moment";
import BusyDateModal from "./BusyDateModal";
import { isMobile } from "react-device-detect";

function ConsultRequest(props) {
  const [educationList, setEducationList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [marketingModal, setMarketingModal] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [disableHours1, setDisableHours1] = useState([]);
  const [disableHours2, setDisableHours2] = useState([]);
  const [busyDate, setBusyDate] = useState([
    "2024-04-20",
    "2024-04-21",
    "2024-04-27",
    "2024-04-28",
    "2024-05-01",
    "2024-05-02",
    "2024-05-03",
    "2024-05-04",
    "2024-05-05",
    "2024-05-06",
  ]);
  const [busyDateModal, setBusyDateModal] = useState(null);
  const [values, setValues] = useState({
    firstDateTime: "",
    secondDateTime: "",
    // ideal_appearance: "0",
    // ideal_character: "0",
    // ideal_ability: "0",
  });
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [isMo, setIsMo] = useState(false);
  const [recommendcodeData, setRecommendcodeData] = useState(null);

  const history = useHistory();

  const callCodeAPI = async () => {
    const response = await requestGet(`/api/recommendcode/list`);
    setRecommendcodeData(response.data);
  };

  useEffect(() => {
    callCodeAPI();
  }, []);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangerecommand = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name + "etc"]: e.target.value,
    });
  };

  const callBusyDate = async () => {
    const response = await requestGet(`/api/counseling/date/busy`);
    setBusyDate(response?.data?.dates);
  };

  const handleDateChange = (e) => {
    if (values.counselor_gender) {
      if (e.target.value) {
        var selectedDate = new Date(e.target.value);
        var year = selectedDate.getFullYear();
        var month = selectedDate.getMonth() + 1; // 월은 0부터 시작하므로 +1
        var day = selectedDate.getDate();
        var dayOfWeek = selectedDate.getDay();

        // 2024년 4월 10일 및 2024년 5월 1일 체크
        if (busyDate.includes(e.target.value)) {
          let arr = [];
          busyDate.forEach((date, i) => {
            if (i === 0) {
              arr.push(
                `${moment(date).format("MM월")}-${moment(date).format("DD일")}`
              );
            } else if (
              moment(busyDate[i - 1]).format("MM") === moment(date).format("MM")
            ) {
              arr.push(moment(date).format("DD일"));
            } else {
              arr.push(
                `\n${moment(date).format("MM월")}-${moment(date).format(
                  "DD일"
                )}`
              );
            }
          });
          setBusyDateModal(arr);
          e.target.value = "";
          return;
        }
        // if (
        //   (year === 2024 && month === 4 && day === 10) ||
        //   (year === 2024 && month === 5 && day === 1)
        // ) {
        //   alert(
        //     "4월 13일, 14일, 20일, 21일, 27일, 28일, 5월 1일은 신청 마감되었습니다. 다른 날짜를 선택해주세요"
        //   );
        //   e.target.value = "";
        //   return;
        // }

        // // 2024년 4월의 모든 주말 체크 (토요일: 6, 일요일: 0)
        // if (
        //   year === 2024 &&
        //   month === 4 &&
        //   (dayOfWeek === 0 || dayOfWeek === 6)
        // ) {
        //   alert(
        //     "4월 13일, 14일, 20일, 21일, 27일, 28일, 5월 1일은 신청 마감되었습니다. 다른 날짜를 선택해주세요"
        //   );
        //   e.target.value = "";
        //   return false;
        // }
      }
      setShowPlaceholder(false);
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        firstDateTime: "",
      });
    } else {
      alert("희망 상담사 성별을 선택해 주세요.");
      setShowPlaceholder(true);
      setValues({
        ...values,
        [e.target.name]: "",
      });
    }
  };
  const handleDateChange2 = (e) => {
    if (values.counselor_gender) {
      if (e.target.value) {
        var selectedDate = new Date(e.target.value);
        var year = selectedDate.getFullYear();
        var month = selectedDate.getMonth() + 1; // 월은 0부터 시작하므로 +1
        var day = selectedDate.getDate();
        var dayOfWeek = selectedDate.getDay();
        if (busyDate.includes(e.target.value)) {
          let arr = [];
          busyDate.forEach((date, i) => {
            if (i === 0) {
              arr.push(
                `${moment(date).format("MM월")}\n${moment(date).format("DD일")}`
              );
            } else if (
              moment(busyDate[i - 1]).format("MM") === moment(date).format("MM")
            ) {
              arr.push(moment(date).format("DD일"));
            } else {
              arr.push(
                `\n\n${moment(date).format("MM월")}\n${moment(date).format(
                  "DD일"
                )}`
              );
            }
          });
          setBusyDateModal(arr);
          e.target.value = "";
          return;
        }
        // // 2024년 4월 10일 및 2024년 5월 1일 체크
        // if (
        //   (year === 2024 && month === 4 && day === 10) ||
        //   (year === 2024 && month === 5 && day === 1)
        // ) {
        //   alert(
        //     "4월 13일, 14일, 20일, 21일, 27일, 28일, 5월 1일은 신청 마감되었습니다. 다른 날짜를 선택해주세요"
        //   );
        //   e.target.value = "";
        //   return;
        // }

        // // 2024년 4월의 모든 주말 체크 (토요일: 6, 일요일: 0)
        // if (
        //   year === 2024 &&
        //   month === 4 &&
        //   (dayOfWeek === 0 || dayOfWeek === 6)
        // ) {
        //   alert(
        //     "4월 13일, 14일, 20일, 21일, 27일, 28일, 5월 1일은 신청 마감되었습니다. 다른 날짜를 선택해주세요"
        //   );
        //   e.target.value = "";
        //   return false;
        // }
      }
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        secondDateTime: "",
      });
    } else {
      alert("희망 상담사 성별을 선택해 주세요.");
      setValues({
        ...values,
        [e.target.name]: "",
      });
    }
  };

  const handleIdealChange = (e) => {
    const targetName = e.target.name;
    const ability = Number(values.ideal_ability ? values.ideal_ability : 0);
    const appearance = Number(
      values.ideal_appearance ? values.ideal_appearance : 0
    );
    const family = Number(values.ideal_character ? values.ideal_character : 0);
    const numval = Number(e.target.value);
    const totalNum =
      targetName === "ideal_ability"
        ? numval + appearance + family
        : targetName === "ideal_appearance"
        ? numval + ability + family
        : targetName === "ideal_character"
        ? numval + appearance + ability
        : 0;
    if (totalNum <= 10) {
      setValues({
        ...values,
        [targetName]: e.target.value,
      });
    } else {
      setValues({
        ...values,
        [targetName]: values[targetName],
      });
      alert("총 합 10 이상은 불가능 합니다.");
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    console.log(checked);
    setValues((prevValues) => {
      if (checked) {
        return {
          ...prevValues,
          [name]: true,
        };
      } else {
        return {
          ...prevValues,
          [name]: false,
        };
      }
    });
  };

  const locationRef = useRef();
  const birth_yearRef = useRef();
  const counselor_genderRef = useRef();
  const educationRef = useRef();
  const firstDateRef = useRef();
  const firstDateTimeRef = useRef();
  const genderRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const secondDateRef = useRef();
  const secondDateTimeRef = useRef();
  const privacy_agreeRef = useRef();
  const submitButtonRef = useRef();
  const kakaoTalkIdRef = useRef();

  const callInfo = async () => {
    let eduarr = [];
    let locarr = [];
    let yeararr = [];
    const educationResponse = await requestGet(`/api/education/list`);
    const locationResponse = await requestGet(`/api/location/list`);
    const yearResponse = await requestGet(`/api/list/year`);
    educationResponse?.data?.forEach((item) =>
      eduarr.push({ label: item.name, value: item.id })
    );
    locationResponse?.data?.forEach((item) =>
      locarr.push({ label: item.name, value: item.id })
    );
    yearResponse?.forEach((item) => yeararr.push({ label: item, value: item }));
    setEducationList(eduarr);
    setLocationList(locarr);
    setYearList(yeararr);
    if(window.kakaoPixel){
      await window.kakaoPixel('2665275622713308583').pageView();
    }
  };

  useEffect(() => {
    callBusyDate();
    callInfo();
    document.title = "상담신청 | 우리의 인연";
    
  }, []);

  const submitApi = async (e) => {
    e.preventDefault();
    console.log(values);
    if(submitButtonRef && submitButtonRef.current){
      submitButtonRef.current.disabled = true;
    }
    
    if (!values.location) {
      alert("지사를 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      } 
      
      locationRef.current.focus();
      return false;
    }
    if (!values.counselor_gender) {
      alert("희망상담사 성별을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      counselor_genderRef.current.focus();
      return false;
    }
    if (!values.firstDate) {
      alert("1순위 상담 일정을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      firstDateRef.current.focus();
      return false;
    }
    if (!values.firstDateTime) {
      alert("1순위 상담 일정 시간을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      firstDateTimeRef.current.focus();
      return false;
    }

    if (!values.secondDate) {
      alert("2순위 상담 일정을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      secondDateRef.current.focus();
      return false;
    }
    if (!values.secondDateTime) {
      alert("2순위 상담 일정 시간을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      secondDateTimeRef.current.focus();
      return false;
    }
    if (!values.name) {
      alert("성함을 입력해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      nameRef.current.focus();
      return false;
    }
    if (!values.phone) {
      alert("연락처를 입력해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      phoneRef.current.focus();
      return false;
    }
    if(!values.kakaoTalkId){
      alert("카카오톡 ID를 입력해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      kakaoTalkIdRef.current.focus();
      return false;
    }
    if (!values.birth_year) {
      alert("출생년도를 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      birth_yearRef.current.focus();
      return false;
    }
    if (!values.gender) {
      alert("성별을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      genderRef.current.focus();
      return false;
    }
    if (!values.education) {
      alert("최종학력을 선택해주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      educationRef.current.focus();
      return false;
    }

    // if (
    //   Number(values.ideal_appearance) +
    //     Number(values.ideal_ability) +
    //     Number(values.ideal_character) ===
    //   10
    // ) {
    // } else {
    //   alert("총점 10점이 될 수 있게 이성 선호도 항목별로 중요도 분배해주세요");
    //   return;
    // }
    if (!values.privacy_agree) {
      alert("개인정보 수집 및 이용에 동의하여야 신청할 수 있습니다.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      privacy_agreeRef.current.focus();
      return false;
    }
    try {
      var _nasa = {};
      if (
        values.birth_year &&
        values.counselor_gender &&
        values.education &&
        values.firstDate &&
        values.gender &&
        // values.ideal_ability &&
        // values.ideal_appearance &&
        // values.ideal_character &&
        values.location &&
        values.name &&
        values.phone &&
        values.kakaoTalkId &&
        // values.recommender &&
        values.secondDate
      ) {
        let body = {
          location: Number(values.location),
          counselingDates: [
            `${values.firstDate} ${values.firstDateTime}`,
            `${values.secondDate} ${values.secondDateTime}`,
          ],
          name: values.name,
          gender: values.gender,
          phone: values.phone,
          kakaoTalkId: values.kakaoTalkId,
          recommender: values.recommender,
          education: Number(values.education),
          // ideal_appearance: Number(values.ideal_appearance),
          // ideal_ability: Number(values.ideal_ability),
          // ideal_character: Number(values.ideal_character),
          ideal_appearance: 9,
          ideal_ability: 0,
          ideal_character: 1,
          counselor_gender: values.counselor_gender,
          birth_year: Number(values.birth_year),
          marketing_agree: values.marketing_agree ? true : false,
          privacy_agree: values.privacy_agree ? true : false,
        };
        await adminRequestPost(`/api/counseling/request`, body);
        if (window.wcs) {
          var _conv = {}; // 전환이벤트 정보 담을 object 생성
          _conv.type = "lead";  // 전환이벤트의 종류 설정
          await window.wcs.trans(_conv); // 전환이벤트 정보를 담은 object를 서버에 전송 
          console.log("window.wcs called");
        } else {
          console.log("window.wcs none");
        }
        if(window.fbq){
          await window.fbq("track", "CompleteRegistration");
          console.log("window.fbq  called");
        } else {
          console.log("window.fbq none");
        }
        
        if(window.kakaoPixel){
          await window.kakaoPixel('2665275622713308583').participation('Consulting');
          console.log("window.kakaoPixel called");
        } else {
          console.log("window.kakaoPixel none");
        }
        try {
          window.CallMtm = window.CallMtm||function(){(window.CallMtm.q=window.CallMtm.q||[]).push(arguments)};
          await window.CallMtm({
            productName :'상담신청 완료', //광고주 측에서 설정하고 싶은 값 (default convType)
            convType : 'etc' // 필수 etc, join, login
          });  
        } catch (error) {
          
        }
        try{
          await window.dataLayer.push("event","submit_fin_banner");
          console.log("window.dataLayer.push called");
        } catch (error) {
          console.log("window.dataLayer.push error");
          console.log(error);
        }
        
        // alert("신청해 주신 상담 일자는 아직 확정된 상담 일자가 아닙니다.\n\n예약부서에서 순차적으로 연락드린 후 확정됩니다.\n\n잠시만 기다려주시면 감사하겠습니다.");
        if(submitButtonRef && submitButtonRef.current){
          submitButtonRef.current.disabled = false;
        }
        history.push("/form_submit_finish");
        // window.location.replace('/dashboard');
        return false;
      } else {
        alert("입력 항목을 확인해 주세요.");
        if(submitButtonRef && submitButtonRef.current){
          submitButtonRef.current.disabled = false;
        }
        return false;
      }
    } catch (error) {
      alert("다시 시도해 주세요.");
      if(submitButtonRef && submitButtonRef.current){
        submitButtonRef.current.disabled = false;
      }
      return false;
    }
  };

  const callDateApi1 = useCallback(async () => {
    if (values.firstDate && values.counselor_gender) {
      try {
        const params = new URLSearchParams();
        params.append("date", moment(values.firstDate).format("YYYY-MM-DD"));
        if (values.counselor_gender === "남자") {
          params.append("gender", "남성");
        } else if (values.counselor_gender === "여자") {
          params.append("gender", "여성");
        }
        const response = await requestGet(
          `/api/counseling/date/info/bygender?${params}`
        );
        setDisableHours1(response?.data?.times);
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.firstDate, values.counselor_gender]);

  useEffect(() => {
    callDateApi1();
  }, [callDateApi1, values.firstDate, values.counselor_gender]);

  const callDateApi2 = useCallback(async () => {
    if (values.secondDate && values.counselor_gender) {
      try {
        const params = new URLSearchParams();
        params.append("date", moment(values.secondDate).format("YYYY-MM-DD"));
        if (values.counselor_gender === "남자") {
          params.append("gender", "남성");
        } else if (values.counselor_gender === "여자") {
          params.append("gender", "여성");
        }
        const response = await requestGet(
          `/api/counseling/date/info/bygender?${params}`
        );
        setDisableHours2(response?.data?.times);
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.secondDate, values.counselor_gender]);

  useEffect(() => {
    callDateApi2();
  }, [callDateApi2, values.secondDate, values.counselor_gender]);

  useEffect(() => {
    setValues({
      ...values,
      firstDate: "",
      firstDateTime: "",
      secondDate: "",
      secondDateTime: "",
    });
  }, [values.counselor_gender]);

  useEffect(() => {
    if (isMobile) {
      setIsMo(true);
    } else {
      setIsMo(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container topPadding">
          <div className="d-flex flex-column mainBottomMargin">
            <h3 className="MainText">상담 신청</h3>
            <div className="d-flex">
              <div>
                <span className="SubText">
                  원하시는 일정에 맞춰 간편하게 상담 예약하세요.
                  <br />
                  수도권 회원의 경우 전화 상담은 불가하고 방문 상담만 가능하며,
                  <br />
                  평일뿐만 아니라 주말, 공휴일도 상담 예약 가능합니다.
                  <br className="brcontroll" />
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <form className="js-step-form" onSubmit={submitApi}>
            <div className="row">
              <div id="formContainer" className="col-lg-12">
                <div id="uploadResumeStepFormContent">
                  <Row>
                    <Col lg={6}>
                      <div className="mottomMargin45">
                        <label for="firstNameLabel" className="labelText">
                          지사
                        </label>
                        <select
                          ref={locationRef}
                          id="locationLabel"
                          className="form-select customInput"
                          name="location"
                          onChange={handleChange}
                          style={
                            !values.location
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          {!values.location ? (
                            <option>지사를 선택해 주세요.</option>
                          ) : null}
                          {locationList.map((item, i) => {
                            return (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label
                          for="counselor_genderLabel"
                          className="labelText"
                        >
                          희망상담사 성별
                        </label>

                        <select
                          ref={counselor_genderRef}
                          id="counselor_genderLabel"
                          className="form-select customInput"
                          name="counselor_gender"
                          onChange={handleChange}
                          style={
                            !values.counselor_gender
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          {!values.counselor_gender ? (
                            <option>상담사의 성별을 선택해 주세요.</option>
                          ) : null}
                          <option value="남자">남자</option>
                          <option value="여자">여자</option>
                          <option value="무관">무관</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="firstDateLabel" className="labelText">
                          1순위 상담 일정
                        </label>
                        <div className="input-group">
                          {/* {isMo
                            ? showPlaceholder && (
                                <label
                                  htmlFor="date"
                                  style={{
                                    position: "absolute",
                                    left: "10px",
                                    top: "1px",
                                    color: "#888",
                                    pointerEvents: "none",
                                    userSelect: "none",
                                    height: "97%",
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px",
                                    zIndex: 10,
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  연도.월.일.
                                </label>
                              )
                            : null} */}
                          <input
                            ref={firstDateRef}
                            type="date"
                            className="js-input-mask form-control customInputLeft"
                            name="firstDate"
                            id="firstDateLabel"
                            min={moment().format("YYYY-MM-DD")}
                            max="9999-12-31"
                            onChange={handleDateChange}
                            value={values.firstDate}
                            style={
                              !values.firstDate
                                ? {
                                    color: "#BCBCBC",
                                  }
                                : { color: "#333" }
                            }
                          />
                          {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          /> */}

                          <select
                            className="form-select customInputRight"
                            name="firstDateTime"
                            value={values.firstDateTime}
                            ref={firstDateTimeRef}
                            style={{
                              maxWidth: "8rem",
                            }}
                            onChange={handleChange}
                          >
                            {!values?.firstDateTime ? (
                              <option value="">시간</option>
                            ) : null}
                            {/* <option
                              value="09:00:00"
                              disabled={disableHours1.includes("09")}
                            >
                              09:00
                            </option> */}
                            <option
                              value="10:00:00"
                              disabled={disableHours1.includes("10")}
                            >
                              10:00
                            </option>
                            <option
                              value="11:00:00"
                              disabled={disableHours1.includes("11")}
                            >
                              11:00
                            </option>
                            <option
                              value="12:00:00"
                              disabled={disableHours1.includes("12")}
                            >
                              12:00
                            </option>
                            <option
                              value="13:00:00"
                              disabled={disableHours1.includes("13")}
                            >
                              13:00
                            </option>
                            <option
                              value="14:00:00"
                              disabled={disableHours1.includes("14")}
                            >
                              14:00
                            </option>
                            <option
                              value="15:00:00"
                              disabled={disableHours1.includes("15")}
                            >
                              15:00
                            </option>
                            <option
                              value="16:00:00"
                              disabled={disableHours1.includes("16")}
                            >
                              16:00
                            </option>
                            <option
                              value="17:00:00"
                              disabled={disableHours1.includes("17")}
                            >
                              17:00
                            </option>
                            <option
                              value="18:00:00"
                              disabled={disableHours1.includes("18")}
                            >
                              18:00
                            </option>
                            <option
                              value="19:00:00"
                              disabled={disableHours1.includes("19")}
                            >
                              19:00
                            </option>
                            <option
                              value="20:00:00"
                              disabled={disableHours1.includes("20")}
                            >
                              20:00
                            </option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="secondDateLabel" className="labelText">
                          2순위 상담 일정
                        </label>
                        <div className="input-group">
                          <input
                            ref={secondDateRef}
                            type="date"
                            className="js-input-mask form-control customInputLeft"
                            name="secondDate"
                            id="secondDateLabel"
                            min={moment().format("YYYY-MM-DD")}
                            max="9999-12-31"
                            onChange={handleDateChange2}
                            value={values.secondDate}
                            style={
                              !values.secondDate
                                ? {
                                    color: "#BCBCBC",
                                  }
                                : { color: "#333" }
                            }
                          />

                          <select
                            className="form-select customInputRight"
                            name="secondDateTime"
                            value={values.secondDateTime}
                            ref={secondDateTimeRef}
                            style={{
                              maxWidth: "8rem",
                            }}
                            onChange={handleChange}
                          >
                            {!values?.secondDateTime ? (
                              <option value="">시간</option>
                            ) : null}
                            {/* <option
                              value="09:00:00"
                              disabled={disableHours2.includes("09")}
                            >
                              09:00
                            </option> */}
                            <option
                              value="10:00:00"
                              disabled={disableHours2.includes("10")}
                            >
                              10:00
                            </option>
                            <option
                              value="11:00:00"
                              disabled={disableHours2.includes("11")}
                            >
                              11:00
                            </option>
                            <option
                              value="12:00:00"
                              disabled={disableHours2.includes("12")}
                            >
                              12:00
                            </option>
                            <option
                              value="13:00:00"
                              disabled={disableHours2.includes("13")}
                            >
                              13:00
                            </option>
                            <option
                              value="14:00:00"
                              disabled={disableHours2.includes("14")}
                            >
                              14:00
                            </option>
                            <option
                              value="15:00:00"
                              disabled={disableHours2.includes("15")}
                            >
                              15:00
                            </option>
                            <option
                              value="16:00:00"
                              disabled={disableHours2.includes("16")}
                            >
                              16:00
                            </option>
                            <option
                              value="17:00:00"
                              disabled={disableHours2.includes("17")}
                            >
                              17:00
                            </option>
                            <option
                              value="18:00:00"
                              disabled={disableHours2.includes("18")}
                            >
                              18:00
                            </option>
                            <option
                              value="19:00:00"
                              disabled={disableHours2.includes("19")}
                            >
                              19:00
                            </option>
                            <option
                              value="20:00:00"
                              disabled={disableHours2.includes("20")}
                            >
                              20:00
                            </option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="nameLabel" className="labelText">
                          성함
                        </label>
                        <input
                          ref={nameRef}
                          type="text"
                          className="js-input-mask form-control customInput"
                          name="name"
                          id="nameLabel"
                          placeholder="성함을 입력해 주세요."
                          aria-label="성함을 입력해 주세요."
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="phoneLabel" className="labelText">
                          연락처
                        </label>
                        <input
                          ref={phoneRef}
                          type="text"
                          className="js-input-mask form-control customInput"
                          name="phone"
                          id="phoneLabel"
                          placeholder="연락처를 입력해 주세요."
                          aria-label="연락처를 입력해 주세요."
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="kakaoTalkIdLabel" className="labelText">
                          카카오톡 ID
                        </label>
                        <input
                          ref={kakaoTalkIdRef}
                          type="text"
                          className="js-input-mask form-control customInput"
                          name="kakaoTalkId"
                          id="kakaoTalkIdLabel"
                          placeholder="카카오톡 ID를 입력해 주세요."
                          aria-label="카카오톡 ID를 입력해 주세요."
                          onChange={handleChange}
                        />
                        <p className="request-kakaotalk-id">* 최근 카카오톡 전화번호로 친구 추가 허용을 막아놓은 분들이 많아 업무상 연락이 힘들어 카톡 아이디도 함께 작성 부탁드립니다</p>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="birth_yearLabel" className="labelText">
                          출생년도
                        </label>

                        <select
                          ref={birth_yearRef}
                          id="birth_yearLabel"
                          className="form-select customInput"
                          name="birth_year"
                          onChange={handleChange}
                          style={
                            !values.birth_year
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          {!values.birth_year ? (
                            <option>출생년도를 선택해 주세요.</option>
                          ) : null}
                          {yearList.map((item, i) => {
                            return (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="genderLabel" className="labelText">
                          성별
                        </label>

                        <select
                          ref={genderRef}
                          id="genderLabel"
                          className="form-select customInput"
                          name="gender"
                          onChange={handleChange}
                          style={
                            !values.gender
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          {!values.gender ? (
                            <option>성별을 선택해 주세요.</option>
                          ) : null}
                          <option value="남자">남자</option>
                          <option value="여자">여자</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label for="educationLabel" className="labelText">
                          최종학력
                        </label>
                        <select
                          ref={educationRef}
                          id="educationLabel"
                          className="form-select customInput"
                          name="education"
                          onChange={handleChange}
                          style={
                            !values.education
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          {!values.education ? (
                            <option>최종학력을 선택해 주세요.</option>
                          ) : null}
                          {educationList.map((item, i) => {
                            return (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </Row>

                  {/* <label className="labelText subBottomMargin18">
                    이성 선호도(총점 10점이 될 수 있게 각 항목별로 중요도
                    분배해주세요)
                  </label> */}
                  <Row>
                    {/* <Col lg={4} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label
                          for="contactInformationLabel"
                          className="labelText"
                        >
                          외모
                        </label>
                        <select
                          className="form-select customInput"
                          name="ideal_appearance"
                          value={values.ideal_appearance}
                          onChange={handleIdealChange}
                          style={
                            !values.ideal_appearance
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          <option value={"0"}>0</option>
                          <option value={"1"}>1</option>
                          <option value={"2"}>2</option>
                          <option value={"3"}>3</option>
                          <option value={"4"}>4</option>
                          <option value={"5"}>5</option>
                          <option value={"6"}>6</option>
                          <option value={"7"}>7</option>
                          <option value={"8"}>8</option>
                          <option value={"9"}>9</option>
                          <option value={"10"}>10</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label
                          for="contactInformationLabel"
                          className="labelText"
                        >
                          능력&집안
                        </label>

                        <select
                          className="form-select customInput"
                          name="ideal_ability"
                          value={values.ideal_ability}
                          onChange={handleIdealChange}
                          style={
                            !values.ideal_ability
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          <option value={"0"}>0</option>
                          <option value={"1"}>1</option>
                          <option value={"2"}>2</option>
                          <option value={"3"}>3</option>
                          <option value={"4"}>4</option>
                          <option value={"5"}>5</option>
                          <option value={"6"}>6</option>
                          <option value={"7"}>7</option>
                          <option value={"8"}>8</option>
                          <option value={"9"}>9</option>
                          <option value={"10"}>10</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} className="d-flex flex-column">
                      <div className="mottomMargin45">
                        <label
                          for="contactInformationLabel"
                          className="labelText"
                        >
                          성격
                        </label>

                        <select
                          className="form-select customInput"
                          name="ideal_character"
                          value={values.ideal_character}
                          onChange={handleIdealChange}
                          style={
                            !values.ideal_character
                              ? {
                                  color: "#BCBCBC",
                                }
                              : { color: "#333" }
                          }
                        >
                          <option value={"0"}>0</option>
                          <option value={"1"}>1</option>
                          <option value={"2"}>2</option>
                          <option value={"3"}>3</option>
                          <option value={"4"}>4</option>
                          <option value={"5"}>5</option>
                          <option value={"6"}>6</option>
                          <option value={"7"}>7</option>
                          <option value={"8"}>8</option>
                          <option value={"9"}>9</option>
                          <option value={"10"}>10</option>
                        </select>
                      </div>
                    </Col> */}
                    <Col lg={6} className="d-flex flex-column">
                      <div className="hopeMargin">
                        <label for="recommenderLabel" className="labelText">
                          추천인
                        </label>
                        <input
                          type="text"
                          className="js-input-mask form-control customInput"
                          name="recommender"
                          id="recommenderLabel"
                          placeholder="추천인을 입력해 주세요."
                          aria-label="추천인을 입력해 주세요."
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Row>
                      <Col lg={7}>
                        <div className="checkMarginBottom checkMarginTop">
                          <div className="form-check">
                            <input
                              ref={privacy_agreeRef}
                              type="checkbox"
                              className="form-check-input customCheckBox"
                              id="privacy_agree"
                              name="privacy_agree"
                              onChange={handleCheck}
                            />
                            <label className="labelText" for="privacy_agree">
                              개인정보 수집 및 이용에 동의합니다.
                              <br />
                              {/* <span className="checkLabelText">
                                * 개인정보 및 상담내용은 철처히 보안이 유지되며,
                                모든 상담은 무료로 진행됩니다.
                              </span> */}
                            </label>
                            <span
                              style={{
                                fontSize: "14px",
                                marginLeft: "20px",
                                color: "#727272",
                                cursor: "pointer",
                              }}
                              onClick={() => setPrivacyModal(true)}
                            >
                              [자세히 보기]
                            </span>
                          </div>
                        </div>
                        <div className="checkMarginBottom">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input customCheckBox"
                              id="marketing_agree"
                              name="marketing_agree"
                              onChange={handleCheck}
                            />
                            <label className="labelText" for="marketing_agree">
                              마케팅 활용에 동의합니다.
                              {/* <span className="checkLabelText">
                                * 서비스와 관련된 소식, 이벤트 안내, 고객 혜택
                                등 다양한 정보를 제공합니다.
                              </span> */}
                              <br />
                            </label>
                            <span
                              style={{
                                fontSize: "14px",
                                marginLeft: "20px",
                                color: "#727272",
                                cursor: "pointer",
                              }}
                              onClick={() => setMarketingModal(true)}
                            >
                              [자세히 보기]
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="customBtnWrap">
                          <button ref={submitButtonRef} type="submit" className="customButton">
                            신청하기
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </div>
                <div id="stickyBlockEndPoint"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {privacyModal ? (
        <PrivacyDetail setPrivacyModal={setPrivacyModal} />
      ) : null}
      {marketingModal ? (
        <MarketingDetail setMarketingModal={setMarketingModal} />
      ) : null}
      {busyDateModal ? (
        <BusyDateModal
          busyDateModal={busyDateModal}
          setBusyDateModal={setBusyDateModal}
        />
      ) : null}
    </React.Fragment>
  );
}

export default ConsultRequest;
