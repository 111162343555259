import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./footer.scss";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <footer
        className="footer"
        style={{ background: "#4F4F4F", color: "#FFF" }}
      >
        <Container className="py-4">
          <Row>
            <Col md={6} className="footerText">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => history.push("/total/2")}
              >
                개인정보처리방침
              </span>
              <span
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                |
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => history.push("/total/1")}
              >
                이용약관
              </span>
              <span
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                |
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => history.push("/total/4")}
              >
                손해배상 청구절차
              </span>
              <br />
              서울특별시 서초구 서초동 1330-3 엔데버빌딩 9층 2호
              <br />
              사업자 등록번호 869-03-03028 <br />
              국내 결혼중개업 신고번호 : 서울-강남-국내-24-0001호
              <br />
              전화번호 : 02-2138-0638
              <br />
              통신판매업신고 : 2024-서울강남-02849
              <br />
              우연 <br /> 대표이사 김여정 <br /> 이메일 : coihelp@naver.com
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">©2024 우연</div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
