import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";

function BusyDateModal({ busyDateModal, setBusyDateModal }) {
  const [timesent, setTimesent] = useState(false);
  const history = useHistory();
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  // const hideForAnHour = () => {
  //   if (timesent) {
  //     const now = new Date().getTime();
  //     const oneHourLater = now + 3600000;
  //     localStorage.setItem("hideUntil", oneHourLater.toString());
  //   }
  //   setBusyDateModal(true);
  // };

  return (
    // <React.Fragment>
    //   <Container fluid className="modalWrap">
    //     <Row className="h-100 d-flex align-items-center justify-content-center">
    //       <Col xs="12" sm="10" md="8" lg="6" xl="5">
    //         <Card className="modalCard">
    //           <CardBody className="busycardTextWrap">
    //             {/* <div className="busycardText"> */}
    //             {`${busyDateModal.join()}\n위 일정들은 신청 마감되었습니다. 다른 날짜를 선택해주세요.`}
    //             {/* </div> */}
    //           </CardBody>
    //           <Row className="w-100 m-0 mt-3">
    //             <Col className="p-0">
    //               <Button
    //                 id="masterAccesBtn"
    //                 className="w-100"
    //                 style={{ borderRadius: "0px" }}
    //                 onClick={() => setBusyDateModal(null)}
    //               >
    //                 확인
    //               </Button>
    //             </Col>
    //           </Row>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </Container>
    // </React.Fragment>
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard h-100">
              <div
                className="busyModalText"
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "300px",
                }}
              >
                {`${busyDateModal.join()}\n\n위 일정들은 신청 마감되었습니다.\n다른 날짜를 선택해주세요.`}
              </div>
              <Row className="w-100 m-0">
                <Col className="p-0">
                  <Button
                    id="masterAccesBtn"
                    className="w-100"
                    style={{ borderRadius: "0px", backgroundColor: "#71869D" }}
                    onClick={() => setBusyDateModal(null)}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default BusyDateModal;
