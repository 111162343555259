import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Modal, Input, Button } from "reactstrap";
// import BackImg from "../../assets/images/small/img-4.jpg";
//i18n
import { withTranslation } from "react-i18next";
import MobileImg11 from "../../assets/img/others/mobile_1_1.png";
import MobileImg12 from "../../assets/img/others/mobile_1_2.png";
import MobileImg13 from "../../assets/img/others/mobile_1_3.png";
import MobileImg14 from "../../assets/img/others/mobile_1_4.png";
import MobileImg2 from "../../assets/img/others/mobile_2.png";
import MobileImg31 from "../../assets/img/others/mobile_3_1.png";
import MobileImg32 from "../../assets/img/others/mobile_3_2.png";
import PcImg11 from "../../assets/img/others/pc_1_1.png";
import PcImg12 from "../../assets/img/others/pc_1_2.png";
import PcImg13 from "../../assets/img/others/pc_1_3.png";
import PcImg2 from "../../assets/img/others/pc_2.png";
import PcImg31 from "../../assets/img/others/pc_3_1.png";
import PcImg32 from "../../assets/img/others/pc_3_2.png";

import "../Dashboard/dashboard.scss";

const Problem = (props) => {
  const [currentImg, setCurrentImg] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };

    // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 초기 로딩 시 화면 너비 체크
    handleResize();
    document.title = "왜 우연인가? | 우리의 인연";
    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <div className="border-bottom">
        {currentImg > 991 ? (
          <div>
            <img alt="mainpage" className="w-100" src={PcImg11} />
            <img alt="mainpage" className="w-100" src={PcImg12} />
            <img alt="mainpage" className="w-100" src={PcImg13} />
            <img alt="mainpage" className="w-100" src={PcImg2} />
            <img alt="mainpage" className="w-100" src={PcImg31} />
            <img alt="mainpage" className="w-100" src={PcImg32} />
          </div>
        ) : (
          <div>
            <img alt="mainpage" className="w-100" src={MobileImg11} />
            <img alt="mainpage" className="w-100" src={MobileImg12} />
            <img alt="mainpage" className="w-100" src={MobileImg13} />
            <img alt="mainpage" className="w-100" src={MobileImg14} />
            <img alt="mainpage" className="w-100" src={MobileImg2} />
            <img alt="mainpage" className="w-100" src={MobileImg31} />
            <img alt="mainpage" className="w-100" src={MobileImg32} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Problem.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Problem);
