import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";
import "./purcahse.scss";

function Products(props) {
  const [data, setData] = useState([]);
  const [marriagefee, setMarriagefee] = useState([]);
  const [radioCheck, setRadioCheck] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const history = useHistory();

  const callApi = async () => {
    const response = await requestGet(`/api/product/joinfee/list`);
    // const marriagefeeresponse = await requestGet(
    //   `/api/product/marriagefee/list`
    // );
    setData(response?.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  const handleRadio = (e) => {
    setRadioCheck(e.target.value);
  };

  useEffect(() => {
    if (radioCheck) {
      for (const item in data) {
        if (data[item].id === parseInt(radioCheck)) {
          setSelectedItem(data[item]);
        }
      }
    }
  }, [radioCheck]);

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  const toggle1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };

  const goPurchase = () => {
    if (selectedItem) {
      history.push({
        pathname: `/purchase`,
        state: {
          productId: selectedItem.id,
          name: selectedItem.name,
          amount: selectedItem.price,
          desc: selectedItem.desc,
        },
      });
    } else {
      alert("상품을 선택해 주세요.");
    }
  };

  useEffect(() => {
    document.title = "결제 안내 | 우리의 인연";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content customWrap">
        <Container className="purchase-container">
          <h1 className="purTitle">결제 안내</h1>
          <Row>
            <Col lg="8" className="purTableWrap">
              <table className="w-100">
                <thead className="purTableHeader">
                  <td></td>
                  <td>옵션명</td>
                </thead>
                <tbody>
                  {data?.map((item, i) => {
                    return (
                      <tr className="purTableBodyTr" key={i}>
                        <td width="20px">
                          <input
                            type="radio"
                            id={`${item.name}${item.price}`}
                            checked={parseInt(radioCheck) === item.id}
                            value={item.id}
                            onClick={(e) => handleRadio(e)}
                            className="purRadio"
                          />
                        </td>
                        <td>
                          <label htmlFor={`${item.name}${item.price}`}>
                            {item.name}
                            가격 :{" "}
                            {String(item.price).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            <br />
                            {item.desc}
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col lg="4" className="furRightWrap">
              <div className="furRight">
                <div className="furRightInWrap">
                  <Row className="furRightMain">
                    <Col>
                      <span className="furRightMainTitle">총 결제 금액</span>
                      <span className="furRightVAT">(VAT 포함)</span>
                    </Col>
                    <Col className="text-end">
                      <span className="furRightPrice">
                        {selectedItem?.price
                          ? String(selectedItem?.price).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) + "원"
                          : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row className="purAgree">
                    <Col>위 내용을 확인하였고 결제에 동의합니다.</Col>
                  </Row>
                  <Row>
                    <button className="purBtn" onClick={() => goPurchase()}>
                      구매하기
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg="8" className="purfooterWrap">
              <div className="purfooter">
                * 성혼사례금은 회사의 만남주선에 의하여 성혼(결혼식 또는
                혼인신고일 중 더 빠르게 도래하는 날 기준)이 되면 30일 이내에
                알리고 지불합니다. (위 성혼을 알리지 않거나 성혼사례금을
                지불하지 아니하면, 그 2배를 지급하여야 합니다.)
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Products;
