import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { ReactComponent as Waring } from "./waring.svg";

function ErrorModalBack({ message }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="modalWrap p-0 m-0">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0">
            <div className="modalOverlay" />
            <Card
              className="h-100 modalCard"
              style={{
                padding: "34px 20px",
                borderRadius: 0,
                justifyContent: "center",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center text-center primiumMainText"
                  style={{ marginBottom: "20px" }}
                >
                  <span className="capSvgWrap">
                    <Waring />
                  </span>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{ marginBottom: "10px", wordBreak: "keep-all" }}
                >
                  {message}
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{
                    color: "#4f4f4f",
                    fontSize: "16px",
                    fontWeight: "400",
                    wordBreak: "keep-all",
                  }}
                >
                  카카오톡에서 새 알림을 확인해 주세요.
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ErrorModalBack;
