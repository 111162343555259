import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";
import PremiumMsg from "./premiumMsg.png";

function PremiumMassegeModal({ setModalOn, name, likeMessage }) {
  const textareaRef = useRef(null);
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto"; // 높이 초기화
      const newHeight = Math.min(textarea.scrollHeight, 15 * 24); // 최대 5줄까지 허용
      textarea.style.height = `${newHeight}px`; // 새로운 높이 설정
    }
  }, [likeMessage, name]);

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "34px 16px 26px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center primiumMainText"
                  style={{ marginBottom: "20px" }}
                >
                  <img
                    src={PremiumMsg}
                    alt="sendicon"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{
                    wordDreak: "keep-all",
                  }}
                >
                  {`${name}님께서\n호감을 보냈습니다.`}
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 m-0 primiumSubText"
                >
                  <label
                    className="introlabelText"
                    style={{
                      color: "#4f4f4f",
                      fontSize: "13px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      marginBottom: "4px",
                      textAlign: "left",
                    }}
                  >
                    메세지
                  </label>
                  <textarea
                    ref={textareaRef}
                    className="js-input-mask introlabelInput"
                    value={likeMessage}
                    readOnly
                    rows={1}
                    style={{
                      padding: "10px",
                      resize: "none",
                      boxSizing: "border-box",
                      borderRadius: "8px",
                      border: "1px solid #CCC",
                      boxShadow: "none",
                      marginBottom: "34px",
                      wordBreak: "keep-all",
                    }}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 4px" }}>
                  <Button
                    id="primiumAccesBtn"
                    onClick={() => setModalOn(false)}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PremiumMassegeModal;
