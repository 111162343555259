import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card,Col, Container, Row, Modal, Input, Button } from "reactstrap";
// import BackImg from "../../assets/images/small/img-4.jpg";
//i18n
import { withTranslation } from "react-i18next";
import { requestGet } from "../../apis/request";

import MobileImg1 from "../../assets/img/others/MOCUTIMG2.jpg";
import PcImg1 from "../../assets/img/others/PCCUTIMG2.jpg";

import MobileImg2Part1 from "../../assets/img/others/mo_cut_part1.png";
import MobileImg2Part2 from "../../assets/img/others/mo_cut_part2.png";
import PcImg2Part1 from "../../assets/img/others/pc_cut_part1.png";
import PcImg2Part2 from "../../assets/img/others/pc_cut_part12.png";

import "../Dashboard/dashboard.scss";

const Problem = (props) => {
  const [currentImg, setCurrentImg] = useState(0);
  const [nomalData, setNomalData] = useState([]);
  const [earlyData, setEarlyData] = useState([]);
  const [subData, setSubData] = useState([]);

  const callApi = async () => {
    const response = await requestGet(`/api/product/list`);
    let arr = [];
    let arr1 = [];
    let arr2 = [];
    if (response?.data) {
      response.data.forEach((item) => {
        if (item.type === "subscribe") {
          arr2.push(item);
        } else if (item.type === "joinFee") {
          if (item.name.includes("얼리버드")) {
            arr.push(item);
          } else {
            arr1.push(item);
          }
        }
      });
    }
    setEarlyData(arr);
    setNomalData(arr1);
    setSubData(arr2);
  };

  useEffect(() => {
    callApi();

    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };

    // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 초기 로딩 시 화면 너비 체크
    handleResize();
    document.title = "왜 우연인가? | 우리의 인연";
    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <div className="border-bottom">
        <div>
        {currentImg > 991 ? (
            <img alt="mainpage" className="w-100" src={PcImg2Part1} />
        ) : (
            <img alt="mainpage" className="w-100" src={MobileImg2Part1} />
        )}
        <Container>
          <h1 className="purMainTitle2">서비스 안내</h1>
          {nomalData.length > 0 ? (
            <Row >
              <Col className="purSubTitle2">횟수제</Col>
            </Row>
          ) : null}
          <Row className="justifyContentCenter">
            {nomalData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              let otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "")
                .replace("[", "")
                .replace("]", "");
              if(otherContent === "-"){
                otherContent = "";
              }
              return (
                <Col lg={7} key={i} className="maxWidth600">
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemType">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemName">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                      </Col>
                    </Row>
                    <Row>
                      <span className="purCardItemVAT">VAT 포함</span>
                    </Row>
                    <Row>
                      <Col className="purCardItemDesc">{otherContent}</Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          {subData.length > 0 ? (
            <Row>
              <Col className="purSubTitle2 mt30">기간제(기간 내 횟수 제한 없이 만남 가능)</Col>
            </Row>
          ) : null}
          <Row className="justifyContentCenter">
            {subData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              let otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "")
                .replace("[", "")
                .replace("]", "");
                if(otherContent === "-"){
                  otherContent = "";
                }
              return (
                <Col lg={7} key={i} className="maxWidth600">
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemType">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemName">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                      </Col>
                    </Row>
                    <Row>
                      <span className="purCardItemVAT">VAT 포함</span>
                    </Row>
                    <Row>
                      <Col className="purCardItemDesc">{otherContent  || '\u00A0'}</Col>
                    </Row>
                    {/* <Row>
                      <Col className="purCardItemSubDesc">{bracketContent}</Col>
                    </Row> */}
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
        {currentImg > 991 ? (
            <img alt="mainpage" className="w-100" src={PcImg2Part2} />
        ) : (
            <img alt="mainpage" className="w-100" src={MobileImg2Part2} />
        )}
          
          
          </div>
      </div>
    </React.Fragment>
  );
};

Problem.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Problem);
