import React from "react";
import { Row } from "reactstrap";

function HopeHate({ handleChange, SelectFn, values, handleCheckstr }) {
  const selOption = [
    {
      name: "직업분류",
      key: "hateIndustry",
      type: "text",
    },
    
    {
      name: "관심사",
      key: "hateInterest",
      type: "text",
    },
    {
      name: "추가 기피사항",
      key: "idealHateDetail",
      type: "textarea",
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">이상형 기피사항</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default HopeHate;
