import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";

function RejectPost({
  setCallModal,
  postReject,
  setErrorMessage,
  setCallErrorModal,
}) {
  const [drop, setDrop] = useState("");
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callapi = () => {
    if (message === "") {
      setErrorMessage("사유를 선택 하거나 직접 입력해 주세요.");
      setCallErrorModal(true);
    } else {
      setCallModal(false);
      postReject(message);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto"; // 높이 초기화
      const newHeight = Math.min(textarea.scrollHeight, 5 * 24); // 최대 5줄까지 허용
      textarea.style.height = `${newHeight}px`; // 새로운 높이 설정
    }
  }, [message]);

  const handleDrop = (event) => {
    setDrop(event.target.value);
    if (event.target.value !== "직접 입력") {
      setMessage(event.target.value);
    } else if (event.target.value === "직접 입력") {
      setMessage("");
    }
  };

  const handleChange = (event) => {
    if (event.target.value.length <= 200) {
      setMessage(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "36px 20px 45px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <button
                onClick={() => setCallModal(false)}
                className="noneBtn"
                style={{ position: "absolute", top: "20px", right: "20px" }}
              >
                <Xmark
                  width={20}
                  height={20}
                  onClick={() => setCallModal(false)}
                />
              </button>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  거절 하시겠습니까?
                </Col>
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                  style={{ marginBottom: "10px" }}
                >
                  아래 거절 사유를 선택해 주세요.
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0" style={{ marginBottom: "30px" }}>
                <Col lg={12} className="p-0 mx-0">
                  <div>
                    <label
                      className="introlabelText"
                      style={{
                        color: "#4f4f4f",
                        fontSize: "13px",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      사유 선택
                    </label>
                    <div
                      className="js-input-mask introlabelInput"
                      style={
                        drop === "직접 입력"
                          ? {
                              padding: "0px 14px",
                              background: "#F3F3F3",
                              boxShadow: "none",
                              marginBottom: "10px",
                            }
                          : {
                              padding: "0px 14px",
                              background: "#F3F3F3",
                              boxShadow: "none",
                              marginBottom: "10px",
                            }
                      }
                    >
                      <select
                        className="customselect1"
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "14px 0px",
                          background: "none",
                          color: "#333",
                        }}
                        onChange={handleDrop}
                      >
                        {message === "" ? (
                          <option value="">사유를 선택해 주세요.</option>
                        ) : null}
                        <option value="제 스타일이 아니에요.">
                          제 스타일이 아니에요.
                        </option>
                        <option value="저랑 성격이 다를 것 같아요.">
                          저랑 성격이 다를 것 같아요.
                        </option>
                        <option value="나이 차이가 너무 많이 나요.">
                          나이 차이가 너무 많이 나요.
                        </option>
                        <option value="거리가 너무 멀어요.">
                          거리가 너무 멀어요.
                        </option>
                        <option value="종교가 달라요.">종교가 달라요.</option>
                        <option value="흡연자는 싫어요.">
                          흡연자는 싫어요.
                        </option>
                        <option value="직접 입력">직접 입력</option>
                      </select>
                    </div>
                    {drop === "직접 입력" ? (
                      <div>
                        <div
                          lg={12}
                          className="d-flex justify-content-between p-0 m-0"
                        >
                          <label
                            className="introlabelText"
                            style={{
                              color: "#4f4f4f",
                              fontSize: "13px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              marginBottom: "4px",
                            }}
                          >
                            직접 입력
                          </label>
                          <div
                            className="introlabelText"
                            style={{
                              color: "#4f4f4f",
                              fontSize: "13px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              marginBottom: "4px",
                            }}
                          >
                            {message.length}/200
                          </div>
                        </div>
                        <textarea
                          ref={textareaRef}
                          className="js-input-mask introlabelInput"
                          value={message}
                          onChange={handleChange}
                          placeholder={`개인 연락처를 남기는 경우 환불 없이 영구 정지 처리됩니다.`}
                          aria-label={`개인 연락처를 남기는 경우 환불 없이 영구 정지 처리됩니다.`}
                          rows={3}
                          style={{
                            padding: "10px",
                            resize: "none",
                            boxSizing: "border-box",
                            borderRadius: "8px",
                            border: "1px solid #CCC",
                            boxShadow: "none",
                            marginBottom: "10px",
                            wordBreak: "keep-all",
                          }}
                        />
                      </div>
                    ) : null}
                    <div
                      lg={12}
                      className="d-flex justify-content-between p-0 m-0"
                    >
                      <label
                        className="introlabelText"
                        style={{
                          color: "#4f4f4f",
                          fontSize: "13px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          marginBottom: "34px",
                          wordBreak: "keep-all",
                        }}
                      >
                        거절 사유 입력 시 5,000 마일리가 지급됩니다.
                        <br />
                        20만 마일리지 적립 시 1회 매칭권으로 교환 가능합니다.
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callapi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default RejectPost;
