import React from "react";
import { Row, Col } from "reactstrap";

function Privacy2(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12">
              <tbody>
                <tr>
                  <td class="privacy__box-1">
                    <h3 className="my-3">제 1 조 (목적)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      이 약관 및 표준계약서(붙임)은 우리의
                      인연(국내결혼중개업자, 이하 “회사”라 함)가 제공하는
                      국내결혼 관련 중개서비스(이하 “서비스”라 함)를 이용함에
                      있어 회사와 회원 사이의 권리·의무 및 책임사항을 규정함을
                      목적으로 합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-2">
                    <h3 className="my-3">제 2 조 (정의)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① “회원”이라 함은 제3조에서 정한 가입절차에 따라 회사가 그
                      가입신청을 승낙하고 이 약관에 따라 계약을 체결한 사람을
                      말합니다.
                      <br />
                      ② “소개”라 함은 회사가 회원간에 결혼상대방을 구하기 위한
                      만남을 주선하는 것을 말합니다.
                      <br />
                      ③ “교제”라 함은 회사의 소개로 만난 회원이 2회 이상
                      계속하여 만나는 것을 말합니다.
                      <br />④ “결혼관련 개인정보”라 함은 학력, 직업, 병력 등
                      통상 결혼함에 있어 당사자 사이에 확인할 필요가 있는 것으로
                      인정되는 개인정보를 말합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-3">
                    <h3 className="my-3">제 3 조 (회원가입)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회원이 되려고 하는 사람은 결혼관련 개인정보를 회사에
                      제공한 후 회사가 정한 가입절차에 따라 회원가입을
                      신청합니다.
                      <br />
                      ② 회사는 제1항과 같이 회원가입을 신청한 자 중 다음 각 호의
                      사항에 대한 심사를 실시하여 그 적격 여부를 판단합니다.
                      <br />
                      1. 배우자(사실혼관계의 배우자 포함) 있는 자인지 여부의
                      확인 2. 결혼관련 개인정보의 사실여부의 확인
                      <br />③ 회사는 제2항의 심사결과 적격자로 인정한 신청자에
                      한하여 회원가입을 승낙하고, 이 약관에 따라 계약을
                      체결합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-4">
                    <h3 className="my-3">
                      제 4 조 (계약서·약관의 명시·교부 등)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사는 계약 체결시 이 약관의 내용을 회원으로 가입하려는
                      자에게 설명하고, 계약서와 함께 이 약관을 교부해야 합니다.
                      <br />
                      ② 회사는 약관의 규제에 관한 법률, 소비자기본법 등
                      관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수
                      있습니다.
                      <br />
                      ③ 회사가 이 약관을 개정할 경우에는 적용일자, 개정사유 및
                      개정내용을 명시하여 현행약관과 함께 그 적용일자 30일
                      이전까지 제13조의 방법으로 예고합니다.
                      <br />④ 회사가 약관을 개정할 경우에는 그 개정약관은 그
                      적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
                      체결된 계약에 대해서는 개정전의 약관조항이 그대로
                      적용됩니다. 다만, 이미 계약을 체결한 회원이 개정약관
                      조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
                      예고기간 내에 회사에 서면으로 통지하여 회사의 동의를 받은
                      경우에는 개정약관조항이 적용됩니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-5">
                    <h3 className="my-3">제 5 조 (서비스의 제공)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사는 회원에게 다음과 같은 서비스를 제공합니다.
                      <br />
                      1. 회원에 대한 결혼상담 및 인터넷 등을 통한 결혼관련
                      정보의 제공
                      <br />
                      2. 회원의 소개 및 이를 위한 행사 등의 개최
                      <br />
                      3. 회원에 대한 결혼관련 개인정보의 관리
                      <br />
                      4. 기타 결혼과 관련된 사항으로서 회사가 정하는 서비스
                      <br />② 회사는 서비스의 내용과 방법이 변경된 경우에는
                      회원의 동의가 없는 한 이 약관 변경의 방법에 의해서만
                      서비스의 내용과 방법을 변경할 수 있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-6">
                    <h3 className="my-3">제 6 조 (회원자격의 보유기간 등)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회원이 회사로부터 제5조에서 정한 서비스를 제공받을 수
                      있는 기간은 제7조 제1항에서 정한 계약 체결일로부터
                      12개월입니다. 다만, 회사가 제7조 제1항에서 정한 총횟수의
                      만남을 다한 경우에는 그 기간이 종료된 것으로 봅니다.
                      <br />② 회사의 책임있는 사유로 회원에게 서비스를 제공하지
                      못하고 제1항 본문의 기간이 경과한 경우, 회원은 회사에
                      대하여 당해 기간만큼 회원자격 보유기간을 연장하여 줄 것과
                      나머지 횟수의 소개를 이행하여 줄 것을 청구하거나 계약을
                      해지할 수 있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-7">
                    <h3 className="my-3">제 7 조 (회원의 관리)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회원이 회사와 총횟수로 계약을 체결하는 경우에 회원은
                      회사의 소개로 앞면 표준계약서 2항의 만남을 갖습니다.
                      <br />
                      ② 제7조 제1항에서 만남의 총횟수(횟수제) 또는 계약기간의
                      총일수(기간제)는 제11조의 가입비 환급의 기준이 됩니다.
                      회사는 회원에게 이성을 소개하는 경우 만남에 필요한
                      제반정보를 사전에 제공합니다.
                      <br />
                      ③ 회원은 회사의 이성 소개에 대하여 2일전까지 만남을 보류할
                      것을 신청할 수 있습니다. 이때 회원은 이미 다른 회원과 교제
                      중이거나 본인의 입원, 출장 또는 가족의 사고나 사망 등
                      상대방과의 만남에 응 할 수 없는 불가피한 사정을
                      소명함으로써 만남횟수의 산입을 면할 수 있습니다.
                      <br />
                      ④ 회원은 회사로부터 고유번호를 부여받아 회사가 제공하는
                      인터넷정보서비스를 이용할 수 있습니다.
                      <br />
                      ⑤ 회원은 회사가 주최하는 각종 행사에 무료 또는 할인 혜택을
                      받아 참가할 수 있습니다.
                      <br />⑥ 회원은 회원자격 보유기간 동안 회사가 제공하는
                      결혼관련 할인서비스를 받을 수 있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-8">
                    <h3 className="my-3">제 8 조 (회원의 의무)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회원은 회사와 가입계약체결 후 회사에 앞면 표준계약서
                      1항의 가입비를 납부하여야 합니다.
                      <br />
                      ② 회원은 회사에 결혼관련 개인정보를 사실대로 제공하여야
                      합니다.
                      <br />
                      ③ 회원은 회사에 제공한 결혼관련 개인정보에 변동이 있을
                      경우 15일 이내에 회사에 이를 통지하여야 합니다.
                      <br />
                      ④ 회원은 회사와 합의하여 회사가 소개한 상대방과의 만남에
                      응하여야 합니다. 다만, 제7조 제3항의 경우에는 그러하지
                      않습니다.
                      <br />⑤ 회원이 회사의 소개로 교제를 시작한 경우에는
                      그로부터 1월 이내에 회사에 그 사실을 통지하여야 합 니다.
                      결혼하기로 확정한 경우에도 같습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-9">
                    <h3 className="my-3">
                      제 9 조 (회원의 인터넷정보서비스 이용시 주의의무)
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사가 제공하는 인터넷정보서비스 이용시 ID와 비밀번호에
                      관한 관리책임은 회원에게 있습니다.
                      <br />
                      ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                      안됩니다.
                      <br />③ 회원이 자신의 ID 및 비밀번호를 도난당하거나
                      제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에
                      통지하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-10">
                    <h3 className="my-3">제 10 조 (계약의 종료)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 계약의 종료사유는 다음과 같습니다.
                      <br />
                      1. 당사자에 의한 계약의 해지
                      <br />
                      2. 제6조에서 정한 회원자격 보유기간의 경과(기간제) 및
                      만남의 총횟수 이행완료(횟수제)
                      <br />
                      3. 회원간의 결혼
                      <br />
                      4. 회원의 사망, 회사의 파산 기타 계약의 목적을 달성할 수
                      없는 경우
                      <br />
                      ② 회사는 다음 각 호의 사유가 확인된 경우 회원에 대하여
                      최고하지 아니하고 계약을 해지할 수 있습니다.
                      <br />
                      1. 회원이 위조 또는 변조된 서류를 제출한 경우. 다만,
                      회원의 고의나 중과실이 없는 경우는 제외합니다.
                      <br />
                      2. (2006. 12. 22. 본호 삭제)
                      <br />
                      3. 이성과 동거하고 있는 경우
                      <br />
                      4. 회원이 제8조 제2항에 위반하여 허위의 정보를 제공한
                      경우. 단, 다소 과장된 표현 등 경미한 위반이나 사소한
                      사항을 누락한 경우는 제외합니다.
                      <br />
                      5. 회원이 제8조 제4항에 위반하여 만남보류의 신청없이
                      무단으로 2회 이상 상대방과의 만남에 응하지 아니하거나,
                      만남보류를 신청하고 제출하는 제7조 제3항의 소명자료가
                      허위인 경우
                      <br />
                      6. 회원이 회사의 소개로 상대방과 만나거나 교제하면서
                      사회통념상 상대방에게 모욕감이나 심한 불쾌감을 주는 행위를
                      하여 상대방이 회사에 그에 관하여 2회 이상 항의한 경우
                      <br />
                      ③ 회사는 다음 각 호의 사유가 확인된 경우 회원에 대하여
                      2주간의 최고를 하고 회원이 재발방지를 위한 적절한 조치를
                      취하지 아니한 때에는 계약을 해지할 수 있습니다.
                      <br />
                      1. 회사의 신용, 명예를 손상시키는 행위를 한 경우
                      <br />
                      2. 회원이 법령 기타 이 약관에 위반되는 행위를 한 경우
                      <br />④ 회원은 언제든지 최고없이 계약을 해지할 수
                      있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-11">
                    <h3 className="my-3">제 11 조 (가입비의 환급)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사의 책임 있는 사유로 계약이 해지되는 경우에는 다음과
                      같이 회원가입비를 환급합니다.(회사의 책임있는 사유는
                      소비자분쟁해결기준에 준함)
                      <br />
                      1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된
                      경우: 회원가입비 +회원가입비의 10%
                      <br />
                      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우:
                      회원가입비 + 회원가입비의 15%
                      <br />
                      3. 만남일자 확정 후에 해지된 경우: 회원가입비 +
                      회원가입비의 20%
                      <br />
                      4. 1회 만남 이후 해지된 경우: 회원가입비×(잔여횟수/총횟수)
                      + 회원가입비의 20%
                      <br />
                      ② 회사의 책임없이 계약이 해지되는 경우에는 다음과 같이
                      회원가입비를 환급합니다.
                      <br />
                      1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된
                      경우: 회원가입비의 90%
                      <br />
                      2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우:
                      회원가입비의 85%
                      <br />
                      3. 만남일자 확정 후에 해지된 경우: 회원가입비의 80%
                      <br />
                      4. 1회 만남 이후 해지된 경우: 회원가입비의
                      80%×(잔여횟수/총횟수)
                      <br />
                      ③ 제6조 제2항에 의하여 회원의 자격기간이 연장되는 경우나
                      제10조 제1항 제2호, 제 3호의 사유로 계약이 종료된 경우에는
                      가입비를 환급하지 아니합니다.
                      <br />④ 위 제1항, 제2항에 불구하고 제10조 제1항 제2호,
                      제3호 외의 사유로 계약이 종료된 경우 회원은 회사의 동의를
                      얻어 가입비를 환급받지 아니하고 그에 상응하는 회원자격을
                      타인으로 하여금 보유하게 할 수 있습니다. 다만, 타인은
                      제3조 제2항에서 정한 적격자에 해당하여야 합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-12">
                    <h3 className="my-3">제 12 조 (개인정보의 보호)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사는 회원에 관한 정보수집시 필요한 최소한의 결혼관련
                      개인정보를 수집합니다.
                      <br />
                      ② 회사가 회원의 개인식별이 가능한 개인정보를 수집하는
                      때에는 반드시 당해 회원 또는 회원가입신청자의 동의를
                      받습니다.
                      <br />
                      ③ 제공된 개인정보는 당해 회원의 동의없이 목적 외의
                      이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은
                      회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.
                      <br />
                      1. 소개 등 회사업무에 필요한 최소한의 회원의 정보(성명,
                      주소, 전화번호)를 알려주는 경우
                      <br />
                      2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한
                      경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
                      <br />
                      ④ 회사가 제2항과 제3항에 의해 회원의 동의를 받아야 하는
                      경우에는 정보의 수집목적 및 이용목적, 제 3자에 대한
                      정보제공 관련사항(제공받는자, 제공목적 및 제공할 정보의
                      내용)등을 미리 명시하거나 통지하여야 하며 회원은 언제든지
                      이 동의를 철회할 수 있습니다.
                      <br />
                      ⑤ 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에
                      대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해
                      지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의
                      정정을 요구한 경우에 는 회사는 그 오류를 정정할 때까지
                      당해 개인정보를 이용하지 않습니다.
                      <br />
                      ⑥ 회사는 개인정보 보호를 위하여 노력하며 개인정보의 분실,
                      도난, 유출, 변조 등으로 인한 회원의 손해에 대하여 모든
                      책임을 집니다.
                      <br />⑦ 회사 또는 그로부터 개인정보를 제공받은 제3자는
                      계약의 해지 기타 개인정보의 수집목적 또는 제공받은 목적을
                      달성한 때에는 당해 개인정보를 지체없이 파기합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-13">
                    <h3 className="my-3">제 13 조 (회원에 대한 통지)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      회원에 대한 통지 및 예고는 서면, 전화, 모사전송 또는
                      전자우편(e-mail)의 방법으로 할 수 있습니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-14">
                    <h3 className="my-3">제 14 조 (약관의 해석)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      이 약관에서 정하지 아니한 사항과 이 약관의 해석은 관계법령
                      및 상관례에 따릅니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-15">
                    <h3 className="my-3">제 15 조 (분쟁해결)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 회사는 회원으로부터 제출되는 불만사항 및 의견은
                      우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
                      곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통지해
                      드립니다.
                      <br />② 이 약관과 관련하여 당사자 사이에 다툼이 있는
                      경우에는 당사자의 협의로 처리하되 신의성실의 원칙에 따라
                      원만히 해결합니다.
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="privacy__box-16">
                    <h3 className="my-3">제 16 조 (재판관할 및 준거법)</h3>
                    <div style={{ lineHeight: "37px" }}>
                      ① 이 약관과 관련하여 회사와 회원간에 발생한 분쟁에 관한
                      소송은 민사소송법상의 관할법원에 제기합니다.
                      <br />② 이 약관과 관련하여 회사와 회원간에 제기된 소송에는
                      한국법을 적용합니다. 20211008
                    </div>
                  </td>
                </tr>
              </tbody>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Privacy2;
