import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";
import Reject from "./reject.png";

function RejectMassegeModal({ setModalOn, name, type, likeMessage }) {
  const textareaRef1 = useRef(null);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    const textarea1 = textareaRef1.current;
    if (textarea1) {
      textarea1.style.height = "auto";
      textarea1.style.height = `${textarea1.scrollHeight}px`;
    }
  }, [likeMessage, textareaRef1]);

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "34px 20px 26px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center primiumMainText"
                  style={{ marginBottom: "20px" }}
                >
                  <img
                    src={Reject}
                    alt="sendicon"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex  p-0 mx-0 justify-content-center primiumMainText"
                  style={{
                    wordDreak: "keep-all",
                  }}
                >
                  {type === "프리미엄"
                    ? `${name}님께서\n프리미엄 호감을 거절하셨습니다.`
                    : `${name}님께서\n호감을 거절 하셨습니다.`}
                    {/* : `${name}님께서\n일반 호감을 거절 하셨습니다.`} */}
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <label
                  className="introlabelText"
                  style={{
                    padding: 0,
                    textAlign: "center",
                    color: "#818181",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "-0.15px",
                  }}
                >
                  거절 사유는 다음과 같습니다.
                </label>
                <Col lg={12} className="d-flex  p-0 mx-0  introlabelText">
                  {/* {likeMessage} */}
                  <textarea
                    ref={textareaRef1}
                    readOnly
                    rows={1}
                    className="js-input-mask introlabelInput"
                    value={likeMessage}
                    style={{
                      resize: "none",
                      borderRadius: "8px",
                      border: "1px solid #CCC",
                      background: "#FFF",
                      boxShadow: "none",
                    }}
                  />
                </Col>
                {type === "일반" ? (
                  <Col
                    lg={12}
                    className="d-flex  p-0 mx-0  introlabelText justify-content-center "
                    style={{
                      color: "#818181",
                      textAlign: "center",
                      fontFamily: "Pretendard",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "19.144px",
                    }}
                  >
                    {/* 놓치기 아쉽다면 프리미엄 호감으로 다시 한번 진심을
                    전해보세요. */}
                    더 좋은 인연이 찾아올 거예요.
                  </Col>
                ) : type === "프리미엄" ? (
                  <Col
                    lg={12}
                    className="d-flex  p-0 mx-0  introlabelText justify-content-center "
                    style={{
                      color: "#818181",
                      textAlign: "center",
                      fontFamily: "Pretendard",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "19.144px",
                    }}
                  >
                    더 좋은 인연이 찾아올 거예요.
                  </Col>
                ) : null}
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 4px" }}>
                  <Button
                    id="primiumAccesBtn"
                    onClick={() => setModalOn(false)}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default RejectMassegeModal;
