import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";

import { ReactComponent as Frame } from "./Frame.svg";

function InfoModal({ setModal }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "24px 32px 30px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col lg={12} className="d-flex justify-content-between p-0 m-0">
                  <div className="infoLeftText">호감</div>
                  {/* <div className="infoLeftText">일반호감</div> */}
                  <div className="infoRightText">매칭권 1개 사용</div>
                </Col>
              </Row>
              {/* <Row className="w-100 px-0 m-0">
                <Col lg={12} className="d-flex justify-content-between p-0 m-0">
                  <div className="infoLeftText">프리미엄 호감</div>
                  <div className="infoRightText">매칭권 2개 사용</div>
                </Col>
              </Row> */}
              <div className="infoBorder" />
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 infoDetailText"
                  style={{ marginBottom: "10px", textAlign: "left" }}
                >
                  호감은 상대가 맞호감하는 경우 양쪽 모두 매칭권 1개씩
                  차감됩니다.
                  {/* 일반 호감은 상대가 맞호감하는 경우 양쪽 모두 매칭권 1개씩
                  차감됩니다. */}
                </Col>
              </Row>
              {/* <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 infoDetailText"
                  style={{ textAlign: "left" }}
                >
                  프리미엄 호감권은 메세지를 추가로 보낼 수 있으며 상대방이
                  맞호감 하는 경우 상대방의 매칭권이 소모되지 않습니다.
                </Col>
              </Row> */}
              <Row className="w-100 m-0">
                <Col className="p-0">
                  <Button
                    id="primiumAccesBtn"
                    className="w-100"
                    style={{ borderRadius: "0px" }}
                    onClick={() => setModal(false)}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default InfoModal;
