import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";

function RejectwaringPost({ setCallModal, setCallWaringModal }) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callapi = () => {
    setCallWaringModal(false);
    setCallModal(true);
  };

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "36px 20px 45px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <button
                onClick={() => setCallWaringModal(false)}
                className="noneBtn"
                style={{ position: "absolute", top: "20px", right: "20px" }}
              >
                <Xmark
                  width={20}
                  height={20}
                  onClick={() => setCallWaringModal(false)}
                />
              </button>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                  style={{ marginBottom: "10px" }}
                >
                  거절하시겠습니까?
                </Col>
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                  style={{ marginBottom: "24px" }}
                >
                  거절하시면 프로필 다시 보기와 복구는 불가합니다.
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callapi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default RejectwaringPost;
