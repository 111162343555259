import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  adminRequestPost,
  adminUserPut,
  apiUrl,
  homeRequestGet,
  requestGet,
} from "../../apis/request";
import "./purcahse.scss";
//lch1721 현재 결제 페이지 
function Purchasecopy(props) {
  const [productId, setProductId] = useState("");
  const [stateName, setStateName] = useState("");
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState("");
  const [userCode, setUserCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailEnd, setEmailEnd] = useState("");
  const [bracketContent, setBracketContent] = useState("");
  const [otherContent, setOtherContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isCheck, setisCheck] = useState(false);
  const [isCheck1, setisCheck1] = useState(false);
  const [isCheck2, setisCheck2] = useState(false);
  const [talkFirst, setTalkFirst] = useState(false);
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [terms, setTerms] = useState("");
  const location = useLocation();
  const history = useHistory();
  const { id, uuid } = useParams();
  const { IMP } = window;
// 테스트
  const normalTerm = "사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준" +
"<br />" +
"<br />1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 환급 및 가입비의 10% 배상" +
"<br />2. 정보(프로필) 제공 후 만남일자 확정 전에해지 된 경우 : 가입비 환급 및 가입비의 15% 배상" +
"<br />3. 만남일자 확정 후에 해지된 경우 : 가입비 환급 및 가입비의 20% 배상" +
"<br />4. 1회 만남 후 해지된 경우 : 가입비*(잔여 횟수/총 횟수) +가입비의 20% 환급" +
"<br />" +
"<br />-사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준" +
"<br />" +
"<br />1. 회원가입계약 성립 후 정보(프로필)제공 전에해지된 경우 : 가입비 -상담비(5만원)-인증&프로필 교정비(5만원)" +
"<br />2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비-상담비(5만원) -인증&프로필 교정비(5만원)-소개비용" +
"<br />3. 만남일자 확정 후에 해지된 경우 : 가입비- 상담비(5만원) -인증&프로필 교정비(5만원) -만남1회 비용-소개비용" +
"<br />4. 1회 만남 후 해지된 경우 : (횟수제) 가입비 * (잔여횟수/총횟수) - 상담비(5만원) - 인증&프로필 교정비(5만원)-소개비용" +
"<br />" +
"<br />*횟수제의 경우 정보(프로필)제공 후 3개월마다 가입비의 5%씩  소개 비용이 차감되며 기간 내 횟수를 모두 차감하는 경우 " +
"<br />소개 비용은 발생하지 않습니다." +
"<br />소개비용  0-3개월 : 5%, 4-6개월  :10% , 7-9개월 : 15%, 10-12개월  :20%" ;
  const subscribeTerm = "사업자(을)의 책임 있는 사유로 계약해제 및 해지 시 환급기준<br />" + 
"<br />" + 
" 1. 회원가입계약 성립 후 정보(프로필) 제공 전에 해지된 경우 : 가입비 환급 및 가입비의 10% 배상<br />" + 
" 2. 정보(프로필) 제공 후 만남일자 확정 전에해지 된 경우 : 가입비 환급 및 가입비의 15% 배상<br />" + 
" 3. 만남일자 확정 후에 해지된 경우 : 가입비 환급 및 가입비의 20% 배상<br />" + 
" 4. 1회 만남 후 해지된 경우 : 가입비*(잔여일수/총일수) +가입비의 20% 환급<br />" + 
"<br />" + 
"<br />" + 
"사업자(을)의 책임 없는 사유로 계약해제 및 해지 시 환급기준<br />" + 
"<br />" + 
" 1. 회원가입계약 성립 후 인증& 프로필제공 전에 해지된 경우 : 가입비 -상담비(5만원 )-인증&프로필 교정비(5만원)<br />" + 
" 2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된 경우 : 가입비 * (잔여일수/총일수) - 상담비5만원) - 인증&프로필 교정비(5만원)<br />" + 
" 3. 만남일자 확정 후에 해지된 경우 : 가입비 * (잔여일수/총일수) - 상담비(5만원) - 인증&프로필 교정비(5만원)<br />" + 
" 4. 1회 만남 후 해지된 경우 :  가입비 * (잔여일수/총일수) - 상담비(5만원) - 인증&프로필 교정비(5만원)<br />";

  useEffect(() => {
    if (location.state) {
      setProductId(location?.state?.productId);
      setStateName(location?.state?.name);
      setAmount(location?.state?.amount);
      setDesc(location?.state?.desc);
    }
  }, [location]);

  useEffect(() => {
    if (desc) {
      const match = desc.match(/\[(.*?)\]/);
      const bracketContent = match
        ? match[0].replace("[", "").replace("]", "")
        : ""; // 대괄호 내용
      // const otherContent = desc
      //   .replace(bracketContent, "")
      //   .trim()
      //   .replace("[", "")
      //   .replace("]", "");
      let otherContent = desc
              .replace(bracketContent, "")
              .trim()
              .replace("[", "")
              .replace("]", "");
      if(otherContent === "-"){
        otherContent = "";
      }
      setBracketContent(bracketContent);
      setOtherContent(otherContent);
    }
  }, [desc]);

  const callApi = async () => {
    const response = await requestGet(`/api/product/list`);
    setData(response?.data);
  };

  useEffect(() => {
    IMP.init("imp75032535");
    // callApi();//이창희 uuid를 이용한 api 호출에서 데이터를 세팅한다.
    if (!location?.state?.amount && !id) {
      // history.replace("/products");
      history.replace("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (id) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].id === parseInt(id)) {
          setProductData(data[i]);
        }
      }
    }
  }, [id, data]);

  const waitApi = () => {
    if (talkFirst) {
      if (
        window.confirm(`
        이름 : ${productData.name}
        가격 : ${String(productData.price).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}원
        상품을 안내받으셨습니다.
        계속 진행 하시겠습니까?`)
      ) {
        setTalkFirst(false);
      } else {
        history.push("/dashboard");
      }
    }
  };

  useEffect(() => {
    if (productData) {
      setProductId(productData?.id);
      setStateName(productData?.name);
      setAmount(productData?.price);
      setDesc(productData?.desc);
      waitApi();
    }
  }, [productData]);

  const callInfp = useCallback(async () => {
    setTerms(normalTerm);
    try {
      const response = await requestGet(`/api/purchase/info/${uuid}`);
      setName(response?.data?.name);
      setPhone(response?.data?.phone);
      setUserId(response?.data?.userId);
      //0630 이창희 uuid를 통한 호출해서 데이터를 세팅한다.
      if (
        response?.data?.productId &&
        response?.data?.productName &&
        response?.data?.price
      ) {
        let data = {
          id: response?.data?.productId,
          name: response?.data?.productName,
          price: response?.data?.price,
          desc: response?.data?.desc,
          productType : response?.data?.productType,
        };
        if("subscribe" === response?.data?.productType){
          setTerms(subscribeTerm);
        }
        // setProductId(data.id);
        // setStateName(data.name);
        // setAmount(data.price);
        setTalkFirst(true);
        setProductData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      callInfp();
    }
  }, [callInfp, uuid]);

  const onclickPay = async () => {
    if (isCheck && isCheck2 && name && phone && email && emailEnd) {
      let body = {
        productId: productId,
        orderer: name,
        phone: phone,
        email: `${email}@${emailEnd}`,
      };
      const response = await adminRequestPost(`/api/purchase/request`, body);
      if (response.merchantId) {
        // const { IMP } = window;
        // IMP.init("imp75032535");
        const data = {
          // param
          pg: "html5_inicis.MOI1607854", //PG사구분코드.{사이트코드},
          pay_method: "card",
          merchant_uid: response.merchantId,
          name: stateName,
          amount: amount,
          buyer_email: `${email}@${emailEnd}`,
          buyer_name: name,
          buyer_tel: phone,
          m_redirect_url: "https://www.coincidence.co.kr/purchasewait",
        };
        IMP.request_pay(data, (rsp) => {
          // callback
          if (rsp.success) {
            adminUserPut(`/api/purchase/complete`, rsp);
            alert("결제 하였습니다.");
            history.replace("/dashboard");
          } else {
            alert("결제 실패 하였습니다.");
            console.log(rsp);
          }
        });
      }
    } else if (!isCheck) {
      return alert("구매 이용 약관에 동의해 주세요.");
    } else if (!isCheck2) {
      return alert("개인정보 수집 및 이용에 대한 안내에 동의해 주세요.");
    } else if (!name) {
      return alert("주문자 입력은 필수 입니다.");
    } else if (!phone) {
      return alert("핸드폰 번호 입력은 필수 입니다.");
    } else if (!email) {
      return alert("이메일 입력은 필수 입니다.");
    } else if (!emailEnd) {
      return alert("이메일 입력은 필수 입니다.");
    }
  };

  const onclickkakaoPay = async () => {
    if (isCheck && isCheck2 && name && phone && email && emailEnd) {
      let body = {
        productId: productId,
        orderer: name,
        phone: phone,
        email: `${email}@${emailEnd}`,
      };
      const response = await adminRequestPost(`/api/purchase/request`, body);
      if (response.merchantId) {
        // const { IMP } = window;
        // IMP.init("imp75032535");
        const data = {
          // param
          pg: "kakaopay", //PG사구분코드.{사이트코드},
          pay_method: "card",
          merchant_uid: response.merchantId,
          name: stateName,
          amount: amount,
          buyer_email: email,
          buyer_name: name,
          buyer_tel: phone,
          m_redirect_url: "https://www.coincidence.co.kr/purchasewait",
        };
        IMP.request_pay(data, (rsp) => {
          // callback
          if (rsp.success) {
            adminUserPut(`/api/purchase/complete`, rsp);
            alert("결제 하였습니다.");
            history.replace("/dashboard");
          } else {
            alert("결제 실패 하였습니다.");
            console.log(rsp);
          }
        });
      }
    } else if (!isCheck) {
      return alert("구매 이용 약관에 동의해 주세요.");
    } else if (!isCheck2) {
      return alert("개인정보 수집 및 이용에 대한 안내에 동의해 주세요.");
    } else if (!name) {
      return alert("주문자 입력은 필수 입니다.");
    } else if (!phone) {
      return alert("핸드폰 번호 입력은 필수 입니다.");
    } else if (!email) {
      return alert("이메일 입력은 필수 입니다.");
    } else if (!emailEnd) {
      return alert("이메일 입력은 필수 입니다.");
    }
  };

  const onclickdaouPay = async () => {
    if (isCheck && isCheck2 && name && phone && email && emailEnd) {
      let body = {
        productId: productId,
        orderer: name,
        phone: phone,
        email: `${email}@${emailEnd}`,
      };
      const response = await adminRequestPost(`/api/purchase/request`, body);
      if (response.merchantId) {
        // const { IMP } = window;
        // IMP.init("imp75032535");
        const data = {
          // param
          pg: "daou.CTS17362", //PG사구분코드.{사이트코드},
          pay_method: "card",
          merchant_uid: response.merchantId,
          //   escrow: false,
          amount: amount,
          name: stateName,
          buyer_name: name,
          buyer_email: email,
          buyer_tel: phone,
          //   digital: false,
          m_redirect_url: "https://www.coincidence.co.kr/purchasewait",
          bypass: {
            daou: {
              PRODUCTCODE: "iamport",
              CASHRECEIPTFLAG: 1,
            },
          },
        };
        IMP.request_pay(data, (rsp) => {
          // callback
          if (rsp.success) {
            adminUserPut(`/api/purchase/complete`, rsp);
            alert("결제 하였습니다.");
            history.replace("/dashboard");
          } else {
            alert("결제 실패 하였습니다.");
            console.log(rsp);
          }
        });
      }
    } else if (!isCheck) {
      return alert("구매 이용 약관에 동의해 주세요.");
    } else if (!isCheck2) {
      return alert("개인정보 수집 및 이용에 대한 안내에 동의해 주세요.");
    } else if (!name) {
      return alert("주문자 입력은 필수 입니다.");
    } else if (!phone) {
      return alert("핸드폰 번호 입력은 필수 입니다.");
    } else if (!email) {
      return alert("이메일 입력은 필수 입니다.");
    } else if (!emailEnd) {
      return alert("이메일 입력은 필수 입니다.");
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  const toggle1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pg.innopay.co.kr/ipay/js/innopay-epay.js";
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const requestPay = (data) => {
    if (window.innopay && window.innopay.goPay) {
      try {
        window.innopay.goPay({
          PayMethod: "CARD",
          // MID: "testpay01m",
          MID: "pguyeon01m",
          // MerchantKey:
          //   "Ma29gyAFhvv/+e4/AHpV6pISQIvSKziLIbrNoXPbRS5nfTx2DOs8OJve+NzwyoaQ8p9Uy1AN4S1I0Um5v7oNUg==",
          MerchantKey:
            "K9IMIrtWYCnmKWiZxI3Z8m5OfxGagv5S3x1jWB7mH3fL87owPBS94Xnwjs9lXHmIoHda73HiBRoacL6VaRL6cw==",
          GoodsName: stateName,
          Amt: String(amount),
          BuyerName: name,
          BuyerTel: phone,
          BuyerEmail: `${email}@${emailEnd}`,
          ResultYN: "Y",
          Moid: data.moid,
        });
      } catch (error) {
        console.error("Error during payment initiation:", error);
      }
    } else {
      console.error(
        "Innopay script not loaded yet or innopay object not available."
      );
    }
  };

  const preparePay = () => {
    if (isCheck && isCheck2 && name && phone && email && emailEnd) {
      fetch(`${apiUrl}/api/purchase/innopay/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId: productId,
          orderer: name,
          phone: phone,
          userId: userId,
          email: `${email}@${emailEnd}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          requestPay(data);
        })
        .catch((error) => {
          console.log(error);
          alert("구매 실패");
        });
    } else if (!isCheck) {
      return alert("구매 이용 약관에 동의해 주세요.");
    } else if (!isCheck2) {
      return alert("개인정보 수집 및 이용에 대한 안내에 동의해 주세요.");
    } else if (!name) {
      return alert("주문자 입력은 필수 입니다.");
    } else if (!phone) {
      return alert("핸드폰 번호 입력은 필수 입니다.");
    } else if (!email) {
      return alert("이메일 입력은 필수 입니다.");
    } else if (!emailEnd) {
      return alert("이메일 입력은 필수 입니다.");
    }
  };

  const payVerify = (data) => {
    fetch(`${apiUrl}/api/purchase/innopay/complete`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        history.replace("/dashboard");
      })
      .catch((error) => {
        alert("결제 처리 실패");
      });
  };

  window.innopay_result = (data) => {
    var resultcode = data.ResultCode; // 결과코드(PG)
    var resultmsg = data.ResultMsg; // 결과메세지(PG)
    payVerify(data);
    alert("[" + resultcode + "]" + resultmsg);
  };

  const onEmailEnd = (e) => {
    setEmailEnd(e.target.value);
  };

  useEffect(() => {
    document.title = "결제 | 우리의 인연";
  }, []);

  return (
    <React.Fragment>
      {/* {talkFirst ? (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            backgroundColor: "#fff",
            zIndex: 55555,
          }}
        />
      ) : ( */}
      <div className="page-content customWrap">
        <Container className="purchase-container">
          <h1 className="purTitle">구매 이용 약관</h1>
          <Row>
            <Col lg="8" className="purTableWrap">
              <Row>
                <Col xs="12">
                  <textarea
                    readOnly
                    className="purCustomTextarea"
                    rows={5}
                    style={{ padding: "10px" }}
                  >
                    {`제 1 조 (목적)

이 약관 및 표준계약서(붙임)은 우연(국내결혼중개업자, 이하 “회사”라 함)가
제공하는 국내결혼 관련 중개서비스(이하 “서비스”라 함)를 이용함에 있어
회사와 회원 사이의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.

제 2 조 (정의)

① “회원”이라 함은 제3조에서 정한 가입절차에 따라
회사가 그 가입신청을 승낙하고 이 약관에 따라
계약을 체결한 사람을 말합니다.

② “소개”라 함은 회사가 회원간에 결혼상대방을
구하기 위한 만남을 주선하는 것을 말합니다.

③ “교제”라 함은 회사의 소개로 만난 회원이 2회 이상
계속하여 만나는 것을 말합니다.

④ “결혼관련 개인정보”라 함은 학력, 직업,
병력 등 통상 결혼함에 있어 당사자 사이에 확인할
필요가 있는 것으로 인정되는 개인정보를 말합니다.

제 3 조 (회원가입)

① 회원이 되려고 하는 사람은 결혼관련 개인정보를
회사에 제공한 후 회사가 정한 가입절차에 따라
회원가입을 신청합니다.

② 회사는 제1항과 같이 회원가입을 신청한 자 중 다음
각 호의 사항에 대한 심사를 실시하여 그 적격 여부를
판단합니다.

  1. 배우자(사실혼관계의 배우자 포함) 있는 자인지 여부의 확인
  2. 결혼관련 개인정보의 사실여부의 확인

③ 회사는 제2항의 심사결과 적격자로 인정한
신청자에 한하여 회원가입을 승낙하고, 이 약관에
따라 계약을 체결합니다.

제 4 조 (계약서·약관의 명시·교부 등)

① 회사는 계약 체결시 이 약관의 내용을 회원으로
가입하려는 자에게 설명하고, 계약서와 함께 이
약관을 교부해야 합니다.

② 회사는 약관의 규제에 관한 법률, 소비자기본법 등
관련법령을 위배하지 않는 범위에서 이 약관을 개정할
수 있습니다.

③ 회사가 이 약관을 개정할 경우에는 적용일자,
개정사유 및 개정내용을 명시하여 현행약관과 함께 그
적용일자 30일 이전까지 제13조의 방법으로
예고합니다.

④ 회사가 약관을 개정할 경우에는 그
개정약관은 그 적용일자 이후에 체결되는 계약에만
적용되고 그 이전에 이미 체결된 계약에 대해서는
개정전의 약관조항이 그대로 적용됩니다. 다만, 이미
계약을 체결한 회원이 개정약관 조항의 적용을 받기를
원하는 뜻을 제3항에 의한 개정약관의 예고기간 내에
회사에 서면으로 통지하여 회사의 동의를 받은
경우에는 개정약관조항이 적용됩니다.

제 5 조 (서비스의 제공)

① 회사는 회원에게 다음과 같은 서비스를 제공합니다.

  1. 회원에 대한 결혼상담 및 인터넷 등을 통한
  결혼관련 정보의 제공

  2. 회원의 소개 및 이를 위한 행사 등의 개최

  3. 회원에 대한 결혼관련 개인정보의 관리

  4. 기타 결혼과 관련된 사항으로서 회사가 정하는
  서비스

② 회사는 서비스의 내용과 방법이 변경된
경우에는 회원의 동의가 없는 한 이 약관 변경의
방법에 의해서만 서비스의 내용과 방법을 변경할 수
있습니다.

제 6 조 (회원자격의 보유기간 등)

① 회원이 회사로부터 제5조에서 정한 서비스를
제공받을 수 있는 기간은 제7조 제1항에서 정한 계약
체결일로부터 12개월입니다. 다만, 회사가 제7조
제1항에서 정한 총횟수의 만남을 다한 경우에는 그
기간이 종료된 것으로 봅니다.

② 회사의 책임있는 사유로 회원에게 서비스를
제공하지 못하고 제1항 본문의 기간이 경과한 경우,
회원은 회사에 대하여 당해 기간만큼 회원자격
보유기간을 연장하여 줄 것과 나머지 횟수의 소개를
이행하여 줄 것을 청구하거나 계약을 해지할 수
있습니다.

제 7 조 (회원의 관리)

① 회원이 회사와 총횟수로 계약을 체결하는 경우에
회원은 회사의 소개로 앞면 표준계약서 2항의 만남을
갖습니다.

② 제7조 제1항에서 만남의 총횟수(횟수제) 또는
계약기간의 총일수(기간제)는 제11조의 가입비 환급의
기준이 됩니다. 회사는 회원에게 이성을 소개하는
경우 만남에 필요한 제반정보를 사전에 제공합니다.

③ 회원은 회사의 이성 소개에 대하여 2일전까지
만남을 보류할 것을 신청할 수 있습니다. 이때 회원은
이미 다른 회원과 교제 중이거나 본인의 입원, 출장
또는 가족의 사고나 사망 등 상대방과의 만남에 응 할
수 없는 불가피한 사정을 소명함으로써 만남횟수의
산입을 면할 수 있습니다.

④ 회원은 회사로부터 고유번호를 부여받아 회사가
제공하는 인터넷정보서비스를 이용할 수 있습니다.

⑤ 회원은 회사가 주최하는 각종 행사에 무료 또는
할인 혜택을 받아 참가할 수 있습니다.

⑥ 회원은 회원자격 보유기간 동안 회사가
제공하는 결혼관련 할인서비스를 받을 수 있습니다.

제 8 조 (회원의 의무)
  
① 회원은 회사와 가입계약체결 후 회사에 앞면
표준계약서 1항의 가입비를 납부하여야 합니다.

② 회원은 회사에 결혼관련 개인정보를 사실대로
제공하여야 합니다.

③ 회원은 회사에 제공한 결혼관련 개인정보에 변동이
있을 경우 15일 이내에 회사에 이를 통지하여야
합니다.

④ 회원은 회사와 합의하여 회사가 소개한 상대방과의
만남에 응하여야 합니다. 다만, 제7조 제3항의
경우에는 그러하지 않습니다.

⑤ 회원이 회사의 소개로 교제를 시작한
경우에는 그로부터 1월 이내에 회사에 그 사실을
통지하여야 합 니다. 결혼하기로 확정한 경우에도
같습니다.

제 9 조 (회원의 인터넷정보서비스 이용시 주의의무)
  
① 회사가 제공하는 인터넷정보서비스 이용시 ID와
비밀번호에 관한 관리책임은 회원에게 있습니다.

② 회원은 자신의 ID 및 비밀번호를 제3자에게
이용하게 해서는 안됩니다.
③ 회원이 자신의 ID 및 비밀번호를
도난당하거나 제3자가 사용하고 있음을 인지한
경우에는 즉시 회사에 통지하고 회사의 안내가 있는
경우에는 그에 따라야 합니다.

제 10 조 (계약의 종료)
  
① 계약의 종료사유는 다음과 같습니다.

  1. 당사자에 의한 계약의 해지

  2. 제6조에서 정한 회원자격 보유기간의 경과(기간제)
  및 만남의 총횟수 이행완료(횟수제)

  3. 회원간의 결혼

  4. 회원의 사망, 회사의 파산 기타 계약의 목적을
  달성할 수 없는 경우

② 회사는 다음 각 호의 사유가 확인된 경우 회원에
대하여 최고하지 아니하고 계약을 해지할 수
있습니다.

  1. 회원이 위조 또는 변조된 서류를 제출한 경우.
  다만, 회원의 고의나 중과실이 없는 경우는
  제외합니다.

  2. (2006. 12. 22. 본호 삭제)

  3. 이성과 동거하고 있는 경우

  4. 회원이 제8조 제2항에 위반하여 허위의 정보를
  제공한 경우. 단, 다소 과장된 표현 등 경미한
  위반이나 사소한 사항을 누락한 경우는 제외합니다.

  5. 회원이 제8조 제4항에 위반하여 만남보류의
  신청없이 무단으로 2회 이상 상대방과의 만남에
  응하지 아니하거나, 만남보류를 신청하고 제출하는
  제7조 제3항의 소명자료가 허위인 경우

  6. 회원이 회사의 소개로 상대방과 만나거나
  교제하면서 사회통념상 상대방에게 모욕감이나 심한
  불쾌감을 주는 행위를 하여 상대방이 회사에 그에
  관하여 2회 이상 항의한 경우

③ 회사는 다음 각 호의 사유가 확인된 경우 회원에
대하여 2주간의 최고를 하고 회원이 재발방지를 위한
적절한 조치를 취하지 아니한 때에는 계약을 해지할
수 있습니다.

  1. 회사의 신용, 명예를 손상시키는 행위를 한 경우

  2. 회원이 법령 기타 이 약관에 위반되는 행위를 한
  경우
④ 회원은 언제든지 최고없이 계약을 해지할 수
있습니다.

제 11 조 (가입비의 환급)
  
① 회사의 책임 있는 사유로 계약이 해지되는 경우에는
다음과 같이 회원가입비를 환급합니다.(회사의
책임있는 사유는 소비자분쟁해결기준에 준함)

  1. 회원가입계약 성립 후 정보(프로필) 제공 전에
  해지된 경우: 회원가입비 +회원가입비의 10%

  2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된
  경우: 회원가입비 + 회원가입비의 15%

  3. 만남일자 확정 후에 해지된 경우: 회원가입비 +
  회원가입비의 20%

  4. 1회 만남 이후 해지된 경우:
  회원가입비×(잔여횟수/총횟수) + 회원가입비의 20%

② 회사의 책임없이 계약이 해지되는 경우에는 다음과
같이 회원가입비를 환급합니다.

  1. 회원가입계약 성립 후 정보(프로필) 제공 전에
  해지된 경우: 회원가입비의 90%

  2. 정보(프로필) 제공 후 만남일자 확정 전에 해지된
  경우: 회원가입비의 85%

  3. 만남일자 확정 후에 해지된 경우: 회원가입비의
  80%

  4. 1회 만남 이후 해지된 경우: 회원가입비의
  80%×(잔여횟수/총횟수)

③ 제6조 제2항에 의하여 회원의 자격기간이 연장되는
경우나 제10조 제1항 제2호, 제 3호의 사유로 계약이
종료된 경우에는 가입비를 환급하지 아니합니다.
④ 위 제1항, 제2항에 불구하고 제10조 제1항
제2호, 제3호 외의 사유로 계약이 종료된 경우 회원은
회사의 동의를 얻어 가입비를 환급받지 아니하고 그에
상응하는 회원자격을 타인으로 하여금 보유하게 할 수
있습니다. 다만, 타인은 제3조 제2항에서 정한
적격자에 해당하여야 합니다.

제 12 조 (개인정보의 보호)
  
① 회사는 회원에 관한 정보수집시 필요한 최소한의
결혼관련 개인정보를 수집합니다.

② 회사가 회원의 개인식별이 가능한 개인정보를
수집하는 때에는 반드시 당해 회원 또는
회원가입신청자의 동의를 받습니다.

③ 제공된 개인정보는 당해 회원의 동의없이 목적 외의
이용이나 제3자에게 제공할 수 없으며, 이에 대한
모든 책임은 회사가 집니다. 다만, 다음의 경우에는
예외로 합니다.

  1. 소개 등 회사업무에 필요한 최소한의 회원의
  정보(성명, 주소, 전화번호)를 알려주는 경우

  2. 통계작성, 학술연구 또는 시장조사를 위하여
  필요한 경우로서 특정 개인을 식별할 수 없는 형태로
  제공하는 경우

④ 회사가 제2항과 제3항에 의해 회원의 동의를 받아야
하는 경우에는 정보의 수집목적 및 이용목적, 제
3자에 대한 정보제공 관련사항(제공받는자, 제공목적
및 제공할 정보의 내용)등을 미리 명시하거나
통지하여야 하며 회원은 언제든지 이 동의를 철회할
수 있습니다.

⑤ 회원은 언제든지 회사가 가지고 있는 자신의
개인정보에 대해 열람 및 오류정정을 요구할 수
있으며 회사는 이에 대해 지체없이 필요한 조치를
취할 의무를 집니다. 이용자가 오류의 정정을 요구한
경우에 는 회사는 그 오류를 정정할 때까지 당해
개인정보를 이용하지 않습니다.

⑥ 회사는 개인정보 보호를 위하여 노력하며
개인정보의 분실, 도난, 유출, 변조 등으로 인한
회원의 손해에 대하여 모든 책임을 집니다.
⑦ 회사 또는 그로부터 개인정보를 제공받은
제3자는 계약의 해지 기타 개인정보의 수집목적 또는
제공받은 목적을 달성한 때에는 당해 개인정보를
지체없이 파기합니다.

제 13 조 (회원에 대한 통지)
  
회원에 대한 통지 및 예고는 서면, 전화, 모사전송
또는 전자우편(e-mail)의 방법으로 할 수 있습니다.

제 14 조 (약관의 해석)
  
이 약관에서 정하지 아니한 사항과 이 약관의 해석은
관계법령 및 상관례에 따릅니다.

제 15 조 (분쟁해결)
  
① 회사는 회원으로부터 제출되는 불만사항 및 의견은
우선적으로 그 사항을 처리합니다. 다만, 신속한
처리가 곤란한 경우에는 회원에게 그 사유와
처리일정을 즉시 통지해 드립니다.

② 이 약관과 관련하여 당사자 사이에 다툼이
있는 경우에는 당사자의 협의로 처리하되 신의성실의
원칙에 따라 원만히 해결합니다.

제 16 조 (재판관할 및 준거법)
  
① 이 약관과 관련하여 회사와 회원간에 발생한 분쟁에
관한 소송은 민사소송법상의 관할법원에 제기합니다.

② 이 약관과 관련하여 회사와 회원간에 제기된
소송에는 한국법을 적용합니다. 20211008
`}
                  </textarea>
                </Col>
                <Col xs="12" className="purLabelWrap">
                  <input
                    type="checkbox"
                    className="purCheckbox"
                    id="purCheck1"
                    value={isCheck}
                    onClick={() => setisCheck(!isCheck)}
                  />
                  <label className="purLabel" htmlFor="purCheck1">
                    이용 약관에 동의합니다.
                  </label>
                </Col>
              </Row>
              {/* <Row>
                  <h1 className="purTitle">전자금융거래 이용약관</h1>
                  <Col xs="12">
                    <textarea readOnly className="purCustomTextarea" rows={5} />
                  </Col>
                  <Col xs="12" className="purLabelWrap">
                    <input
                      type="checkbox"
                      className="purCheckbox"
                      id="purCheck2"
                      value={isCheck1}
                      onClick={() => setisCheck1(!isCheck1)}
                    />
                    <label className="purLabel" htmlFor="purCheck2">
                      이용 약관에 동의합니다.
                    </label>
                  </Col>
                </Row> */}
              <Row>
                <h1 className="purTitle">개인정보 수집 및 이용에 대한 안내</h1>
                <Col xs="12">
                  <textarea
                    readOnly
                    className="purCustomTextarea"
                    rows={5}
                    style={{ padding: "10px" }}
                  >{`당사는 '개인정보보호법'에 따라 귀하의 개인정보를 다음과 같이 수집.이용하고자 합니다.

개인정보의 수집.이용 목적
- 회원관리 및 결혼서비스에 관한 상담 및 자료요청 의사 확인
- 결혼관련 서비스 상담 및 이용 권유, 각종 서비스 및 이벤트 안내
- 테스트 결과 도출 및 안내를 위한 정보수집
- 이벤트 참가신청, 참가가능여부, 당첨자발표, 진행사항에 대한 정보 전달

수집하는 개인정보의 항목
- 성명, 출생년도(또는 출생년월일), 성별, 연락처 및 휴대전화번호, 결혼경력, 이메일, 학력(또는 최종출신학교), 주거주지(또는 주소), 직업종류(또는 직장명)

개인정보의 보유 및 이용기간
- 결혼중개업법 기준 5년 또는 개인정보 삭제 및 탈회를 요청할 때까지 보유.이용합니다.
- 결혼회원 가입의 경우 개인정보에 관한 회사 내부 방침에 따라 개인정보를 보유합니다.
- 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 회사 내부 방침에 의한 정보보유 사유

-개인정보 삭제 및 회원탈퇴 신청기록
  보존 이유 : 부정 이용 방지
  보존 기간 : 우연 회원 탈퇴일부터 2년
  보존 항목 : 아이디, 이름, 출생년도, 이메일

-채용에 관한 입사지원 기록
  보존 이유 : 상시 채용 및 내부 인재풀 관리
  보존기간 : 최종 업데이트일부터 180일

나. 관련법령에 의한 정보보유 사유

상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

- 계약 또는 청약철회 등에 관한 기록
  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
  보존 기간 : 5년

- 대금결제 및 재화 등의 공급에 관한 기록
  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
  보존 기간 : 5년

- 전자금융 거래에 관한 기록
  보존 이유 : 전자금융거래법
  보존 기간 : 5년

- 소비자의 불만 또는 분쟁처리에 관한 기록
  보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
  보존 기간 : 3년

- 본인 확인에 관한 기록
  보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
  보존 기간 : 6개월

- 웹사이트 방문기록
  보존 이유 : 통신비밀보호법
  보존 기간 : 3개월


개인정보의 수집 및 이용에 대한 동의 거부 관련
- 당사는 서비스 제공에 필요한 최소한의 정보만을 수집·이용합니다.
- 이용자는 개인정보의 수집 및 이용 동의에 거부하실 수 있으며, 다만 이 경우 해당 서비스 이용 및 가입상담이 제한될 수 있습니다.`}</textarea>
                </Col>
                <Col xs="12" className="purLabelWrap">
                  <input
                    type="checkbox"
                    className="purCheckbox"
                    id="purCheck3"
                    value={isCheck2}
                    onClick={() => setisCheck2(!isCheck2)}
                  />
                  <label className="purLabel" htmlFor="purCheck3">
                    이용 약관에 동의합니다.
                  </label>
                </Col>
              </Row>
              <Row>
                <h1 className="purTitle">주문자 정보</h1>
                <Col xs="12">
                  <table className="purCustomTable">
                    <tbody>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">주문자</td>
                        <td className="purCustomTableTdInputWrap">
                          <input
                            type="text"
                            className="purCustomTableTdInput"
                            placeholder="주문자"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">핸드폰 번호</td>
                        <td className="purCustomTableTdInputWrap">
                          <input
                            type="text"
                            className="purCustomTableTdInput"
                            placeholder="핸드폰 번호"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </td>
                      </tr>
                      <tr className="purCustomTableTr">
                        <td className="purCustomTableTdName">이메일</td>
                        <td className="purCustomTableTdInputWrap">
                          <Row className="align-items-center">
                            <Col className="pe-0">
                              <input
                                type="text"
                                className="purCustomTableTdInputEmail w-100"
                                placeholder="이메일"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Col>
                            <span className="purCustomTableTdInput2 w-auto p-0">
                              @
                            </span>
                            <Col className="ps-0">
                              <input
                                type="text"
                                className="purCustomTableTdInputEmail w-100"
                                value={emailEnd}
                                onChange={(e) => setEmailEnd(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <Row className="align-items-center">
                            <Col>
                              <select
                                className="purCustomTableTdSelect"
                                onChange={onEmailEnd}
                              >
                                <option value="">직접입력</option>
                                <option value="naver.com">@naver.com</option>
                                <option value="gmail.com">@gmail.com</option>
                                <option value="daum.net">@daum.net</option>
                                <option value="hanmail.net">
                                  @hanmail.net
                                </option>
                                <option value="yahoo.com">@yahoo.com</option>
                                <option value="outlook.com">
                                  @outlook.com
                                </option>
                                <option value="nate.com">@nate.com</option>
                                <option value="kakao.com">@kakao.com</option>
                              </select>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
            <Col lg="4" className="furRightWrap">
              <div className="furRight">
                <div className="furRightInWrap">
                  <Row>
                    <Col className="furNameText">{stateName}</Col>
                  </Row>
                  <Row className="purcollapWrap">
                    <Collapse isOpen={true} className="purcard">
                      <Card>
                        <CardBody
                          style={{ maxHeight: "260px", overflow: "auto" }}
                        >
                          [제품설명]
                          <br />
                          {otherContent}
                          <br />
                          {bracketContent}
                          <br />
                          <br />
                          [환불규정]
                          <br />
                          {terms.split('<br />').map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Row>
                  <Row className="furRightMain">
                    <Col>
                      <span className="furRightMainTitle">총 결제 금액</span>
                      <span className="furRightVAT">(VAT 포함)</span>
                    </Col>
                    <Col className="text-end">
                      <span className="furRightPrice">
                        {amount
                          ? String(amount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) + "원"
                          : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row className="purAgree">
                    <Col>위 내용을 확인하였고 결제에 동의합니다.</Col>
                  </Row>
                  <Row>
                    <button
                      className="purBtn d-flex align-items-center justify-content-center blackColor"
                      onClick={() => preparePay()}
                    >
                      결제하기
                    </button>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  );
}

export default Purchasecopy;
