import React from "react";
import { Row } from "reactstrap";

//Import Breadcrumb

function Education({ handleChange, handleOneCheck, SelectFn }) {
  const selOption = [
    {
      name: "최종학력",
      key: "finaledu",
      type: "radio",
      value: [
        "고졸",
        "전문대 재학",
        "전문대 수료",
        "전문대 졸업",
        "대학 재학",
        "대학 중퇴",
        "대학 수료",
        "대학 졸업",
        "대학원 재학",
        "대학원졸업",
        "박사 과정",
        "박사 취득",
      ],
    },
    {
      name: "대학명(학사)",
      key: "hakname",
      type: "text",
      description: "* 캠퍼스의 경우 (캠퍼스)라고 작성해주세요.",
    },
    {
      name: "졸업년도(학사)",
      key: "hakenddate",
      type: "number",
    },
    {
      name: "전공(학사)",
      key: "hakskill",
      type: "text",
    },
    {
      name: "대학원명(석,박사)",
      key: "sukname",
      type: "text",
    },
    {
      name: "졸업년도(석,박사)",
      key: "sukenddate",
      type: "number",
    },
    {
      name: "전공(석,박사)",
      key: "sukskill",
      type: "text",
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">학력</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
            description: el.description,
          });
        } else if (el.type === "number") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Education;
