import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Privacy from "./Privacy";
import "./total.scss";
import Privacy1 from "./Privacy1";
import Privacy2 from "./Privacy2";
import Privacy3 from "./Privacy3";
import { useParams } from "react-router-dom";

function Total(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const { page } = useParams();
  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 10) {
        var modifiedSteps = [...passedSteps, tab];
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (page) {
      toggleTab(parseInt(page));
    }
  }, [page]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12">
              <Nav
                tabs
                style={{
                  borderRadius: "0.25rem",
                  justifyContent: "space-around",
                }}
              >
                <NavItem
                  className={activeTab === 1 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(1);
                    }}
                  >
                    이용약관
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 2 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(2);
                    }}
                  >
                    개인정보처리방침
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 3 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(3);
                    }}
                  >
                    국내결혼중개 표준약관
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 4 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(4);
                    }}
                  >
                    손해배상 청구절차
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Privacy />
                </TabPane>
                <TabPane tabId={2}>
                  <Privacy1 />
                </TabPane>
                <TabPane tabId={3}>
                  <Privacy2 />
                </TabPane>
                <TabPane tabId={4}>
                  <Privacy3 />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Total;
