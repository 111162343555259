import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";
import "./purcahsecopy.scss";

function Productscopycopy(props) {
  const [nomalData, setNomalData] = useState([]);
  const [earlyData, setEarlyData] = useState([]);
  const [subData, setSubData] = useState([]);

  const history = useHistory();

  const callApi = async () => {
    const response = await requestGet(`/api/product/list`);
    let arr = [];
    let arr1 = [];
    let arr2 = [];
    if (response?.data) {
      response.data.forEach((item) => {
        if (item.type === "subscribe") {
          arr2.push(item);
        } else if (item.type === "joinFee") {
          if (item.name.includes("얼리버드")) {
            arr.push(item);
          } else {
            arr1.push(item);
          }
        }
      });
    }
    setEarlyData(arr);
    setNomalData(arr1);
    setSubData(arr2);
  };

  useEffect(() => {
    callApi();
  }, []);

  const goPurchase = (item) => {
    history.push({
      pathname: `/purchase`,
      state: {
        productId: item.id,
        name: item.name,
        amount: item.price,
        desc: item.desc,
      },
    });
  };

  useEffect(() => {
    document.title = "서비스 안내 | 우리의 인연";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content customWrap">
        <Container>
          <h1 className="purMainTitle">서비스 안내</h1>
          {subData.length > 0 ? (
            <Row>
              <Col className="purSubTitle">기간제(기간 내 횟수 제한 없이 만남 가능)</Col>
            </Row>
          ) : null}
          <Row>
            {subData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              let otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "\n")
                .replace("[", "")
                .replace("]", "");
                if(otherContent === "-"){
                  otherContent = "\n\n";
                }
                
              return (
                <Col lg={4} key={i}>
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemType">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemName27">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                      </Col>
                    </Row>
                    <Row>
                      <span className="purCardItemVAT">VAT 포함</span>
                    </Row>
                    <Row>
                      <span className="purCardItemDescSubscribe">{otherContent  || '\u00A0'}</span>
                    </Row>
                    {/* <Row>
                      <Col className="purCardItemSubDesc">{bracketContent}</Col>
                    </Row> */}
                  </Card>
                </Col>
              );
            })}
          </Row>
          {nomalData.length > 0 ? (
            <Row className="mt131">
              <Col className="purSubTitle">횟수제</Col>
            </Row>
          ) : null}
          <Row>
            {nomalData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              let otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "\n")
                .replace("[", "")
                .replace("]", "");
              if(otherContent === "-"){
                otherContent = "\n\n";
              }
              return (
                <Col lg={4} key={i}>
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemType">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemName27">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                      </Col>
                    </Row>
                    <Row>
                      <span className="purCardItemVAT">VAT 포함</span>
                    </Row>
                    <Row>
                      <span className="purCardItemDescNormal">{otherContent}</span>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Productscopycopy;
