import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ceoMO from "./ceoMO1.jpg";
import ceoPC from "./ceoPC.jpg";
import "../Dashboard/dashboard.scss";

const CEOIntroduce = (props) => {
  const [currentImg, setCurrentImg] = useState(window.innerWidth);
  const [containerWidth, setContainerWidth] = useState(0);
  const imgRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };

    // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 초기 로딩 시 화면 너비 체크
    handleResize();
    document.title = "우연은? | CEO 인사말";

    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (imgRef.current) {
      setContainerWidth(imgRef.current.offsetWidth);
    }
  }, [currentImg]);

  // 이미지가 로드된 후에 크기 설정
  const handleImageLoad = () => {
    if (imgRef.current) {
      setContainerWidth(imgRef.current.offsetWidth);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          className="container d-flex justify-content-center"
          style={{ paddingTop: "20px" }}
        >
          {currentImg > 991 ? (
            <div
              className="d-flex flex-column align-items-start ceo-content"
              style={{ width: containerWidth }}
            >
              <img
                alt="mainpage"
                src={ceoPC}
                className="ceo-image"
                ref={imgRef}
                style={{ marginBottom: "45px" }}
                onLoad={handleImageLoad}
              />
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                결혼은 한 사람의 인생에서 가장 중요한 행사라고 생각합니다.
                새로운 ‘가정’을 이루고 미래를 이어갈 자녀를 낳아 가족이 되는
                결혼을 통해, 우리는 행복해지기도 하고 반대로 가장 불행해지기도
                합니다. 그래서 결혼은 ‘인생에서 가장 큰 숙제'라고 불릴 만큼
                심사숙고해야 할 중요한 선택입니다.
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                한때 저는 인생의 이 중요한 숙제를 위해 전문가들의 도움을 받고자
                여러 결혼정보 회사에 고액의 비용을 들였습니다. 하지만 그러고
                나니 이들이 정말 내 결혼 성사에 의지가 있는 것인지, 아니면
                결혼을 빌미로 매출 증대만 꾀하는 것인지 의문이 들었습니다.
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                단순히 매칭 횟수를 늘리고자 회원의 동의 없이 사진을 조작하거나
                키를 늘리는 행위, 무의미한 미차감 만남, 저를 만나본 적도 없는
                매니저가 내 평생의 반려자를 찾아주는 시스템과 불합리한 환불
                정책까지. 사람의 진심과 진실성이 가장 필요한 곳에서 일어나서는
                안될 많은 일들이 성행하는 것을 보고 저는 다짐했습니다.
              </div>
              <div
                className="ceo-web-text-border w-100"
                style={{ fontFamily: "Pretendard" }}
              >
                '매출이 아닌 회원들의 성혼만을 목표로 하는
                <br />
                정직한 결혼정보 회사를 직접 만들자'
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                그 결심을 실현하기 위해 우연, 우리의 인연을 설립했습니다. 우연은
                평균 나이 30대의 젊고 전문성을 갖춘 임직원들이 고객님의 눈높이에
                맞춰 여러분의 소중한 인연을 찾아드리겠습니다.
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                우연은 구독자 90만 명을 보유한 연애 분야 1위 유튜버 김달님의
                전문적인 노하우를 바탕으로 운영됩니다. 김달님은 우연의 기획과
                개발 그리고 운영에 참여하여 고객님들의 행복한 결혼 성사를 위해
                최선을 다할 것입니다.
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard" }}
              >
                우연을 통해 여러분들의 결혼이 인생의 ‘숙제’가 아닌 인생의
                ‘축제’가 되기를 진심으로 기원합니다.
              </div>
              <div
                className="ceo-web-text"
                style={{ fontFamily: "Pretendard", marginBottom: "120px" }}
              >
                감사합니다.
              </div>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-start ceo-content"
              style={{ width: "100%", padding: "16px" }}
            >
              <img
                alt="mainpage"
                src={ceoMO}
                className="ceo-image"
                ref={imgRef}
                style={{
                  maxWidth: "100%",
                  marginBottom: "25px",
                }}
                onLoad={handleImageLoad}
              />
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                결혼은 한 사람의 인생에서 가장 중요한 행사라고 생각합니다.
                새로운 ‘가정’을 이루고 미래를 이어갈 자녀를 낳아 가족이 되는
                결혼을 통해, 우리는 행복해지기도 하고 반대로 가장 불행해지기도
                합니다. 그래서 결혼은 ‘인생에서 가장 큰 숙제'라고 불릴 만큼
                심사숙고해야 할 중요한 선택입니다.
              </div>
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                한때 저는 인생의 이 중요한 숙제를 위해 전문가들의 도움을 받고자
                여러 결혼정보 회사에 고액의 비용을 들였습니다. 하지만 그러고
                나니 이들이 정말 내 결혼 성사에 의지가 있는 것인지, 아니면
                결혼을 빌미로 매출 증대만 꾀하는 것인지 의문이 들었습니다.
              </div>
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                단순히 매칭 횟수를 늘리고자 회원의 동의 없이 사진을 조작하거나
                키를 늘리는 행위, 무의미한 미차감 만남, 저를 만나본 적도 없는
                매니저가 내 평생의 반려자를 찾아주는 시스템과 불합리한 환불
                정책까지. 사람의 진심과 진실성이 가장 필요한 곳에서 일어나서는
                안될 많은 일들이 성행하는 것을 보고 저는 다짐했습니다.
              </div>
              <div
                className="ceo-text-border w-100"
                style={{ fontFamily: "Pretendard" }}
              >
                '매출이 아닌 회원들의 성혼만을 목표로 하는
                <br />
                정직한 결혼정보 회사를 직접 만들자'
              </div>
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                그 결심을 실현하기 위해 우연, 우리의 인연을 설립했습니다. 우연은
                평균 나이 30대의 젊고 전문성을 갖춘 임직원들이 고객님의 눈높이에
                맞춰 여러분의 소중한 인연을 찾아드리겠습니다.
              </div>
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                우연은 구독자 90만 명을 보유한 연애 분야 1위 유튜버 김달님의
                전문적인 노하우를 바탕으로 운영됩니다. 김달님은 우연의 기획과
                개발 그리고 운영에 참여하여 고객님들의 행복한 결혼 성사를 위해
                최선을 다할 것입니다.
              </div>
              <div className="ceo-text" style={{ fontFamily: "Pretendard" }}>
                우연을 통해 여러분들의 결혼이 인생의 ‘숙제’가 아닌 인생의
                ‘축제’가 되기를 진심으로 기원합니다.
              </div>
              <div
                className="ceo-text"
                style={{ fontFamily: "Pretendard", marginBottom: "50px" }}
              >
                감사합니다.
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

CEOIntroduce.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CEOIntroduce);
