import React from "react";
import { Row, Col } from "reactstrap";

function Privacy3(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12">
              <tbody>
                <tr>
                  <td>
                    <h3 className="my-3">
                      보증보험금(예치금) 지급사유 및 청구절차 개시
                    </h3>
                    <div style={{ lineHeight: "37px" }}>
                      결혼중개업의 관리에 관한 법률 시행령
                      <br />
                      [시행 2020. 3. 3.] [대통령령 제30509호, 2020. 3. 3.,
                      타법개정]
                      <br />
                      <br />
                      제7조(보증보험금 지급)
                      <br />
                      <br />
                      ① 법 제25조제3항에 따라 결혼중개와 관련하여 손해를 입은
                      이용자는 결혼중개업자가 손해배상을 하지 아니한 경우에는
                      보증보험금의 지급을 청구할 수 있다. &lt;개정 2010. 11.
                      16.&gt;
                      <br />
                      <br />
                      ② 이용자는 제1항에 따라 지급을 청구하려면 이용자와
                      결혼중개업자 간의 손해배상합의서(공정증서이어야 한다),
                      화해조서 또는 확정된 법원의 판결문 정본, 그 밖에 이에
                      준하는 효력이 있는 서류를 시장ㆍ군수ㆍ구청장에게
                      제출하여야 한다. &lt;개정 2010. 11. 16.&gt;
                      <br />
                      <br />③ 결혼중개업자는 보증보험금으로 해당 결혼중개와
                      관련된 손해를 배상한 경우에는 제6조제1항에 따른 금액을
                      보장할 수 있도록 15일 이내에 보증보험에 다시 가입하고,그
                      증명서류를 시장ㆍ군수ㆍ구청장에게 제출하여야 한다.
                      &lt;개정 2010. 11. 16.&gt;
                    </div>
                  </td>
                </tr>
              </tbody>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Privacy3;
