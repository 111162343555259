import React, { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useParams, Link } from "react-router-dom";

import { Col, Input, Label, Row } from "reactstrap";

import "../Consult/consult.scss";
import BackImg from "../../assets/img/others/Logo1.png";
import Rectangle from "../../assets/img/others/rectangle1.png";
import { adminRequestPost, adminUserPut, requestGet } from "../../apis/request";
import PremiumMadal from "./PremiumMadal";
import WaringModal from "./WaringModal";
import { ReactComponent as Right } from "../../assets/icon/svgs/solid/chevron-right.svg";
import { ReactComponent as Left } from "../../assets/icon/svgs/solid/chevron-left.svg";
import InfoModal from "./InfoModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NomalPost from "./NomalPost";
import RejectPost from "./RejectPost";

function UsersProfile(props) {
  const [values, setValues] = useState(null);
  const [editData, setEditData] = useState([
    {
      id: 10,
      name: "이**",
      birthday: "82",
      homeFull: "123",
      homeSido: "456",
      homeGugun: "서울 광진구",
      image:
        "https://dp68lan0fdg0l.cloudfront.net/image/2024/05/17/a220ea88-87bf-4fb7-a670-0f982c3c12fc.jpeg",
    },
    {
      id: 11,
      name: "김**",
      birthday: "94",
      homeFull: "ㅁㄴㅇ",
      homeSido: "ㅋㅌㅊ",
      homeGugun: "서울 중구",
      image:
        "https://dp68lan0fdg0l.cloudfront.net/image/2024/05/17/a220ea88-87bf-4fb7-a670-0f982c3c12fc.jpeg",
    },
  ]);
  const [callNomalModal, setCallNomalModal] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [callRejectModal, setCallRejectModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [waring, setWaring] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const sliderRef = useRef(null);

  const settings = {
    dots: false, // 하단 도트 표시 여부
    infinite: true, // 무한 반복 여부
    speed: 500, // 슬라이드 전환 속도
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 수
    autoplay: false, // 자동 슬라이드 여부
    autoplaySpeed: 2000, // 자동 슬라이드 인터벌
    adaptiveHeight: true, // 슬라이더 높이 자동 조절
    beforeChange: (current, next) => setCurrentIndex(next),
  };
  const { id, birth } = useParams();

  const history = useHistory();

  const callInfp = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      params.append("phone", id);
      params.append("birthday", birth);
      const response = await requestGet(`/api/introduce/my/list?${params}`);
      setValues(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [id]);

  useEffect(() => {
    callInfp();
  }, [callInfp, id]);

  useEffect(() => {
    const hideUntil = localStorage.getItem("hideUntil");
    if (hideUntil) {
      const now = new Date().getTime();
      const hideUntilTime = parseInt(hideUntil, 10);

      // 현재 시간이 저장된 'hideUntil' 시간보다 크다면 요소를 다시 보여줍니다.
      if (now > hideUntilTime) {
        setIsVisible(true);
        setWaring(false);
        localStorage.removeItem("hideUntil"); // 시간이 경과했으므로 'hideUntil' 항목을 제거합니다.
      } else {
        setIsVisible(false);
        setWaring(true);
      }
    }
  }, []);

  const postLike = async (message, type) => {
    if (window.confirm("호감을 보내시겠습니까?")) {
      try {
        let body = {
          uuid: id,
          birthday: birth,
          type: type,
          message: message,
        };
        await adminRequestPost(`/api/like`, body);
        alert("호감을 보냈습니다.");
        history.push("/dashboard");
      } catch (error) {
        alert(error?.data?.error);
      }
    }
  };

  const postReject = async () => {
    if (window.confirm("거절 하시겠습니까?")) {
      try {
        let body = {
          uuid: id,
          birthday: birth,
        };
        await adminUserPut(`/api/introduce/reject`, body);
        alert("거절 하였습니다.");
        history.push("/dashboard");
      } catch (error) {
        alert(error?.data?.error);
      }
    }
  };

  const premiumpostLike = () => {
    setCallModal(true);
  };
  const nomalpostLike = () => {
    setCallNomalModal(true);
  };
  const rejectpostLike = () => {
    setCallRejectModal(true);
  };

  useEffect(() => {
    if (values?.length > 0) {
      let arr = [];
      values.forEach((item) => {
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday,
          homeFull: item.homeFull,
          homeSido: item.homeSido,
          homeGugun: item.homeGugun,
          image: item.image,
          uuid: item.uuid,
        });
      });
      setEditData(arr);
    } else {
      setEditData([]);
    }
  }, [values]);

  // useEffect(() => {
  //   callInfp();
  // }, [callInfp, id]);

  const onInfoModa = (e) => {
    e.stopPropagation();
    setInfoModal(true);
  };

  useEffect(() => {
    if (containerRef.current) {
      const updateSize = () => {
        const width = containerRef.current.offsetWidth;
        setContainerSize({ width, height: width });
      };

      // Initial size calculation
      updateSize();

      // Recalculate size on window resize
      window.addEventListener("resize", updateSize);

      // Clean up the event listener
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [containerRef]);

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goProfile = (id) => {
    window.location.href = `/introduce/detail/${id}/${birth}`;
  };

  return (
    <React.Fragment>
      {waring ? (
        <div
          className="container d-flex"
          style={{ backgroundColor: "#F9F9F9", height: "100vh" }}
        >
          <img
            src={Rectangle}
            alt="top"
            style={{ position: "absolute", top: "0", left: "0", width: "100%" }}
          />
          <div
            className="d-flex flex-column w-100 justify-content-center"
            style={{ paddingBottom: "40px", zIndex: 1 }}
          >
            <div style={{ padding: "0px 38px" }}>
              <div style={{ position: "relative" }} ref={containerRef}>
                <Slider {...settings} ref={sliderRef}>
                  {editData?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="sliderCustomRadius"
                        id="sliderTotalWrap"
                      >
                        <div id="sliderWrap">
                          <div className="sliderInWrap">
                            <div className="sliderInCard">
                              <div className="sliderProfileimg">
                                <Link
                                  to={`/image?src=${encodeURIComponent(
                                    item.image
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="sliderimgWrap"
                                >
                                  <img
                                    alt={`Slide ${i + 1}`}
                                    src={item.image}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </Link>
                              </div>
                              <div className="sliderName">{item.name}님</div>
                              <div className="sliderBirthday">
                                {item.birthday}년생
                                <br />· {item.homeGugun}
                              </div>
                              <div
                                className="w-100"
                                style={{ padding: "0px 10px" }}
                              >
                                <button
                                  className="sliderCardBtn"
                                  onClick={() => goProfile(item.uuid)}
                                >
                                  프로필 보기
                                </button>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "12px",
                              }}
                            >
                              <button
                                onClick={goToPrev}
                                style={{ border: "none", background: "none" }}
                              >
                                <Left width={20} height={20} />
                                이전
                              </button>
                              <button
                                onClick={goToNext}
                                style={{ border: "none", background: "none" }}
                              >
                                다음
                                <Right width={20} height={20} />
                              </button>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {currentIndex + 1} / {editData?.length}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : isVisible ? (
        <WaringModal setWaring={setWaring} />
      ) : null}
    </React.Fragment>
  );
}

export default UsersProfile;
