import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";

import { ReactComponent as Frame } from "./Frame.svg";

function BottomModal({ setShowBottomModal, setShowPrompt }) {
  const history = useHistory();
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const onComplete = async () => {
    await setShowPrompt(false);
    history.push("/");
    window.location.reload();
    setShowBottomModal(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "24px 32px 30px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col lg={12} className="d-flex justify-content-between p-0 m-0">
                  <div
                    className="infoLeftText"
                    style={{
                      wordBreak: "keep-all",
                    }}
                  >
                    신청 완료되었습니다.
                    <br />
                    운영팀 검토 후 매칭 난이도 또는 내부 규정에 따라 보류/반려
                    될 수 있음을 알려드립니다.
                    <br />이 점 널리 양해 부탁드립니다. 감사합니다.
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="p-0">
                  <Button
                    id="primiumAccesBtn"
                    className="w-100"
                    style={{ borderRadius: "0px" }}
                    onClick={() => onComplete()}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default BottomModal;
