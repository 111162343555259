import React from "react";
import { Row, Col } from "reactstrap";

function Privacy1(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <Row>
            <Col className="col-12">
              <div
                role="document"
                tabindex="0"
                style={{
                  marginBottom: "64px",
                }}
              >
                <div>
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <h3 className="my-3">
                      우연 결혼정보서비스 개인정보 처리방침
                    </h3>
                    <p>(2024. 03. 25. 시행)</p>
                    <p>
                      {`<우연>`}은 개인정보보호법 제30조에 따라 정보주체의
                      개인정보 보호 및 권익을 보호하고 개인정보와
                    </p>
                    <p>
                      관련한 정보주체의 고충을 원활하게 처리할 수 있도록 다음과
                      같은 처리방침을 수립 공개하고
                    </p>
                    <p>있습니다</p>
                    <p></p>
                    <p>
                      개인정보란 생존하는 개인에 관한 정보로서 당해 정보에
                      포함되어 있는 성명, 주민등록번호 등의
                    </p>
                    <p>
                      사항에 의하여 당해 개인을 식별할 수 있는 정보(당해
                      정보만으로는 특정 개인을 식별할 수 없더라도
                    </p>
                    <p>
                      다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
                      말합니다. 우연은 회원의 개인정보보호를
                    </p>
                    <p>
                      매우 중요시하며, 정보통신망이용촉진등에 관한 법률상의
                      개인정보보호 규정 및 정보통신부가 제정한
                    </p>
                    <p>
                      개인정보보호지침을 준수하고 있습니다. 우연은는
                      개인정보보호법에 따라 이용자의 개인정보 보호 및
                    </p>
                    <p>
                      권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게
                      처리할 수 있도록 다음과 같은
                    </p>
                    <p>
                      처리방침을 두고 있습니다. 우연은는 개인정보 취급방침을
                      우연에서 운영하는
                    </p>
                    <p>
                      홈페이지(www.coincidence.co.kr) 및 동 사이트의 기본 DB를
                      공유하는 모든 첫 화면에 공개함으로써
                    </p>
                    <p>
                      귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있으며
                      개인정보 취급방침의 지속적인 개선을
                    </p>
                    <p>
                      위하여 개인정보 취급방침을 개정하는데 필요한 절차를 정하고
                      있습니다. 그리고 개인정보 취급방침을
                    </p>
                    <p>
                      개정하는 경우 개정된 사항을 귀하께서 쉽게 알아볼 수 있도록
                      하고 있습니다.
                    </p>
                    <p></p>
                    <h3 className="my-3">제1조. 개인정보 수집에 대한 동의</h3>
                    <p>
                      우연은 귀하께서 개인정보 취급방침 또는 이용약관의 내용에
                      대해 「동의한다」 또는「동의하지
                    </p>
                    <p>
                      않는다」를 선택할 수 있는 절차를 마련하여,
                      「동의한다」항목을 선택하고 서명한 경우에 개인정보
                    </p>
                    <p>수집에 대해 동의한 것으로 봅니다</p>
                    <p></p>
                    <h3 className="my-3">
                      제2조. 개인정보의 처리 목적 및 처리 항목
                    </h3>
                    <p>
                      1. 우연은 다음과 같은 목적을 위하여 개인정보를 수집하고
                      있습니다.
                    </p>
                    <p>가) 홈페이지 회원가입 및 관리</p>
                    <p>
                      회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                      식별·인증, 회원자격 유지·관리, 제한적
                    </p>
                    <p>
                      본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지,
                      만14세 미만 아동 개인정보 수집 시
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁
                      조정을 위한 기록 보존 등을 목적으로
                    </p>
                    <p>개인정보를 처리합니다.</p>
                    <p>나) 재화 또는 서비스 제공</p>
                    <p>
                      서비스 제공, 콘텐츠 제공 등을 목적으로 개인정보를
                      처리합니다.
                    </p>
                    <p>다) 민원사무 처리</p>
                    <p>
                      회원의 신원 확인, 민원 사항 확인, 사실조사를 위한
                      연락⦁통지, 처리결과 통보 목적으로 개인정보를
                    </p>
                    <p>처리합니다.</p>
                    <p>라) 마케팅 및 광고에의 활용</p>
                    <p>
                      신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및
                      광고성 정보 제공 및 참여기회 제공 ,
                    </p>
                    <p>
                      인구통계학적 특성에 따른 서비스 제공 및 광고 게재 ,
                      서비스의 유효성 확인, 접속빈도 파악 또는
                    </p>
                    <p>
                      회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를
                      처리합니다.
                    </p>
                    <p>마) 개인영상정보</p>
                    <p>
                      범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로
                      개인정보를 처리합니다
                    </p>
                    <p>
                      2) {`<우연>`}은 다음의 개인정보 항목을 처리하고 있습니다.
                    </p>
                    <p>가) 회원가입 및 관리</p>
                    <p>○ 필수항목 :</p>
                    <p>• 회원가입 시 필수항목</p>
                    <p>
                      - 성명, 회원아이디, 비밀번호, 주민등록번호, 이메일주소,
                      이메일수신여부, 전화번호, 주소,
                    </p>
                    <p>
                      결혼경력유무, 학력, 직장명, 직장주소(본인식별 및 본인의사
                      확인 등),암호화된 이용자 확인값(CI)
                    </p>
                    <p>• 서비스 이용 과정에서 추가 수집 정보</p>
                    <p>
                      - 재산 : 자가용유무, 본인가족소유부동산, 신장, 몸무게,
                      안경착용여부, 병역, 결혼예정일, 직업,
                    </p>
                    <p>
                      성격,학력, 외모, 가정환경, 연령, 주거유형, 소유여부,
                      잔고증명서 등 경제력을 증빙할 수 있는 일체
                    </p>
                    <p>
                      자료(국민건강보험 보험료 납부확인서, 자동차등록증 또는
                      자동차보험증권, 리스계약서 등)
                    </p>
                    <p>
                      - 지역 : 거주선호지역, 출신지역, 타지역허용여부,
                      출신배타지역, 상세거주지역
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      - 가족 : 부모동거, 시부모동거, 장남장녀여부, 양친생존여부,
                      자녀수용, 혼인경력, 재혼여부
                    </p>
                    <p>- 건강 : 건강상태, 신장, 흡연 유무, 혈액형, 체형</p>
                    <p>
                      - 취향 : 취미, 성격, 관심사, 상대희망조건, 스타일, 설문,
                      여행지
                    </p>
                    <p>- 사진 : 프로필 사진, 사진공개여부, 셀프인터뷰 여부</p>
                    <p>○ 선택항목</p>
                    <p>- 종교</p>
                    <p>나) 서비스의 이행</p>
                    <p>
                      {`<우연>`}은 서비스 이행을 위하여 귀하의 프로필, 사진 등
                      타 회원에게 제공될 수 있습니다(프로필소개
                    </p>
                    <p>등 안내자료발송 및 미팅 진행 등).</p>
                    <p>- 서비스별 회원간 정보공개 내역</p>
                    <p>
                      * 기본프로필 : 성, 생년, 거주지주소(시/구/동), 생년월일,
                      형제관계, 혼인경력, 관심취미,
                    </p>
                    <p>
                      본인작성,성격, 신장, 혈액형, 종교, 음주여부, 학력,
                      직장업종/부서/직위, 가족관계
                    </p>
                    <p>
                      * 상세/확정프로필 : 성명, 거주지주소(시/구/동), 본적지,
                      생년월일, 주거형태, 형제관계,
                    </p>
                    <p>
                      혼인경력,전화번호, 이메일주소, 관심취미, 본인작성성격,
                      신장, 혈액형, 종교, 음주여부, 세부학력,
                    </p>
                    <p>
                      세부직장업종/부서/직위, 년수입, 고용형태, 가족관계, 사진,
                      본인작성PR
                    </p>
                    <p>
                      3. 회원의 기본적 인권 침해의 우려가 있는 개인 민감 정보 중
                      종교를 제외한 인종 및 민족, 사상 및
                    </p>
                    <p>
                      신조, 정치적 성향, 범죄기록 및 성생활 등은 수집하지
                      않습니다.
                    </p>
                    <p>
                      4. 수집 되어진 개인정보는 서비스 내용에 따라 필요시
                      회원간에 공개되며 공개되는 항목, 대상, 방법
                    </p>
                    <p>등은 회사의 정책에 의해 결정됩니다</p>
                    <p>
                      5. 우연은 주민등록번호 실명제도를 운영함에 있어 정상적으로
                      실명 확인이 되지않은 회원에 대해서는
                    </p>
                    <p>
                      회원 승인을 유보한 후, 개별적인 확인 절차를 통해 우연 의
                      회원으로 인정할 것인지를 결정할 수
                    </p>
                    <p>있습니다.</p>
                    <h3 className="my-3">제3조. 쿠키(cookie)의 운영 및 활용</h3>
                    <p>
                      1. 우연은 귀하에 대한 보다 빠른 서비스를 제공하기 위한
                      간단한 정보를 저장하고 수시로 찾아내는
                    </p>
                    <p>
                      {`'쿠키(cookie)'`}를 사용합니다. 쿠키는 웹사이트가 귀하의
                      컴퓨터 브라우저(넷스케이프, 인터넷
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      익스플로러 등)로 전송하는 소량의 정보입니다..
                      쿠키(cookie)는 귀하의 컴퓨터는 식별하지만 귀하를
                    </p>
                    <p>개인적으로 식별하지는 않습니다.</p>
                    <p>
                      2. 쿠키(cookie)를 이용하여 우연 는 귀하의 서비스
                      이용형태를 파악함으로써 더욱 편리한 서비스를
                    </p>
                    <p>
                      만들어제공할 수 있습니다.. 귀하는 쿠키(cookie)에 대한
                      선택권이 있습니다. 웹브라우저의 옵션을
                    </p>
                    <p>
                      조정함으로써 모든 쿠키(cookie)를 다 받아들이거나,
                      쿠키(cookie)가 설치될 때 통지를 보내도록
                    </p>
                    <p>
                      하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수
                      있으며 수시로 변경, 삭제 등이
                    </p>
                    <p>가능합니다.</p>
                    <p></p>
                    <h3 className="my-3">
                      제4조. 목적 외 사용 및 제 3자에 대한 제공
                    </h3>
                    <p>
                      1. 우연은 원칙적으로 회원의 개인정보를 [개인정보의 수집 및
                      이용목적]에서 고지한 범위 내에서
                    </p>
                    <p>
                      사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타
                      기업.기관에 제공하지 않습니다 .
                    </p>
                    <p>
                      2. 단, 보다 나은 서비스 제공을 위하여 귀하의 개인정보를
                      제휴사에게 제공하거나 또는 제휴사와
                    </p>
                    <p>
                      공유할 수있습니다. 개인정보를 제공하거나 공유할 경우에는
                      사전에 귀하께 제휴사가 누구인지, 제공
                    </p>
                    <p>
                      또는 공유되는 개인정보 항목이 무엇인지, 왜 그러한
                      개인정보가 제공되거나 공유되어야 하는지,
                    </p>
                    <p>
                      그리고 언제까지 어떻게 보호, 관리 되는지에 대해 개별적으로
                      전자우편 및 홈페이지를 통해 고지하여
                    </p>
                    <p>
                      동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는
                      경우에는 제휴사에게 개인정보 를
                    </p>
                    <p>
                      제공하거나 공유하지 않습니다. 다음의 경우에는 예외적으로
                      회원님의 동의 없이 개인정보를 제공할
                    </p>
                    <p>수 있습니다.</p>
                    <p>- 회원으로부터 별도의 동의를 받은 경우</p>
                    <p>
                      - 개인정보보호법 제15조 제1항제2호 , 제3호 및 제5호부터
                      제7호까지에 따라 개인정보를 수집한 목적
                    </p>
                    <p>범위에서 개인정보를 제공하는 경우</p>
                    <p>- 다른 법률에 특별한 규정이 있는 경우</p>
                    <p>
                      - 명백히 회원 또는 제3자의 급박한 생명, 신체, 재산의
                      이익을 위하여 필요하다고 인정되는 경우
                    </p>
                    <p>
                      - 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게
                      제공하지 아니하면 다른 법률에서 정하는
                    </p>
                    <p>
                      소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의,
                      의결을 거친 경우
                    </p>
                    <p>
                      - 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는
                      국제기구에 제공하기 위하여 필요한 경우
                    </p>
                    <p>
                      - 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
                    </p>
                    <p>- 법원의 재판업무 수행을 위하여 필요한 경우</p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      - 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우
                    </p>
                    <p>
                      - 공중위생 등 공공의 안전과 안녕을 위하여 긴급히 필요한
                      경우
                    </p>
                    <p></p>
                    <p>
                      3. 이벤트 및 경품당첨 등의 서비스를 이용하기 위하여
                      제휴사에 정보를 제공할 수 있으며 이때 필요한
                    </p>
                    <p>
                      제공 개인정보의 범위, 용도, 기간 등은 별도 고지 및 동의
                      절차를 통하여 진행합니다.
                    </p>
                    <p>
                      4. {`<우연>`}은 다음과 같이 개인정보를 제3자에게 제공하고
                      있습니다.
                    </p>
                    <p>- 개인정보를 제공받는 자 : 법무법인(유한) 산우</p>
                    <p>
                      - 제공받는 자의 개인정보 이용 목적 : 회원의 신원 확인 및
                      위계 및 허위 서류 제출 검토
                    </p>
                    <p>
                      - 제공하는 개인정보의 항목 : 성명, 생년월일, 신분관계를
                      확인할 수 있는 자료 및 경제력을 확인할 수
                    </p>
                    <p>있는 일체의 자료</p>
                    <p>- 제공받는 자의 보유 이용 기간 : 1년</p>
                    <p></p>
                    <h3 className="my-3">제5조. 개인정보의 처리 및 보유기간</h3>
                    <p>
                      1. 귀하는 회원 또는 이용자로서 회사가 제공하는 서비스를
                      이용하는 동안 회사는 귀하의 개인정보를
                    </p>
                    <p>계속적으로 보유하며 서비스 제공 등을 위해 이용합니다.</p>
                    <p>
                      2. 귀하가 직접 관리자 혹은 고객 만족팀에 회원탈퇴를
                      신청하시거나 개인정보 삭제를 요청한 경우
                    </p>
                    <p>추후 열람과 이용이 불가능한 상태로 처리됩니다.</p>
                    <p>
                      3. 귀하의 개인정보는 개인정보 삭제 및 탈퇴 신청을 요청할
                      시 파기하는 것을 원칙으로 합니다. 다만,
                    </p>
                    <p>
                      회사는 불량회원의 부정한 이용의 재발을 방지하기 위해 해지
                      요청 회원의 아이디,이름,주민등록번호,
                    </p>
                    <p>이메일을 보유할 수 있습니다.</p>
                    <p>
                      4. 각각의 개인정보 처리 및 보유기간은 다음과 같습니다.
                    </p>
                    <p>• 회원 가입 및 관리 : 탈퇴 시까지</p>
                    <p>
                      다만, 다음의 각 사유에 해당하는 경우에는 해당 사유
                      종료시까지
                    </p>
                    <p>
                      - 관계 법령 위반에 따른 수사 및 조사 등이 진행 중인
                      경우에는 해당 수사 및 조사 종료시까지
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      - 회원 가입에 따른 채권 채무 관계 잔존 시에는 해당 채권
                      채무관계 정산시까지
                    </p>
                    <p>
                      • 회사는 결혼회원 가입의 경우 결혼중개업의 관리에 관한
                      법률에 의거하여 개인정보를 보관합니다.
                    </p>
                    <p>
                      결혼회원(활동, 보류 포함)의 계약 종료시 가입일 기준 5년간
                      보관(가입일 기준 5년이 경과 하였으나
                    </p>
                    <p>
                      활동 보류중인 회원의 개인정보는 보관하며, 계약 종료시 까지
                      보관)
                    </p>
                    <p></p>
                    <h3 className="my-3">제6조. 개인정보처리의 위탁</h3>
                    <p>
                      1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이
                      개인정보 처리업무를 위탁하고 있으며,
                    </p>
                    <p>
                      관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
                      있도록 필요한 사항을 규정하고
                    </p>
                    <p>
                      있습니다. 자세한 내용은 아래와 같으며, 그 이외 고객님의
                      동의 없이 귀하의 개인정보를 외부에
                    </p>
                    <p>위탁하여 처리하지 않습니다..</p>
                    <p>
                      2. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이
                      개인정보 처리업무를 위탁하고 있습니다.
                    </p>
                    <p>가. - 수탁업체 : ㈜더존비즈온</p>
                    <p>- 위탁업무 내용 : 전자세금계산서 서비스 유지 보수</p>
                    <p>
                      - 개인정보 이용기간 : 개인정보의 수집 및 이용 목적 달성시
                      혹은 계약 종료시
                    </p>
                    <p>나. - 수탁업체 : 서울 신용평가</p>
                    <p>- 위탁업무 내용 : 본인인증(실명확인)</p>
                    <p>
                      - 개인정보 이용기간 : 신용평가기관에서 이미 보유하고 있는
                      개인정보이기 때문에 별도로 저장하지
                    </p>
                    <p>않음</p>
                    <p>
                      3. 향후 더 나은 서비스 제공을 위하여 개인정보의 처리를
                      위탁하는 경우에는 미리 그 사실을 귀하에 게
                    </p>
                    <p>고지 및 사전 동의 받겠습니다.</p>
                    <p>
                      4. 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을
                      통하여 서비스제공자의 개인정보보호 관련
                    </p>
                    <p>
                      지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및
                      사고시의 책임부담 등을 명확히 규정하고
                    </p>
                    <p>당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.</p>
                    <p>
                      5. 본 조 제1항의 동의를 거부할 경우, 이용자는 회원제로
                      운영되는 일부 서비스, 수탁업체가 제공하는
                    </p>
                    <p>
                      일부 서비스, 상품 서비스, 경품 이벤트 및 프로모션의 참여
                      등 기타 서비스 이용이 제한될 수 있습니다.
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p></p>
                    <h3 className="my-3">
                      제7조. 정보주체의 권리•의무 및 행사방법
                    </h3>
                    <p>
                      정보주체의 권리,의무 및 그 행사방법 정보주체는
                      개인정보주체로서 다음과 같은 권리를 행사할 수
                    </p>
                    <p>있습니다.</p>
                    <p>
                      1. 정보주체는 우연 에 대해 언제든지 다음 각 호의 개인정보
                      보호 관련 권리를 행사할 수 있습니다.
                    </p>
                    <p>
                      ①개인정보 열람요구 / ② 오류 등이 있을 경우 정정 요구 / ③
                      삭제요구/ ④ 처리정지 요구
                    </p>
                    <p>
                      2. 제1항에 따른 권리 행사는 우연 에 대해 개인정보 보호법
                      시행규칙 별지 제8호 서식에 따라 서면,
                    </p>
                    <p>
                      전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 우연은
                      이에 대해 지체 없이 조치하겠습니다.
                    </p>
                    <p>
                      3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를
                      요구한 경우에는 우연 는 정정 또는 삭제를
                    </p>
                    <p>
                      완료할 때까지 당해 개인정보를 이용하거나 제공하지
                      않습니다.
                    </p>
                    <p>
                      4. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나
                      위임을 받은 자 등 대리인을 통하여 하실 수
                    </p>
                    <p>
                      있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호
                      서식에 따른 위임장을 제출하셔야 합니다.
                    </p>
                    <p>
                      5. 귀하는 우연대표 Site에서 언제든지 본인의 개인정보를
                      조회하거나 수정할 수 있으며 회원탈퇴를
                    </p>
                    <p>
                      요청할수도 있습니다. 개인정보 조회 및 수정을 위해서는
                      [개인정보수정] 메뉴에서 아이디와
                    </p>
                    <p>
                      비밀번호를 사용하여 로그인하면 되는데, 아이디 이름을
                      제외한 모든 입력사항을 수정할 수 있습니다.
                    </p>
                    <p>
                      또한, 아이디와 비밀번호를 잊어버린 경우에는
                      {`"아이디/비밀번호 찾기"`}를 클릭하여 본인 확인에 필요한
                    </p>
                    <p>
                      사항을 입력하시면, 본인 확인 후 본인email 또는 핸드폰을
                      통하여 알려드립니다. 우연대표 Site를 통한
                    </p>
                    <p>
                      회원탈퇴 요청시 우연 에서 정한 탈퇴신청절차에 따라
                      처리합니다.
                    </p>
                    <p>
                      6. 우연은 정보주체의 요청에 의해 해지 또는 삭제된
                      개인정보는 [개인정보의 보유 및 이용기간]에
                    </p>
                    <p>
                      명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할
                      수 없도록 처리하고 있습니다. 우연 는
                    </p>
                    <p>
                      개인정보의 오류에 대하여 이메일이나 서면을 통해 정정을
                      요청한 경우 정정을 완료하기 전까지 당해
                    </p>
                    <p>개인정보를 이용 또는 제공하지 않습니다.</p>
                    <p></p>
                    <h3 className="my-3">제8조. 개인정보의 파기</h3>
                    <p>
                      개인정보의 파기{`("결혼정보회사 우연")`}은(는) 원칙적으로
                      개인정보 처리목적이 달성된 경우에는 지체
                    </p>
                    <p>
                      없이 해당개인정보를 파기합니다. 파기의 절차, 기한 및
                      방법은 다음과 같습니다.
                    </p>
                    <p>1. 파기절차</p>
                    <p>
                      정보주체가 입력한 정보는 목적 달성 후 별도의 DB에
                      옮겨져(종이의 경우 별도의 서류) 내부 방침 및
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>
                      기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시
                      파기됩니다. 이 때, DB로 옮겨진 개인정보는
                    </p>
                    <p>
                      법률에 의한 경우가 아니고서는 다른 목적으로 이용되지
                      않습니다.
                    </p>
                    <p>2. 파기기한</p>
                    <p>
                      정보주체의 개인정보는 개인정보의 보유기간이 경과된
                      경우에는 보유기간의 종료일로부터 5일
                    </p>
                    <p>
                      이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지,
                      사업의 종료 등 그 개인정보가 불필요하게
                    </p>
                    <p>
                      되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는
                      날로부터 5일 이내에 그 개인정보를
                    </p>
                    <p>파기합니다.</p>
                    <p>3. 파기방법</p>
                    <p>
                      전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
                      방법을 사용합니다.
                    </p>
                    <p>
                      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                      파기합니다.
                    </p>
                    <h3 className="my-3">제9조. 안전성 확보 조치</h3>
                    <p>
                      우연 는 개인정보보호법 제29조에 따라 귀하의 개인정보를
                      취급함에 있어 개인정보가 분실, 도난,
                    </p>
                    <p>
                      누출, 변조 또는 훼손되지 않도록 다음과 같이 안전성 확보에
                      필요한 기술적/관리적 및 물리적 조치를
                    </p>
                    <p>하고 있습니다.</p>
                    <p>
                      1. 고객의 개인정보는 비밀번호에 의해 보호되며 중요한
                      데이터는 별도의 보안기능을 통해 보호되고
                    </p>
                    <p>있습니다.</p>
                    <p>
                      2. 우연은 백신프로그램을 이용하여 컴퓨터 바이러스에 의한
                      피해를 방지하기 위한 조치를 취하고
                    </p>
                    <p>
                      있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런
                      바이러스가 출현할 경우 백신이 나오는
                    </p>
                    <p>
                      즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고
                      있습니다.
                    </p>
                    <p>
                      3. 해킹 등에 의해 귀하의 개인정보가 유출되는 것을 방지하기
                      위해, 외부로부터의 침입을 차단하는
                    </p>
                    <p>
                      장치를 이용하고 있으며, 각 서버마다 침입탐지시스템을
                      설치하여 24시간 침입을 감시하고 있습니다.
                    </p>
                    <p>
                      4. 개인정보 및 인증정보의 송수신 시에는 안전하게
                      개인정보가 전송될 수 있는 보안장치(SSL)을
                    </p>
                    <p>설치하여 보호하고 있습니다.</p>
                    <p>
                      5. 개인정보에 대한 접근권한을 개인정보관리책임자 및
                      담당자, 기타 업무상 개인정보의 취급이
                    </p>
                    <p>불가피한 자로 제한하여 최소화 하였습니다.</p>
                    <p>
                      6. 개인정보 취급직원에 대하여는 새로운 보안기술 습득 및
                      개인정보 보호의무에 관한 정기적인 사내
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>교육 및 외부 위탁 교육을 실시 하고 있습니다.</p>
                    <p>
                      7. 개인정보와 일반 정보를 혼합하여 탑재하고 있지 않으며
                      별도로 분리하여 보관 하고 있습니다.
                    </p>
                    <p>
                      8. 전산실 및 개인정보 보관실은 출입 통제구역으로 설정하여
                      허락된 인원만 출입이 허용되고
                    </p>
                    <p>
                      있습니다. 단 이용자 본인의 부주의나 기본적인 인터넷상의
                      위험성으로 일어나는 아이디, 비밀번호,
                    </p>
                    <p>
                      주민번호 등의 유출에 관하여 회사는 일체의 책임을 지지
                      않습니다.
                    </p>
                    <p></p>
                    <h3 className="my-3">제10조. 의견수렴 및 불만처리</h3>
                    <p>
                      우연은 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할
                      수 있는 창구를 개설하고 있습니다.
                    </p>
                    <p>
                      개인정보와 관련한 불만이 있으신 분은 우연 의 개인정보 관리
                      담당자 및 책임자에게 의견을 주시면
                    </p>
                    <p>접수 즉시 조치하여 처리결과를 통보해 드립니다.</p>
                    <p></p>
                    <h3 className="my-3">제11조. 아동의 개인정보 보호</h3>
                    <p>
                      1) 우연은 만14세 미만 아동의 개인정보를 수집하는 경우
                      법정대리인의 동의를 받습니다.
                    </p>
                    <p>
                      2) 만14세 미만 아동의 법정대리인은 아동의 개인정보의 열람,
                      정정, 동의 철회를 요청할 수 있으며,
                    </p>
                    <p>
                      이러한 요청이 있을 경우 우연 는 지체 없이 필요한 조치를
                      취합니다.
                    </p>
                    <p></p>
                    <h3 className="my-3">제12조. 개인정보 보호 책임자</h3>
                    <p>
                      1. 우연은 정보주체에게 좋은 정보를 안전하게 이용할 수
                      있도록 최선을 다하고 있습니다. 개인정보를
                    </p>
                    <p>
                      보호하는데 있어 정보주체에게 고지한 사항들에 반하는 사고가
                      발생할 시에 개인정보관리 책임자가
                    </p>
                    <p>모든 책임을 집니다.</p>
                    <p>
                      2. 단, 해킹 등 기본적인 네트워크상의 위험성에 의해
                      발생하는 예기치 못한 사고로 인한 정보의 훼손
                    </p>
                    <p>
                      및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는
                      책임이 없습니다. 우연 의 개인정보에 대한
                    </p>
                    <p>
                      관리책임자 및 담당자는 다음과 같으며 귀하의 개인정보 관련
                      문의 사항에 신속하고 성실하게
                    </p>
                    <p>답변하여 드리고 있습니다.</p>
                    <p>- 개인정보 보호 책임자 및 담당자</p>
                    <p>
                      이름 : 강바다 소속/지위 : 대표/ 연락처 : 02-2138-0638 E -
                      mail : helpcoi@naver.com
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <h3 className="my-3">
                      제13조 (회원 스스로의 개인정보 보호를 위한 숙지사항)
                    </h3>
                    <p>
                      회원은 PC방 등 외부 장소에서 서비스를 이용할 경우, 해킹
                      프로그램 기타 유해 프로그램이 없는지
                    </p>
                    <p>
                      유의하여 이용하시기 바랍니다. 회사는 개인정보보호에 최선을
                      다하고 있으나, 이용자 개인의 실수나
                    </p>
                    <p>
                      인터넷 상의 문제로 인한 분쟁에 관하여는 책임지지 않습니다.
                    </p>
                    <h3 className="my-3">제14조. 개인정보 열람청구</h3>
                    <p>
                      1. 정보주체는 개인정보 보호법 제35조에 따른 개인정보의
                      열람 청구를 아래의 부서에 할 수 있습니다.
                    </p>
                    <p>
                      (‘https://www.coincidence.co.kr/‘이하 ‘결혼정보회사
                      우연‘)은(는) 정보주체의 개인정보 열람청구가
                    </p>
                    <p>신속하게 처리되도록 노력하겠습니다.</p>
                    <p>▶ 개인정보 열람청구 접수·처리 부서</p>
                    <p>부서명 : 고객만족팀</p>
                    <p>담당자 : 고객만족처리 담당자</p>
                    <p>연락처 : 02- 02-2138-0638 이메일 : helpcoi@naver.com</p>
                    <p>
                      2. 정보주체께서는 제1항의 열람청구 접수․처리부서 이외에,
                      행정안전부의 ‘개인정보보호 종합지원
                    </p>
                    <p>
                      포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보
                      열람청구를 하실 수 있습니다.
                    </p>
                    <p>
                      3. 회원은 기타 개인정보침해에 대한 신고나 상담이 필요하신
                      경우에는 아래 기관에 문의할 수
                    </p>
                    <p>있습니다.</p>
                    <p></p>
                    <p>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
                    <p>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</p>
                    <p>- 홈페이지 : privacy.kisa.or.kr</p>
                    <p>- 전화 : (국번없이) 118</p>
                    <p>
                      - 주소 : (138-950) 서울시 송파구 중대로 135
                      한국인터넷진흥원 개인정보침해신고센터
                    </p>
                    <p>▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</p>
                    <p>
                      - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적
                      해결)
                    </p>
                  </div>
                </div>
                <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf">
                  <div class="ndfHFb-c4YZDc-cYSp0e-DARUcf-Df1ZY-bN97Pc-haAclf">
                    <p>- 홈페이지 : privacy.kisa.or.kr</p>
                    <p>- 전화 : (국번없이) 118</p>
                    <p>
                      - 주소 : (138-950) 서울시 송파구 중대로 135
                      한국인터넷진흥원 개인정보침해신고센터
                    </p>
                    <p>
                      ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
                    </p>
                    <p>
                      ▶ 경찰청 사이버범죄수사단 : 1566-0112 (www.netan.go.kr)
                    </p>
                    <p></p>
                    <h3 className="my-3">제 14조. 고지의 의무</h3>
                    <p>
                      현 개인정보 취급방침에 대한 정부의 정책 또는 보안기술의
                      변경에 따라 내용의 추가, 삭제 및 수정이
                    </p>
                    <p>있을 시에</p>
                    <p>
                      는 개정 최소 7일 전부터 홈페이지의 ‘공지‘란을 통해 고지할
                      것입니다
                    </p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p>공고일자 : 2024년 3월 25일 시행일자 : 2024년 3월 25일</p>
                    <p></p>
                    <p>* 본 방침은부터 2024년 3월 25일부터 시행됩니다.</p>
                    <p>* 우연 홈페이지 개인정보 취급방침 참조</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Privacy1;
