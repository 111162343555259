import axios from "axios";
// export const apiUrl = "http://3.34.235.234";
export const apiUrl = "https://www.coincidence.co.kr";

let flag = false;

export const setToken = (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const noclient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const update = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

export const homeUpdate = axios.create({
  baseURL:
    apiUrl === "https://www.coincidence.co.kr"
      ? "https://manager.coincidence.co.kr"
      : "http://43.203.0.1",
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    return error;
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

update.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error.response.data.error);
    return error;
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

export const loginPost = async (url, body) => {
  try {
    const res = await client.post(url, body);
    if (res?.status >= 200 || res?.status <= 299) {
      return res.data;
    } else {
      let message;
      if (res?.status) {
        switch (res?.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = res.message;
            break;
        }
      }
      alert(message);
    }
  } catch (error) {
    alert("아이디와 비밀번호를 확인해주세요");
  }
};

export const requestPost = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.post(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const adminRequestPost = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await update.post(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // if (res?.response?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     case 409:
    //       message = res?.response?.data?.error;
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    // console.log(message);
    // throw message;
    throw res.response;
  }
};

export const homeRequestPost = async (url, body) => {
  const res = await homeUpdate.post(url, body);
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const adminUserPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
  }
};

export const reUserPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  const longtoken = localStorage.getItem("long");
  let headers = {};
  if (token) {
    headers = {
      "x-jwt-refresh": `${longtoken}`,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    sessionStorage.setItem("authUser", res.data.accessToken);
    localStorage.setItem("long", res.data.refreshToken);
    flag = false;
    window.location.reload();
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestGet = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.get(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    // throw message;
    throw res.response;
  }
};

export const homeRequestGet = async (url) => {
  const res = await homeUpdate.get(url);
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
export const norequestGet = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await noclient.get(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const requestDelete = async (url) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.delete(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};

export const imgPut = async (url, body) => {
  const token = sessionStorage.getItem("authUser");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res?.status) {
      switch (res?.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = res.message;
          break;
      }
    }
    throw message;
  }
};
