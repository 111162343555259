import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";

import { ReactComponent as Star } from "./Star.svg";
import BackImg from "./review.png";
import "./review.scss";

function ReviewPage(props) {
  const [dummy1] = useState([
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
    {
      header: "김**님, 이**님의 후기",
      stars: 5,
      desc: "피드백이 빠른 부분이 좋았어요. 원하는 이성의 스타일부터 자기소개서, 사진까지 하나하나 신경써주셔서 그 점이 좋았어요. 평일, 주말 밤낮할 것 없이 열심히 해주시니 제가 오히려 죄송할 지경이더라고요 🥹",
      date: "24.05.11",
    },
  ]);

  useEffect(() => {
    document.title = "커플 후기 | 우리의 인연";
  }, []);

  const callStars = (num) => {
    return (
      <div className="stars">
        {Array.from({ length: num }, (_, i) => (
          <Star key={i} />
        ))}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content reviewcustomWrap">
        <Container>
          <h1 className="ReviewTitle">커플 후기</h1>
          <Row>
            {dummy1?.map((item, i) => {
              return (
                <Col lg={6} key={i} className="d-flex justify-content-center">
                  <Card
                    className="review1Card"
                    onClick={() => (window.location.href = "/review/detail")}
                  >
                    <Row className="review1CardHeader w-100 m-0">
                      <Col className="review1CardHeaderName">{item.header}</Col>
                      <Col className="review1CardHeaderStar">
                        {callStars(item?.stars)}
                      </Col>
                    </Row>
                    <Row className="review1CardBody w-100 m-0">
                      <Col className="d-flex flex-column p-0">
                        <div className="review1CardDesc">{item.desc}</div>
                      </Col>
                      <Row>
                        <Col className="d-flex flex-column p-0">
                          <div className="review1CardDate">{item.date}</div>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ReviewPage;
