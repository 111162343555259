import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  adminRequestPost,
  adminUserPut,
  homeRequestGet,
  requestGet,
} from "../../apis/request";
import "./purcahse.scss";

function Purchasewait(props) {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search.split("?")[1]);

  const callApi = async () => {
    const success = params.get("imp_success");
    if (success === "true") {
      try {
        let body = {
          imp_uid: params.get("imp_uid"),
          merchant_uid: params.get("merchant_uid"),
        };
        await adminUserPut(`/api/purchase/complete`, body);
        alert("결제 성공 하였습니다.");
        history.replace("/dashboard");
      } catch (error) {
        alert("결제 실패 하였습니다.");
        history.replace("/dashboard");
      }
    } else {
      alert("결제 실패 하였습니다.");
      history.replace("/dashboard");
    }
  };

  useEffect(() => {
    if (params) {
      callApi();
    }
  }, [params]);

  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          backgroundColor: "#fff",
          zIndex: 55555,
        }}
      />
    </React.Fragment>
  );
}

export default Purchasewait;
